import { React, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import OutsideClickHandler from 'react-outside-click-handler';

import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';

import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';

import { ApiUnstake, ApiDeFiShieldUpdate } from '../../Api';

import { DeleteCardModule } from '../../Modal/DeleteCard';

import { ApiDeleteDefishield, ApiDeFiShieldAlertUpdate, ApiDefishieldInvestmentRisks } from '../../Api';
import { EmptyCircularProgressBar } from '../EmptyCircularProgressBar';

import { INVESTMENTRISK_SUCCESS } from '../../Reducer/InvestmentRisksReducer';

import { NoAlertComponent } from '../../Components/NoAlert';
import chart from '../../Assets/img/icons/chart__icon.svg';
import chart_open from '../../Assets/img/icons/chart__icon__open.svg';


import { ChartModal } from '../../Modal/Chart';

import { ContractAddressDefiShild } from '../../Modal/ContractAddressDefiShild';

export function DefiCard({ element, reloadListCallback }) {
    const dispatch = useDispatch();
    const [alertvalue, setAlertValue] = useState();
    const [openProgress, setOpenProgress] = useState(false);
    const [openStake, setOpenStake] = useState(false);
    const [openUnstaking, setOpenUnstaking] = useState(false);
    const [unstakingValue, setUnstakingValue] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [unstakeError, setUnstakeError] = useState('');
    const [showUnstakeMesage, setShowUnstakeMesage] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [showStakeUlert, setShowStakeUlert] = useState(false);
    const [openChart, setOpenChart] = useState(false);

    const [showContractAddress, setShowContractAddress] = useState(false);


    const coldprops = {
        percent: Math.round(element?.risks?.productRisk ? element?.risks?.productRisk : element.productRisk), // is require
        colorSlice: '#4DC7B5',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const riskprops = {
        percent: Math.round(element?.risks?.productRisk ? element?.risks?.productRisk : element.productRisk), // is require
        colorSlice: '#EFB661',
        fontColor: '#EFB661',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const holdprops = {
        percent: Math.round(element?.risks?.productRisk ? element?.risks?.productRisk : element.productRisk), // is require
        colorSlice: '#F27281',
        fontColor: '#F27281',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const whiteprops = {
        percent: 0, // is require
        colorSlice: '#f0f0f3',
        fontColor: '#f0f0f3',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }



    const renderClassName = (color) => {
        switch (color) {
            case 'green':
                return {
                    className: 'card cold defi',
                    props: coldprops
                };
            case 'yellow':
                return {
                    className: 'card hold defi',
                    props: riskprops
                };
            case 'red':
                return {
                    className: 'card risky defi',
                    props: holdprops
                };
            case 'white':
                return {
                    props: whiteprops,
                    color: '#C4C4C4',
                 className: 'card empty defi',
                };
        }
    }

    const renderColorClassName = (risk) => {
        if (risk <= 100 && risk >= 80) {
            return {
                className: 'card__product__number card__product__green',
            };
        }

        else if (risk < 80 && risk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        }

        else if (risk < 60) {
            return {
                className: 'card__product__number card__product__red',
            };
        }
        else if (risk === null || 0) {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }



    useEffect(() => {
        setUnstakingValue(+element.autoUnstacking);
        setAlertValue(+element.alert)

    }, [element])


    const unstakinghandleChange = (value, e) => {
        // e.preventDefault()
        setUnstakingValue(value)
    }

    const handleChange = (value, e) => {
        // e.preventDefault()
        setAlertValue(value)

    }

    const onChangeComplete = (value) => {
        ApiDeFiShieldUpdate(element.id, +unstakingValue)
            .then((result) => {
            })
            .catch((error) => {
            })

    }


    const alertOnChangeComplete = () => {
        ApiDeFiShieldAlertUpdate(element.id, +alertvalue)
            .then((result) => {
            })
            .catch((error) => {
            })
    }

    return (
        <>
            <div className={renderClassName(element?.risks?.trustScore)?.className}>
                <div className="card__header">
                    <div className="row">

                        <div className="chart__open__btn" onClick={(() => {
                            setOpenChart(!openChart)
                        })}>

                            {
                                openChart ?
                                    <img src={chart} alt="" className="chart__opened__btn__icon" /> :
                                    <img src={chart_open} alt="" className="chart__open__btn__icon" />

                            }
                        </div>
                        <div className="card__settings" style={{ fontSize: '12px' }} onClick={(() => {
                            setShowContractAddress(!showContractAddress)
                        })}>
                            <i className="icon-setting"></i>
                        </div>

                        <h2 className="card__title">
                            {element.assetId?.symbol} Staking
                        </h2>
                        <div className='close__card__content' onClick={(() => {
                            setOpenDeleteModal(!openDeleteModal)
                        })}>
                            <span className="card__close"
                            >
                                <span></span><span></span>
                            </span>
                        </div>


                    </div>
                </div>

                <OutsideClickHandler
                    onOutsideClick={(event) => {
                        setShowContractAddress(false)

                    }}>
                    {
                        showContractAddress ?
                            <ContractAddressDefiShild element={element}
                                modalClose={((event) => {
                                    setShowContractAddress(event)
                                    reloadListCallback();
                                })} /> : null
                    }

                </OutsideClickHandler>

                {
                    !showUnstakeMesage ?
                        <div className="card__price">
                            <div className="card__wallet__row">
                                <div className="card__wallet__rates">Staked</div>



                                <div className="card__wallet__usd">${element?.stakedAmount ? new Intl.NumberFormat('en-US').format(Math.round(element?.stakedAmount)) : 0}</div>
                            </div>
                        </div> :
                        <div className='card__price' style={{ 'height': '35px' }}>
                            <p className='unstake__error_message'>{unstakeError} </p>
                        </div>

                }
                <div className="card__product__wrapper">
                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Asset:<div className='title_item'><p className='card__product__title_item'>{element?.assetId?.symbol}</p></div> </div>
                        <div
                            className={renderColorClassName(Number(Math.round(element?.risks?.assetRisk)) ? Number(Math.round(element?.risks?.assetRisk)) : Number(Math.round(element?.assetId?.value)) ? Number(Math.round(element?.assetId?.value)) : Number(Math.round(element?.assetId?.defaultValue)))?.className}
                        >{Math.round(element?.risks?.assetRisk ? element?.risks?.assetRisk : element?.assetId?.value ? element?.assetId?.value : element?.assetId?.defaultValue)}</div>
                    </div>
                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Blockchain: <div className='title_item'><p className='card__product__title_item'>{element?.blockchainId?.name}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(element?.risks?.blockchainRisk)) ? Number(Math.round(element?.risks?.blockchainRisk)) : Number(Math.round(element?.blockchainId?.value)) ? Number(Math.round(element?.blockchainId?.value)) : Number(Math.round(element?.blockchainId?.defaultValue)))?.className}>
                            {Math.round(element?.blockchainId?.value ? element?.blockchainId?.value : element?.blockchainId?.defaultValue)}</div>
                    </div>
                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Platform: <div className='title_item'><p className='card__product__title_item'>{element?.platformId?.name}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(element?.risk?.platformRisk)) ? Number(Math.round(element?.risk?.platformRisk)) : Number(Math.round(element?.platformId?.value)) ? Number(Math.round(element?.platformId?.value)) : Number(Math.round(element?.platformId?.defaultValue)))?.className}>
                            {Math.round(element?.platformId?.value ? element?.platformId?.value : element?.platformId?.defaultValue)}</div>
                    </div>
                </div>
                <OutsideClickHandler disabled={openStake ? false : true} onOutsideClick={(event) => {
                    setOpenStake(false)
                }}>
                    <div className="card__product__stake__block">
                        <button style={{ color: '#889096' }} className={'card__product__stake' + ' ' + (openStake ? 'card__product__stake_active' : 'card__product__stake')}
                            onClick={(() => {
                                setTimeout(() => {
                                    setShowStakeUlert(true)
                                })
                                setTimeout(() => {
                                    setShowStakeUlert(false)
                                }, 2000)
                            })}
                        >Stake</button>
                        <button className="card__product__unstacked" onClick={() => {
                            ApiUnstake(element?.id)
                                .then((result) => {
                                    setUnstakeError(`Unstake has been performed ${'\n'} successfully.`)
                                    setTimeout(() => {
                                        setShowUnstakeMesage(true)
                                    })
                                    setTimeout(() => {
                                        setShowUnstakeMesage(false)
                                    }, 3000)
                                })
                                .catch((error) => {
                                    setUnstakeError(error?.response?.data?.error?.message)
                                    setTimeout(() => {
                                        setShowUnstakeMesage(true)
                                    })
                                    setTimeout(() => {
                                        setShowUnstakeMesage(false)
                                    }, 3000)
                                })
                        }}>Unstake</button>
                        {openStake ?
                            <div className="card__product__stake__amount open">
                                <div className="card__product__stake__amount__input__wrapper">
                                    <input placeholder="Amount" type="text" className="card__product__stake__amount__input" />
                                </div>
                                <button className="card__product__stake__amount__max">Max Amount</button>
                                <button className="card__product__stake__amount__confirm">Confirm</button>
                            </div> : ''
                        }

                    </div>
                </OutsideClickHandler>
                <div className="card__risk">
                    <h3 className="card__risk__title">Product Risk</h3>
                    <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')}
                        onClick={((event) => {
                            if (openProgress) {
                                alertOnChangeComplete()
                            }
                            setOpenProgress(!openProgress)
                            setOpenUnstaking(false)
                            // event.stopPropagation()
                            //alertOnChangeComplete()
                        })}>
                        <p className="card__risk__alert__title">Alert</p>
                        <p className={'card__risk__alert__number' + ' ' + (alertvalue === 0 || null ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{alertvalue === null || 0 ? 0 : alertvalue}</p>
                    </div>
                    {
                        openProgress ?
                            <div className={'card__risk__range active card__slider' + ' ' + (alertvalue === 0 || null ? 'card__slider_not_value' : 'card__risk__range active card__slider')}>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={alertvalue === null || 0 ? 0 : alertvalue}
                                    orientation='vertical'
                                    onChange={handleChange}
                                    tooltip={false}
                                    onChangeComplete={alertOnChangeComplete}
                                    step={1}

                                />
                            </div> : ''
                    }
                    {
                        openUnstaking ?
                            <div
                                className={'card__risk__range__hr active card__unstaking__slider' + ' ' + (unstakingValue === 0 || null ? 'card__slider_not_value' : 'card__risk__range__hr active card__unstaking__slider')}
                            >
                                <Slider
                                    min={0}
                                    max={100}
                                    value={unstakingValue === 0 || null ? 0 : unstakingValue}
                                    orientation='vertical'
                                    onChange={unstakinghandleChange}
                                    tooltip={false}
                                    onChangeComplete={onChangeComplete}
                                    step={1}

                                />
                            </div> : ''
                    }
                    {element && element?.risks?.trustScore ?
                        <div className="card__risk__chart">
                            <CircularProgressBar {...renderClassName(element?.risks?.trustScore)?.props}>
                                <div className='card__round__shadow'></div>
                                <div className='card__risk__chart__min__wrapper'>
                                    <p className="card__risk__chart__number">{renderClassName(element?.risks?.trustScore)?.props.percent}</p>
                                    <p className="card__risk__chart__number__of">of 100</p>
                                </div>
                            </CircularProgressBar>
                        </div>
                        : <EmptyCircularProgressBar />
                    }

                    <div className="card__risk__info" onClick={(() => {
                        setTimeout(() => {
                            setShowAlert(true)
                        })
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 2000)
                    })}>
                        <i className="icon-info-icon"></i>
                    </div>
                </div>



                <div className={'unstacking' + ' ' + (unstakingValue < 1 || unstakingValue === null ? 'active' : 'unstacking')} onClick={(() => {
                    if (openUnstaking) {
                        onChangeComplete()
                    }
                    setOpenUnstaking(!openUnstaking)
                    setOpenProgress(false)
                    //onChangeComplete()
                })}>

                    Auto Unstaking
                    <span>{unstakingValue === 0 || null ? 0 : unstakingValue}</span>
                </div>
            </div>
            <OutsideClickHandler onOutsideClick={(event) => {
                setOpenChart(false)

            }}>
                {
                    openChart ?

                        <ChartModal element={element} closeModal={(() => {
                            setOpenChart(false)
                        })} />

                        : ''
                }
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={(event) => {
                setOpenDeleteModal(false)

            }}>
                {
                    openDeleteModal ?
                        <DeleteCardModule title="You are going to delete the Investment Product"
                            text="This Investment Product will be deleted, but can be restored from the Recycle Bin."
                            element={element}
                            deleteItem={(id) => {
                                ApiDeleteDefishield(id)
                                    .then((result) => {
                                        setOpenDeleteModal(false);
                                        reloadListCallback();
                                        ApiDefishieldInvestmentRisks()
                                            .then((result) => {
                                                dispatch({
                                                    type: INVESTMENTRISK_SUCCESS,
                                                    payload: {
                                                        result: result.data.data
                                                    }
                                                })
                                            })
                                            .catch((error) => {
                                            })
                                    })


                            }}
                            cancel={((event) => {
                                setOpenDeleteModal(event)
                            })} /> : ''
                }
            </OutsideClickHandler>

            <OutsideClickHandler onOutsideClick={(event) => {
                setShowAlert(false)

            }}>
                {
                    showAlert ?
                        <NoAlertComponent top={60} title={'No Alerts.'} /> : ''
                }
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={(event) => {
                setShowStakeUlert(false)

            }}>
                {
                    showStakeUlert ?
                        <NoAlertComponent top={60} title={'This functionality is not available for your account. Please contact LightCore.'} /> : ''

                }
            </OutsideClickHandler>


        </>
    )
}