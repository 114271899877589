import {CREATE_LIGHT_NTF_SUCCESS,CREATE_LIGHT_NTF_ERROR} from '../Reducer/CreateLightNTFReducer';

import {ApiCreateLightNT} from '../Api';


export function CreateLightNtfThunk(id){
    return (dispatch,getState) =>{
        ApiCreateLightNT(id)
        .then((result)=>{
            dispatch({
                type:CREATE_LIGHT_NTF_SUCCESS
            })
        })
        .catch((error)=>{
            dispatch({
                type: CREATE_LIGHT_NTF_ERROR,
                payload: {
                    error: error?.response?.data?.error?.message,
                }

            })
        })
    }
}