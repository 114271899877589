import {OPEN_PAGE_LOADING, OPEN_PAGE_SUCCESS} from '../Reducer/OpenPagesReducer';
import {ApiGetUserShields} from '../Api';

export function OpenPageThunk(id){
    return (dispatch,getState) => {
        // dispatch({ type: OPEN_PAGE_LOADING });
        ApiGetUserShields(id)
        .then((result)=>{
            dispatch({
                type:OPEN_PAGE_SUCCESS,
                payload: {
                    deFiShield: result.data.data.deFiShield,
                    lightDeFi:result.data.data.lightDeFi,
                    lightNFT:result.data.data.lightNFT,
                    walletShield:result.data.data.walletShield,
                    partnersGuard:result.data.data.partnersGuard
                }
            })
        })
        .catch((error)=>{
        })
    }
}