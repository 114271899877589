export const LIGHT_NTF_PRODUCT_SUCCESS = 'LIGHT_NTF_PRODUCT_SUCCESS';
export const LIGHT_NFT_FETCHED = 'LIGHT_NFT_FETCHED';

const lightNtfProductState = {
    lightNtfProductResult:[],
     islightNTFFetched:false,
}

export function GetLightNTFProductReducer(state=lightNtfProductState,action){
    if (action.type === LIGHT_NTF_PRODUCT_SUCCESS){
        return {
            ...lightNtfProductState,
            lightNtfProductResult:action.payload.result,
         islightNTFFetched:action.payload.result.length? false : true,
        }
    }

    else if(action.type ===  LIGHT_NFT_FETCHED){
        return {
            ...state,
            lightNtfProductResult: [],
            islightNTFFetched:false,  
        }
    }
    
    return state;
}