export const GET_SETTINGS_SUCCESS = 'get_settings_success';

const settingsState ={
    settingsResult:null,
}

export function GetSettingsReducer(state = settingsState, action){
    if(action.type === GET_SETTINGS_SUCCESS){
        return {
            ...settingsState,
            settingsResult:action.payload.result,
        }
    }
    return state;
}