export const DELETE_LIGHT_NTF_SUCCESS ='DELETE_LIGHT_NTF_SUCCESS';

const deletelightntfState = {
    delete_light_ntf_success:false,
};

export function DeleteLightNTFProductReducer(state =deletelightntfState,action){

    if(action.type === DELETE_LIGHT_NTF_SUCCESS){
        return {
            ...deletelightntfState,
            delete_light_ntf_success:true,
        }
    }
    return state;
}