export const LIGHT_DEFI_INVESTMENT_RISK_ALERT = 'light_defi_investment_risk_alert';

const  lightDefiInvestmentRiskAlertState={
    lightDefiInvestmentRiskAlertResult:[], 
}

export function LightDefiInvestmentRiskAlertReducer(state=lightDefiInvestmentRiskAlertState,action){
    if(action.type === LIGHT_DEFI_INVESTMENT_RISK_ALERT){
        return {
            ...lightDefiInvestmentRiskAlertState,
            lightDefiInvestmentRiskAlertResult:action.payload.result,
        }

    }
    return state;
}