import { React, useState, useEffect } from 'react';


import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import OutsideClickHandler from 'react-outside-click-handler';

import { DeleteCardModule } from '../../Modal/DeleteCard';
import { NoAlertComponent } from '../../Components/NoAlert';
import { useDispatch } from 'react-redux';

import { DELETE_LIGHT_NTF_SUCCESS } from '../../Reducer/DeleteLightNTFProductReducer';
import { LIGHT_NTF_PRODUCT_SUCCESS } from '../../Reducer/GetLightNTFProductReducer';

import { ApiDeleteLightNTFProduct, ApiGetProductLightNT } from '../../Api';

import { ApiLightNTFAlertUpdate } from '../../Api';

import chart from '../../Assets/img/icons/chart__icon.svg';
import chart_open from '../../Assets/img/icons/chart__icon__open.svg';

import { LightNtfChartModal } from '../../Modal/LightNtfChart';

export function LightNFCard({ element, reloadListCallback }) {

    const dispatch = useDispatch();

    const [alertvalue, setAlertValue] = useState();

    const [openProgress, setOpenProgress] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [showAlert, setShowAlert] = useState(false);

    const [openChart, setOpenChart] = useState(false);

    const coldprops = {
        percent: Math.round(element?.lightNFTId?.risk), // is require
        colorSlice: '#4DC7B5',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const hotprops = {
        percent:Math.round(element?.lightNFTId?.risk), // is require
        colorSlice: '#EFB661',
        fontColor: '#EFB661',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const riskprops = {
        percent:Math.round(element?.lightNFTId?.risk), // is require
        colorSlice: '#F27281',
        fontColor: '#F27281',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }



    const renderClassName = (element) => {
        if (element?.lightNFTId?.risk <= 100 && element?.lightNFTId?.risk >= 80) {
            return {
                className: 'card cold nft',
                props: coldprops,
            };
        }

        else if (element?.lightNFTId?.risk < 80 && element?.lightNFTId?.risk >= 60) {
            return {
                className: 'card hold nft',
                props: hotprops,
            };
        }

        else if (element?.lightNFTId?.risk < 60 ) {
            return {
                className: 'card risky nft',
                props: riskprops,
            };
        }
        else if (element?.lightNFTId?.risk === null || 0) {
            return {
                className: 'card card__product__white nft',
            };
        }
    }


    useEffect(() => {
        setAlertValue(+element.alert)

    }, [element]);

    const handleChange = (value, e) => {
       // e.preventDefault()
        setAlertValue(value)

    };


    const alertOnChangeComplete = (value) => {
        ApiLightNTFAlertUpdate(element.id, +alertvalue)
            .then((result) => {
            })
            .catch((error) => {
            })
    }





    return (
        <>
            <div className={renderClassName(element)?.className}>
          
                <div className="card__header">
                    <div className="row light_defi_row">

                        <div className="chart__open__btn" onClick={(() => {
                            setOpenChart(!openChart)
                        })}>

                            {
                                openChart ?
                                    <img src={chart} alt="" className="chart__opened__btn__icon" /> :
                                    <img src={chart_open} alt="" className="chart__open__btn__icon" />

                            }


                        </div>


                        <h2 className="card__title ntf_card_title">
                            {element?.lightNFTId?.name}
                        </h2>
                        <div className='close__card__content' onClick={(() => {
                            setOpenDeleteModal(!openDeleteModal)
                        })}>
                            <span className="card__close" >
                                <span></span><span></span>
                            </span>
                        </div>

                    </div>

                </div>
                <div className="card__nft__wrapper">
                    <img src={element?.lightNFTId?.image} alt="nft1" className="card__nft__img" />
                </div>


                <div className="card__risk">
                    <h3 className="card__risk__title">Collection Value</h3>

                    <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')}
                        onClick={((event) => {
                            if(openProgress){
                                alertOnChangeComplete()
                            }
                            setOpenProgress(!openProgress)
                            //  event.stopPropagation()
                        })}>
                        <p className="card__risk__alert__title">Alert</p>
                        <p className={'card__risk__alert__number' + ' ' + (alertvalue === 0 || null ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{alertvalue === null || 0 ? 0 : alertvalue}</p>
                    </div>
                    <div className="card__risk__chart">
                        <CircularProgressBar  {...renderClassName(element)?.props}>
                            <div className='card__round__shadow'></div>
                            <div className='card__risk__chart__min__wrapper'>
                                <p className="card__risk__chart__number">{renderClassName(element)?.props?.percent}</p>
                                <p className="card__risk__chart__number__of">of 100</p>
                            </div>
                        </CircularProgressBar>
                    </div>
                    <div className="card__risk__info" onClick={(() => {
                        setTimeout(() => {
                            setShowAlert(true)
                        })
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 2000)
                    })}>
                        <i className="icon-info-icon"></i>
                    </div>


                    <div>

                        {
                            openProgress ?
                                <div className={'card__risk__range active card__slider' + ' ' + (alertvalue === 0 || null ? 'card__slider_not_value' : 'card__risk__range active card__slider')}>
                                    <Slider
                                        min={0}
                                        max={100}
                                        value={alertvalue === null || 0 ? 0 : alertvalue}
                                        orientation='vertical'
                                        onChange={handleChange}
                                        tooltip={false}
                                        onChangeComplete={alertOnChangeComplete}
                                        step={1}
                                    />
                                </div> : ''
                        }


                    </div>
                    <div className="card__risk__range">
                    </div>
                </div>
            </div>
         
            <OutsideClickHandler onOutsideClick={(event) => {
             setOpenChart(false)

            }}>
                {
                    openChart ?

                        <LightNtfChartModal element={element} closeModal={(() => {
                            setOpenChart(false)
                        })} />

                        : ''
                }
            </OutsideClickHandler>


            <OutsideClickHandler onOutsideClick={(event) => {
                setShowAlert(false)

            }}>
                {
                    showAlert ?
                        <NoAlertComponent top={60} title={'No Alerts.'} /> : ''
                }

            </OutsideClickHandler>



            <OutsideClickHandler onOutsideClick={(event) => {
                setOpenDeleteModal(false)

            }}>
                {
                    openDeleteModal ?
                        <DeleteCardModule title="You are going to delete the NFT Collection"
                            text="This NFT Collection will be deleted, but can be restored from the Recycle Bin."
                            element={element}
                            deleteItem={(id) => {
                                ApiDeleteLightNTFProduct(id)
                                    .then((result) => {
                                        dispatch(({
                                            type: DELETE_LIGHT_NTF_SUCCESS
                                        }))
                                        setOpenDeleteModal(false)
                                        reloadListCallback();
                                    })
                                    .catch((error) => { })
                            }}
                            cancel={((event) => {
                                setOpenDeleteModal(event)
                            })} /> : ''
                }
            </OutsideClickHandler>
        </>
    )
}