import { ACCESSPASSWORD_SUCCESS, ACCESSPASSWORD_LOADING, ACCESSPASSWORD_ERROR } from '../Reducer/AccessPasswordReducer';
import { ApiAccessPassword } from '../Api';

export function AccessPasswordThunk({ password }) {
    return (dispatch, getState) => {
        dispatch({
            type: ACCESSPASSWORD_LOADING
        })
        ApiAccessPassword(password)
            .then((result) => {
                localStorage.setItem('acceptToken',result.data.data.access_token)
                dispatch({
                    type: ACCESSPASSWORD_SUCCESS,
                    payload:{
                        accept_token:result.data.data.access_token,
                    }
                })
            })
            .catch((error) => {
                dispatch({
                    type: ACCESSPASSWORD_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}