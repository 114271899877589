export const WALLET_INVESTMENT_RISK = 'WALLET_INVESTMENT_RISK';


const walletInvestmentRiskState = {
 walletInvestmentRiskResult: [],
};

export function WalletInvestmentRiskReducer(state = walletInvestmentRiskState, action) {
    if (action.type === WALLET_INVESTMENT_RISK) {
        return {
            ...walletInvestmentRiskState,
            walletInvestmentRiskResult: action.payload.result,
        }
    }

    return state;

}