import {UPDETE_WALLET_INVESTMENT_ALERT_SUCCESS} from '../Reducer/UpdateWalletInvestmentAlertReducer';
import {ApiUpdateWalletAlert} from '../Api';

export function UpdateWalletInvestmentAlertThunk(alert,type){
    return (dispatch,getState)=>{
        ApiUpdateWalletAlert(alert, type)
        .then((result)=>{
            return dispatch({
                type:UPDETE_WALLET_INVESTMENT_ALERT_SUCCESS
            })
        })
        .catch((error)=>{
        })
    
    }
}