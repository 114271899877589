import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';


import { ApiStatisticspartnersGuard } from '../../Api';
import * as _ from 'lodash';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    Filler
} from 'chart.js';

import { format } from 'date-fns'
import { useRef } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
);


function getGradient(ctx, chartArea) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(230, 234, 239, 0.4)');
        gradient.addColorStop(0.2, 'rgba(223, 228, 236, 0.4)');
        gradient.addColorStop(0.5, 'rgba(194, 209, 225, 0.4)');
        gradient.addColorStop(0.8, 'rgba(182, 202, 221, 0.4)');
        gradient.addColorStop(1, 'rgba(154, 187, 217, 0.4)');
    }

    return gradient;
}


export function CounterpartyRiskChart({ element, closeModal }) {
    const defaultChartItem = {
        label: '',
        data: [],
        borderColor: '',
        backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
                return;
            }
            return getGradient(ctx, chartArea);
        },
        fill: true
    };


    const [selectedPeriod, setSelectedPeriod] = useState([]);

    let chart = useRef();
    let securityData = useRef();
    let assetsData = useRef();
    let blockChainData = useRef();
    let platformData = useRef();
    let chartRange = useRef();


    const [dropDownData] = useState(
        [
            { id: 1, title: 'Week', value: 'week' },
            { id: 2, title: 'Month', value: 'month' },
            { id: 3, title: '3 Months', value: 'three_months' },
            { id: 4, title: 'Year', value: 'year' },



        ]
    );

    const defaultPeriod = dropDownData.at(0);

    const [openDropdown, setopenDropdown] = useState(false);


    const [options, setOptions] = useState({
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
        },
        scales: {
            y: {
                ticks: {}
            }
        }
    });


    let labels = [];

    let [data, setData] = useState({
        labels,
        datasets: [
            {
                label: '',
                data: [12, 13, 14, 15, 7, 9, 12],
                borderColor: '#C9CEFF',
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                fill: true,
            }
        ]
    });

    const setInitalValues = () => {
        setSelectedPeriod(dropDownData.at(0));
    };

    useEffect(() => {
        setInitalValues();
        getStatisticsProductSecurity(defaultPeriod.value);
    }, []);

    const renderChart = () => {
        const labelsSet = new Set();
        const additionalData = [
            {
                color: '#00000',
                uniqueId: 'security'
            },
            {
                color: '#C9CEFF',
                uniqueId: 'asset'
            },
            {
                color: '#97EAEA',
                uniqueId: 'blokchain'
            },
            {
                color: '#F0DC8B',
                uniqueId: 'platform'
            },

        ]
        const chartData = [
            securityData.current || {},
            assetsData.current || {},
            blockChainData.current || {},
            platformData.current || {}
        ];
        const datasets = [];

        chartData.forEach((e, i) => {
            const { data } = e;
            if (!data) {
                return;
            }
            data.forEach((dt) => {
                labelsSet.add(dt.createdAt);
            });
        })

        const labels = [...labelsSet]
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .map((dt) => {
                return format(new Date(dt), 'dd-MMM');
            });

        chartData.forEach((e, i) => {
            const { data } = e;
            if (!data) {
                return;
            }
            const dataItem = _.cloneDeep(defaultChartItem);

            labels.forEach((label) => {
                const dataPoint = data.find((e) => format(new Date(e.createdAt), 'dd-MMM') === label);
                if (dataPoint) {
                    dataItem.data.push(dataPoint.productRisk || dataPoint.risk);
                } else {
                    dataItem.data.push(null);
                }
            });
            dataItem.borderColor = additionalData[i].color;
            dataItem.uniqueId = additionalData[i].uniqueId;
            datasets.push(dataItem);
        })
        options.scales.y.suggestedMax = chartRange?.current?.max + 5;
        options.scales.y.suggestedMin = chartRange?.current?.min - 5 > 0 ? chartRange?.current?.min - 5 : chartRange?.current?.min;
        setOptions({
            ...options
        });
        setData({
            labels: [...labels],
            datasets: [...datasets]
        });
    }



    const renderColorClassName = () => {
        if (+element.partnersGuardId?.risk <= 100 && +element.partnersGuardId?.risk >= 80) {
            return {
                className: 'card__product__number card__product__green',
            };
        }

        else if (+element.partnersGuardId?.risk < 80 && +element.partnersGuardId?.risk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        }

        else if (+element.partnersGuardId?.risk < 60) {
            return {
                className: 'card__product__number card__product__red',
            };
        }
        else if (+element.partnersGuardId?.risk === null || 0) {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }

    const getStatisticsProductSecurity = (type) => {
        ApiStatisticspartnersGuard(type, element?.partnersGuardId?.id)
            .then(({ data: response }) => {
                const { data: result } = response;
                const { max, min } = result;
                securityData.current = result;
                chartRange.current = {
                    max: Math.max(max),
                    min: Math.min(min),
                }
                renderChart();

            })
            .catch((error) => {
            })
    };

    return (
        <>
            <div className='chart__container'>
                <div className="card__chart__wrapper">
                    <h2 className="card__title chart__card__title" style={{ margin: '0 0 10px 0' }}>
                        {element.partnersGuardId?.name}
                    </h2>
                    <div className="card__chart__header">
                        <div className="card__chart__dropdown">
                            <div className="card__chart__dropdown__selected" onClick={(() => {
                                setopenDropdown(!openDropdown)
                            })}>
                                {selectedPeriod.title}
                                <div className="card__chart__dropdown__selected__arrow" ></div>
                            </div>
                            {
                                openDropdown ?
                                    <div className="card__chart__dropdown__block">
                                        {
                                            dropDownData.map((element, index) => {
                                                return <div className="card__chart__dropdown__list" key={index + 1}
                                                    onClick={(() => {
                                                        setSelectedPeriod(element);
                                                        setopenDropdown(false);
                                                        getStatisticsProductSecurity(element?.value);
                                                    })}>{element?.title}</div>
                                            })
                                        }

                                    </div> : null
                            }

                        </div>
                        <div className='chart__catent__value' style={{ marginLeft: '70px' }}>
                            <p>Current Counterparty Risk -</p>
                            <p className={renderColorClassName()?.className} style={{ margin: '0 10px' }}>{Number(Math.round(element?.partnersGuardId?.defaultRisk))}</p>
                        </div>

                    </div>
                    <div className="card__chart">
                        <div className='line__chart'>
                        <span className="risk__text">Risk</span>
                            <Line options={options} data={data} ref={chart} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}