export const DEFI_SHILES_SUCCESS = 'defi_shield';
export const DEFISHILD_ERROR = 'defi_shield _error';
export const  DEFISHILD_FETCHED = 'DEFISHILD_FETCHED';


const defishieldState = {
    defishieldResult: [],
    isDefiShieldFetched: false,
    defishieldError: false,
}

export function DeFiShieldReducer(state = defishieldState, action) {

    if (action.type === DEFI_SHILES_SUCCESS) {
        return {
            ...defishieldState,
            defishieldResult: action.payload.result,
            isDefiShieldFetched: action.payload.result.length ? false : true,
            defishieldError: false,
        }

    }
    else if (action.type === DEFISHILD_ERROR) {
        return {
            ...defishieldState,
            defishieldResult: [],
            defishieldError: true,
        }
    }
   else if(action.type ===  DEFISHILD_FETCHED){
        return {
            ...state,
            defishieldResult: [],
            isDefiShieldFetched:false,  
        }
    }


    return state;
}