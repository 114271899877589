import { React } from 'react';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';

export function AllLightDeFiCard({element}) {
    const coldprops = {
        percent: Math.round(element?.risks?.productRisk ? element?.risks?.productRisk: element.productRisk), // is require
        colorSlice: '#4DC7B5',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 56,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const riskprops = {
        percent: Math.round(element?.risks?.productRisk ? element?.risks?.productRisk: element.productRisk), // is require
        colorSlice: '#EFB661',
        fontColor: '#EFB661',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 56,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const holdprops = {
        percent: Math.round(element?.risks?.productRisk ? element?.risks?.productRisk: element.productRisk), // is require
        colorSlice: '#F27281',
        fontColor: '#F27281',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 56,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const whiteprops = {
        percent: 0, // is require
        colorSlice: '#f0f0f3',
        fontColor: '#f0f0f3',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 56,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }


    const renderClassName = (color) => {
        switch (color) {
            case 'green':
                return {
                    className: 'card__list cold',
                    props: coldprops
                };
            case 'yellow':
                return {
                    className: 'card__list hold',
                    props: riskprops
                };
            case 'red':
                return {
                    className: 'card__list risky',
                    props: holdprops
                };
                case 'white':
                    return {
                        props: whiteprops,
                    };
        }
    }
    return <>
        <div className={renderClassName(element?.risks?.trustScore)?.className}>
            <div className="card__list__percent"></div>
            <div className="card__list__content">
                <h3 className="card__list__title">{element.assetId?.symbol} Staking</h3>
                <div className="card__risk__chart__list">
                    <CircularProgressBar {...renderClassName(element?.risks?.trustScore)?.props}>
                                <div className='card__round__shadow__defi__list'></div>
                                <div className='card__risk__chart__min__wrapper card__risk__chart__min__wrapper__defi__list'>
                                    <p className="card__risk__chart__list__number">{renderClassName(element?.risks?.trustScore)?.props.percent}</p>
                                </div>
                            </CircularProgressBar>
                </div>
            </div>
        </div>
    </>
}