import { React, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as  yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';

import site from '../../../Assets/img/site/logo__login.svg';
import view from '../../../Assets/img/icons/eye.svg';
import { Loading } from '../../../Components/Loading';

import { ResetPasswordThunk } from '../../../Thunks/ResetPasswordThunk';
import { useNavigate } from 'react-router';

export function PasswordReset() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [stateError, setStateError] = useState();

    const [passwordShown, setPasswordShown] = useState(false);
    

    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

    const [shoResetPassword,setShowResetPassword] = useState(false);

    const password_reset_error = useSelector((state) => {
        return state.passwordReset.password_reset_error;
    });

    const password_reset_success = useSelector((state) => {
        return state.passwordReset.password_reset_success;
    });

    const password_reset_loading = useSelector((state) => {
        return state.passwordReset.password_reset_loading;
    });

    const schema = yup.object().shape({
        reset_code: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.'),
        reset_password: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.').min(8, 'The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol.').matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."),

    });



    const formik = useFormik({
        initialValues: {
            reset_code: '',
            reset_password: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            dispatch(ResetPasswordThunk({code:values.reset_code,password:values.reset_password}));
        }
    });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    useEffect(() => {
        if (password_reset_error) {
            setStateError(password_reset_error);
        }
    }, [password_reset_error]);

    useEffect(() => {
        if (password_reset_success) {
            setResetPasswordSuccess(true)
            // setTimeout(()=>{
            //     navigate('/login')
            // },3000)
        
        }

    }, [password_reset_success]);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

      useEffect(()=>{
        if (password_reset_error && password_reset_error[0] === 'Verification reset_code has expired or is invalid.') {
            setShowResetPassword(true)
        }
    },[password_reset_error]);


    return (
        <>
            {
                !resetPasswordSuccess ?
                    <form className="login__form" onSubmit={formik.handleSubmit}>
                        <img src={site} alt="" className="login__logo" />
                    
                       {stateError?.map(item => <span key={item} className='error__message psw_requests__message'>{item}</span>) }
                       <>
                          <div className="form__content">
                       
                            <div  className={'login__input__wrapper' + ' ' + (formik.touched.reset_code &&  formik.errors.reset_code ? 'form__content__error' : 'login__input__wrapper')}>
                                <input type="text" className="login__input" placeholder="Code *" id="reset_code" name="reset_code"
                                    value={formik.values.reset_code}
                                    onChange={formik.handleChange}
                                    onFocus={() => deleteErrorButton('reset_code')}
                                    autoComplete="new-password"
                                />
                            </div>
                            {formik.touched.reset_code && formik.errors.reset_code ? <span className='error__message'>{formik.errors.reset_code}</span> : null}
                        </div>
                       </>
                     
                        <>
                      
                        <div className="form__content">
                        
                            <div className={'login__input__wrapper' + ' ' + (formik.touched.reset_password &&  formik.errors.reset_password ? 'form__content__error' : 'login__input__wrapper')}>
                                <input className="login__input" placeholder="Enter your new password *" id="reset_password" name="reset_password"
                                    value={formik.values.reset_password}
                                    onChange={formik.handleChange}
                                    onFocus={() => deleteErrorButton('reset_password')}
                                    type={passwordShown ? "text" : "password"}
                                    autoComplete="new-password"
                                
                                />
                                <img className='view__icon' src={view}  onClick={togglePassword}/>
                            </div>
                        </div>
                        {formik.touched.reset_password && formik.errors.reset_password ? <span className='error__message password__message'>{formik.errors.reset_password}</span> : null}
                        </>
                        <div>
                            {
                                shoResetPassword ?
                            <div className="forgot__password__content">
                                <p className='forgot__passwors_text' onClick={(()=>{
                                    navigate('/forgot-reset_password')
                                })}>Reset Password</p>
                            </div>:null }
                        </div>

                        <button className="login__btn">Change{password_reset_loading ? <Loading /> : null}</button>
                    </form> :
                    <div className="login__form">
                        <img src={site} alt="" className="login__logo" />
                        <h1 className="purchase__form__title">Your password has been changed successfully.</h1>
                        <button className="login__btn" onClick={(() => {
                            navigate('/login');
                        })}>Login</button>
                    </div>
            }
        </>

    )
}