import { React, useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import {
  Header,
  LightNFCard,
  NTFAside,
  LightNtfFilter,
} from "../../../Components";

import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";

import filter from "../../../Assets/img/icons/filter.svg";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";

import { ApiGetProductLightNT, ApiGetAllLightNT } from "../../../Api";
import { LIGHT_NTF_PRODUCT_SUCCESS } from "../../../Reducer/GetLightNTFProductReducer";

import { AddLightNTFModal } from "../../../Modal/AddLightNTF";

import { CREATE_LIGHT_NTF_CLOSE_MODAL } from "../../../Reducer/CreateLightNTFReducer";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LightNTFInvestmentRiskThunk } from "../../../Thunks/LightNTFInvestmentRiskThunk";

import { GET_LIGHT_NTF_SUCCESS } from "../../../Reducer/GetLightNtfReducer";

import { LogoLoading } from "../../../Components/index";

import { LIGHT_NFT_FETCHED } from "../../../Reducer/GetLightNTFProductReducer";
import top from "../../../Assets/img/icons/to__top.svg";

let interval;

export function LightNFT() {
  const navigate = useNavigate();
  let searchData = {};
  const dispatch = useDispatch();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [openFilter, SetOpenFilter] = useState(false);
  const [searchParams, setsearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const lightNTFProductResult = useSelector((state) => {
    return state.getLightNTFProductReducer.lightNtfProductResult;
  });

  const light_ntf_success = useSelector((state) => {
    return state.createLightNTFReducer.create_light_ntf_success;
  });

  const islightNtfched = useSelector((state) => {
    return state.getLightNTFProductReducer.islightNTFFetched;
  });

  useEffect(() => {
    document.getElementById("scrollContent").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (islightNtfched) {
      if (lightNTFProductResult?.length === 0 || modalIsOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [islightNtfched, lightNTFProductResult.length, modalIsOpen]);

  useEffect(() => {
    if (lightNTFProductResult?.length === 0 && !modalIsOpen) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [lightNTFProductResult.length, modalIsOpen]);

  useEffect(() => {
    dispatch({
      type: LIGHT_NFT_FETCHED,
    });
  }, []);

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  const getLightNft = () => {
    const params = { ...searchData };
    ApiGetProductLightNT(params.orderRisk)
      .then((result) => {
        dispatch({
          type: LIGHT_NTF_PRODUCT_SUCCESS,
          payload: {
            result: result.data.data,
          },
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (light_ntf_success) {
      setsearchParams(JSON.parse("{}"));
      dispatch({
        type: CREATE_LIGHT_NTF_CLOSE_MODAL,
      });
      ApiGetProductLightNT()
        .then((result) => {
          dispatch({
            type: LIGHT_NTF_PRODUCT_SUCCESS,
            payload: {
              result: result.data.data,
            },
          });
        })
        .catch((error) => {});
    }
  }, [light_ntf_success]);

  useEffect(() => {
    searchData = {};
    const search = searchParams.get("q");
    searchData = JSON.parse(search || "{}");
    if (interval) {
      clearInterval(interval);
    }
    getLightNft();
    interval = setInterval(() => {
      getLightNft(false);
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [searchParams.toString()]);

  useEffect(() => {
    navigate("/light-nft");
  }, []);

  return (
    <>
      <Header title={"Ligh NFT"} />
      <main className={"main" + " " + (openMenu ? "open" : "main")}>
        <div className="content" id="scrollContent">
          <div className="row">
            {modalIsOpen ? (
              <AddLightNTFModal
                modalIsOpen={modalIsOpen}
                title={"Light NTF"}
                modalClose={() => {
                  setIsOpen(false);
                }}
              />
            ) : (
              ""
            )}
            {lightNTFProductResult.length
              ? lightNTFProductResult.map((element) => {
                  return (
                    <LightNFCard
                      key={element?.id}
                      element={element}
                      reloadListCallback={() => {
                        getLightNft();
                        dispatch(LightNTFInvestmentRiskThunk());
                        if (modalIsOpen) {
                          ApiGetAllLightNT("")
                            .then((result) => {
                              dispatch({
                                type: GET_LIGHT_NTF_SUCCESS,
                                payload: {
                                  result: result.data.data,
                                },
                              });
                            })
                            .catch((error) => {});
                        }
                      }}
                    />
                  );
                })
              : null}

            <div className="layout__block">
              <div className="layount__right__container">
                <img
                  src={filter}
                  alt=""
                  className="filter__icon"
                  onClick={() => {
                    SetOpenFilter(!openFilter);
                  }}
                />

                <LightNtfFilter
                  activeclassName={openFilter ? "open" : "close"}
                  urlpath={"/light-nft"}
                />

                <div className="zoom__page__btn">
                  <img
                    src={zoom_pluse}
                    width="24"
                    height="24"
                    alt=""
                    className="zoom__page__btn__plus"
                    onClick={zoomClose}
                  />
                  <img
                    src={zoom_minus}
                    width="24"
                    height="24"
                    alt=""
                    className="zoom__page__btn__minus"
                    onClick={zoom}
                  />
                </div>
              </div>
            </div>
          </div>
          {loading ? <LogoLoading /> : null}
        </div>

        <NTFAside
          openModal={(event) => {
            setIsOpen(event);
          }}
        />

        {showTopBtn ? (
          <a
            className="to__top"
            onClick={() => {
              document.getElementById("scrollContent").scrollTo(0, 0);
            }}
          >
            <img src={top} alt="top" className="to__top__icon" />
          </a>
        ) : (
          ""
        )}
      </main>
    </>
  );
}
