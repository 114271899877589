export const CREATE_DEFISHILD_ERROR ='create_defishild_error';
export const CREATE_DEFISHILD_LOADING ='create_defishild_loading';
export const CREATE_DEFISHILD_SUCCESS ='create_defishild_success';
export const SET_DEFISHILD_SUCCESS_FALSE = 'SET_DEFISHILD_SUCCESS_FALSE';

const creteDefiShildState ={
    create_defishild_error:null,
    create_defishild_loading:false,
    create_defishild_success:false,
}

export function CreteDefiShildReduicer(state=creteDefiShildState,action){
    if(action.type === CREATE_DEFISHILD_LOADING){
return {
    ...creteDefiShildState,
    create_defishild_loading:true,
}
    }

    else if (action.type === CREATE_DEFISHILD_ERROR){
        return {
            ...creteDefiShildState,
            create_defishild_loading:false,
            create_defishild_error:action.payload.error,
        }
    }

    else if(action.type === CREATE_DEFISHILD_SUCCESS){
        return {
            ...creteDefiShildState,
            create_defishild_loading:false,
            create_defishild_error:null,
            create_defishild_success:true,
        }

    }
    else if (action.type === SET_DEFISHILD_SUCCESS_FALSE) {
        return {
            ...creteDefiShildState,
            create_defishild_error:null,
            create_defishild_loading:false,
            create_defishild_success: false,
        }

    }
    return state;
}