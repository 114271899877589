export const projectSecurityTooltip = (
  <div className="custom-toolTip">
    <h2>Project Security</h2>
    <p>
      The level of project security will be evaluated based on identified
      security issues from the project's history. The security status will be
      categorized into three levels:
    </p>
    <p style={{ width: "95%" }}>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>Low Risk </span> -
      This category applies to projects with no history of security breaches.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F5B452" }}>Medium Risk </span>{" "}
      - Projects falling under this category have experienced one or up to two
      security breaches.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>High Risk </span> -
      This level is assigned to projects that have encountered more than two
      security breaches. It is recommended that assets associated with such
      projects should be delisted.
    </p>
  </div>
);
export const smartContractPlatformSecurityAudit = (
  <div className="custom-toolTip">
    <h2>Platform / Smart Contract Security</h2>
    <p>
      For mature projects with a history of more than one and a half years, it
      is advisable to conduct a minimum of 3 audits, regardless of the timing of
      the last audit. For younger projects, a minimum of 2 audits is
      recommended, provided that the last audit was conducted within the last
      four months.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>Low Risk </span>-
      No critical or high findings.
      <ul style={{ margin: "0 30px" }}>
        <li>Mature projects: 3 audits.</li>
        <li>Young projects: 2 audits.</li>
      </ul>
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F5B452" }}>Medium Risk </span>{" "}
      - Only medium findings.
      <ul style={{ margin: "0 30px" }}>
        <li>Mature projects: 3 audits.</li>
        <li>Young projects: 2 audits.</li>
      </ul>
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>High Risk </span> -
      Includes projects with at least one critical finding or more than two high
      findings. Projects with an insufficient number of audits will also be
      considered high risk.
    </p>
  </div>
);

/*General Tap */

export const foundersTeamTooltip = (
  <div className="custom-toolTip">
    <h2>Founders/ Team</h2>
    <p>
      To satisfy the listing criteria, the project team should consist of
      well-known individuals with no involvement in fraudulent activities.
    </p>
    <p>The Founders/Team parameter will be categorized into three levels:</p>
    <p>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>
        Reliable Team
      </span>{" "}
      - Led by individuals with a proven track record of success and ethical
      conduct, known for their credibility and expertise.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>
        Unreliable Team
      </span>{" "}
      - Includes individuals with a questionable history, lack of clear
      achievements, or past controversies, raising concerns about their ability
      to lead effectively.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F5B452" }}>
        Anonymous Team
      </span>{" "}
      - Comprises teams where key members' identities are undisclosed, lacking
      transparency about their backgrounds and qualifications, which can lead to
      skepticism about the project's credibility and leadership capability.
    </p>
  </div>
);

export const reputationTooltip = (
  <div className="custom-toolTip">
    <h2>Reputation</h2>
    <p>
      The Project Reputation parameter will be categorized into two levels:{" "}
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>
        Positive Reputation
      </span>{" "}
      - A project with no history of involvement in fraudulent activities.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>
        Negative Reputation
      </span>{" "}
      - A project with a history of fraudulent activities.
    </p>
  </div>
);

export const AMLTooltip = (
  <div className="custom-toolTip">
    <h2>AML</h2>
    <p>The AML parameter will be categorized into two levels:</p>
    <p>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>
        No AML Activities
      </span>{" "}
      - The Blockchain or its underlying assets are not directly linked to AML
      or terrorist activities.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>
        AML Activities
      </span>{" "}
      - The Blockchain or its underlying assets are sometimes involved in AML or
      terrorist activities. It is essential to conduct thorough analysis of
      specific addresses using appropriate tools.
    </p>
  </div>
);

export const lawsuitsTooltip = (
  <div className="custom-toolTip">
    <h2>Lawsuits</h2>
    <p>The Project Lawsuits parameter will be divided into two levels:</p>
    <p>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>No Lawsuits</span>{" "}
      - A project with no legal actions filed against it.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>
        Existing Lawsuits
      </span>{" "}
      - A project currently facing legal actions.
    </p>
  </div>
);

export const communityMediaTooltip = (
  <div className="custom-toolTip">
    <h2>Community / Media</h2>
    <p>The Community / Media parameter will be categorized into two levels:</p>
    <p>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>
        Strong Community
      </span>{" "}
      - A project with a robust and active community. A strong community is
      characterized by active participation and growth over the past 3 months,
      demonstrating a positive trend.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F5B452" }}>No Community</span>{" "}
      - A project lacking a substantial community presence.
    </p>
  </div>
);

/*Project Maturity */

export const projectMaturityTooltip = (
  <div className="custom-toolTip">
    <h2>Project Maturity</h2>
    <p>The Project Maturity parameter will be divided into three levels:</p>
    <p style={{ width: "95%" }}>
      <span style={{ fontWeight: "bold", color: "#4DC7B5" }}>
        Mature Project{" "}
      </span>{" "}
      - A project that has been in existence for more than 5 years and no major
      cyber/ trading incidents.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F5B452" }}>
        Medium Maturity Project
      </span>{" "}
      - A project that has been in existence for more than 1-3 years and no
      major cyber/ trading incidents.
    </p>
    <p>
      <span style={{ fontWeight: "bold", color: "#F27281" }}>
        Immature Project
      </span>{" "}
      - A project that has been in existence for less than one year or has face
      multiple trading/ cyber incidents.
    </p>
  </div>
);

export const projectVolatilityTooltip = (
  <div className="custom-toolTip">
    <h2>Volatility</h2>
    <p>Annualized price volatility calculated using a 365-day period.</p>
  </div>
);

export const projectAdoptionRateTooltip = (
  <div className="custom-toolTip">
    <h2>New Adoption Rate (30-Day Avg.)</h2>
    <p>
      The percentage of new addresses making their first transaction relative to
      all active addresses provides insight into the proportion of total
      activity attributed to newcomers.
    </p>
  </div>
);

export const searchTooltip = (
  <div className="custom-toolTip">
    <p>
      To search and present a few assets, they should be entered in the search
      box, separated by commas.
    </p>
    <p>For example: BTC, eth, LINK.</p>
  </div>
);

export const googleSearchTrendTooltip = (
  <div className="custom-toolTip">
    <p>
      The trend in Google searches is represented by a value of 100, indicating
      peak popularity for the term. A value of 50 means the term is half as
      popular, while a score of 0 indicates insufficient data for that term.
    </p>
  </div>
);
