export const PARTNER_GUARD_INVESTMENT_RISK_ALERT = 'PARTNER_GUARD_INVESTMENT_RISK_ALERT';

const  partnerGuardInvestmentRiskAlertState={
    partnerGuardInvestmentRiskAlertResult:[], 
}

export function PartnerGuardInvestmentRiskAlertReducer(state=partnerGuardInvestmentRiskAlertState,action){
    if(action.type === PARTNER_GUARD_INVESTMENT_RISK_ALERT){
        return {
            ...partnerGuardInvestmentRiskAlertState,
            partnerGuardInvestmentRiskAlertResult:action.payload.result,
        }

    }
    return state;
}