export const CHART_PRODUCT_SECURITY_SUCCESS = 'CHART_PRODUCT_SECURITY_SUCCESS';


const chartProductSecurityState = {
    chartProductSecurityResult: [],
};

export function LightDefiChartProductSecurityReducer(state = chartProductSecurityState, action) {
    if (action.type === CHART_PRODUCT_SECURITY_SUCCESS) {
        return {
            ...chartProductSecurityState,
            chartProductSecurityResult: action.payload.result,
        }
    }
    return state;
}