import { React, useState } from 'react';

import remove from '../../../../../Assets/img/icons/remove.png';

import { DeleteCardModule } from '../../../../../Modal/DeleteCard';
import OutsideClickHandler from 'react-outside-click-handler';

export function DefiShildTrashComponent({ element, restoreItem, deleteItem }) {

    const [openDeleteModal, setOpenDeleteModal] = useState(false);


    const renderColorClassName = (risk) => {
        if (risk <= 100 && risk >= 80) {
            return {
                className: 'card__product__number card__product__green',
                borderColor: '#4DC7B5'
            };
        }

        else if (risk < 80 && risk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        }

        else if (risk < 60) {
            return {
                className: 'card__product__number card__product__red',
            };
        }
        else if (risk === null || 0) {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }


    const renderBorderClassName = (risk) => {
        if (risk <= 100 && risk >= 80) {
            return {
                className: 'card__risk__chart__min card__border__color__green',
            };
        }

        else if (risk <= 79 && risk >= 60) {
            return {
                className: 'card__risk__chart__min card__border__color__yellow',
            };
        }

        else if (risk <= 59) {
            return {
                className: 'card__risk__chart__min card__border__color__red',
            };
        }
        else if (risk === null || 0) {
            return {
                className: 'card__risk__chart__min card__border__color__white',
            };
        }
    }

    return (
        <>
            <div className="card cold defi trash">
                <div className="card__header">
                    <div className="row">
                        <h2 className="card__title">
                            {element?.defiShield?.assetId?.symbol} Staking
                        </h2>
                        <div className='close__card__content' style={{ top: 'unset', zIndex: '99999999' }} onClick={(() => {
                            setOpenDeleteModal(true)
                        })}>
                            <span className="card__close"
                            >
                                <span></span><span></span>
                            </span>
                        </div>

                    </div>
                </div>
                <div className="card__price">
                    <div className="card__wallet__row">
                        <div className="card__wallet__rates">Staked amount</div>
                        <div className="card__wallet__usd"></div>
                    </div>
                </div>
                <div className="card__product__wrapper">
                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Asset:<div className='title_item'><p className='card__product__title_item ' style={{ marginRight: '15px' }}>{element?.defiShield?.assetId?.symbol}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(element?.defiShield?.assetId?.value)) ? Number(Math.round(element?.defiShield?.assetId?.value)) : Number(Math.round(element?.defiShield?.assetId?.defaultValue)))?.className}
                        >{Math.round(element?.defiShield?.assetId?.value ? element?.defiShield?.assetId?.value : element?.defiShield?.assetId?.defaultValue)}</div>
                    </div>



                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Blockchain: <div className='title_item'><p className='card__product__title_item ' style={{ marginRight: '15px' }}>{element?.defiShield?.blockchainId?.name}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(element?.defiShield?.blockchainId?.value)) ? Number(Math.round(element?.defiShield?.blockchainId?.value)) : Number(Math.round(element?.defiShield?.blockchainId?.defaultValue)))?.className}>
                            {Math.round(element?.defiShield?.blockchainId?.value ? element?.defiShield?.blockchainId?.value : element?.defiShield?.blockchainId?.defaultValue)}</div>
                    </div>

                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Platform: <div className='title_item'><p className='card__product__title_item' style={{ marginRight: '15px' }}>{element?.defiShield?.platformId?.name}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(element?.platformId?.value)) ? Number(Math.round(element?.defiShield?.platformId?.value)) : Number(Math.round(element?.defiShield?.platformId?.defaultValue)))?.className}>
                            {Math.round(element?.defiShield?.platformId?.value ? element?.defiShield?.platformId?.value : element?.defiShield?.platformId?.defaultValue)}</div>
                    </div>



                </div>
                <div className="card__product__stake__block">
                    <button className="card__product__stake">Stake</button>
                    <button className="card__product__unstacked">Unstake</button>
                    <div className="card__product__stake__amount">
                        <div className="card__product__stake__amount__input__wrapper">
                            <input placeholder="Amount" type="text" className="card__product__stake__amount__input" />
                        </div>
                        <button className="card__product__stake__amount__max">Max Amount</button>
                        <button className="card__product__stake__amount__confirm">Confirm</button>
                    </div>
                </div>
                <div className="card__risk">
                    <h3 className="card__risk__title">Product Risk</h3>
                    <div className="card__risk__alert">
                        <p className="card__risk__alert__title">Alert</p>
                        <p className="card__risk__alert__number">{element?.defiShield?.alert}</p>
                    </div>
                    <div className="card__risk__chart">
                        <div className={renderBorderClassName(Number(Math.round(element?.defiShield?.productRisk)))?.className}   >
                            <p className={renderColorClassName(Number(Math.round(element?.defiShield?.productRisk)))?.className}>{Math.round(element?.defiShield?.productRisk)}</p>
                            <p className={renderColorClassName(Number(Math.round(element?.defiShield?.productRisk)))?.className} style={{ fontSize: '14px' }}>of 100</p>
                        </div>
                    </div>
                    <div className="card__risk__info">
                        <i className="icon-info-icon"></i>
                    </div>
                </div>
                <div  className="unstacking active">
                    Auto Unstacking
                    <span>30</span>
                </div>
                <div className="trash__wallet" onClick={(() => {
                    restoreItem(element.id)
                })}>
                    <img width="44" height="48" src={remove} alt="" className="trash__wallet__icon" />
                </div>
                <div className="trash__layer"></div>
            </div>
            <OutsideClickHandler onOutsideClick={(event) => {
                    setOpenDeleteModal(false)
                }}>
                {
                    openDeleteModal ?
                        <DeleteCardModule title="You are going to delete this product"
                            text="This product will be deleted and you will not be able to restore it."
                            element={element} deleteItem={(() => {
                                deleteItem(element.id)
                                setOpenDeleteModal(false)
                            })} cancel={(() => {
                                setOpenDeleteModal(false)
                            })} /> : ''
                }
                </OutsideClickHandler>
        </>
    )
}