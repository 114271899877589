import { useState } from 'react';
import { useEffect } from 'react';
import { React } from 'react';
import { ApiUpdateDefiShild } from '../../Api';


export function ContractAddressDefiShild({ modalClose, element }) {

    const [inputValue, setInputValue] = useState();
    const [errorMessage, setErrorMessage] = useState(null);


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setErrorMessage(null);
        }
    };

    useEffect(() => {

        if (element && element?.address) {
            if (!inputValue) {
                setInputValue(element?.address);
            }
        }
        else {
            setInputValue(null);
        }

    }, [element]);

    return <>
        <div className='contract__address_container'>
            <p className='error_message' style={{ 'marginBottom': '10px' }}>{errorMessage} </p>
            <div className='contract__address_main_content'>

                <div className='ntf__filter__content  contract__address_content' style={{ 'height': '45px' }}>
                    <input placeholder='Contract address' className='ntf__filter__input' value={inputValue}
                        onChange={((event) => {
                            setInputValue(event?.target?.value);
                        })}
                        onFocus={() => deleteErrorButton('address')}

                    />
                </div>
                <button className='login__btn contract__address_btn' onClick={(() => {

                    ApiUpdateDefiShild(element.id, {
                        address: inputValue || null
                    }).then((result) => {
                        if (result) {
                            modalClose(false)
                        }

                    }).catch((error) => {
                        setErrorMessage(error?.response?.data?.error?.message)
                    });


                })}>SAVE</button>

            </div>
        </div>
    </>
}