import { React, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';


import low from '../../Assets/img/icons/low.svg';
import high from '../../Assets/img/icons/high.svg';
import { useDispatch, useSelector } from 'react-redux';



export function LightNtfFilter({ urlpath, activeclassName }) {
    const dispatch = useDispatch();
    const searchData = useRef();
    const [searchParams] = useSearchParams();


    const navigate = useNavigate();


    useEffect(() => {
        const paramsStr = searchParams.get('q');
        const params = JSON.parse(paramsStr || '{}');

    }, []);

    const updateQueries = () => {
        const url = urlpath;
        const paramsStr = JSON.stringify(searchData.current);
        const path = `${url}?q=${paramsStr}`;
        navigate(path);
    }


    return (
        <>
            <div className={"filter__block " + activeclassName}>
                <div className="filter__block__btn__block"></div>
                <div className="filter__block__scroll">
                    <h3 className="filter__block__title">Filter</h3>
                    <div className="filter__risk__block">
                        <div className="risk__btn__row">
                            <button className="risk__btn"
                                onClick={(() => {
                                    searchData.current = {
                                        ...searchData.current || {},

                                        orderRisk: 'DESC',
                                    };
                                    updateQueries();
                                })}> <img src={high} /></button>
                            <span className="risk__btn__text">High value .....</span>
                        </div>
                        <div className="risk__btn__row">
                            <button className="risk__btn"
                                onClick={(() => {
                                    searchData.current = {
                                        ...searchData.current || {},
                                        orderRisk: 'ASC',
                                    }
                                    updateQueries();
                                })}><img src={low} /></button>
                            <span className="risk__btn__text">Low value .....</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
