import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import user from "../../Assets/img/user/userinfo.jpg";
import log__out from "../../Assets/img/icons/log__out__icon.svg";
import { ProfileThunk } from "../../Thunks/ProfileThunk";

import { NoAlertComponent } from "../../Components/NoAlert";
import { useNavigate } from "react-router";

export function Header({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openLogOut, setOpenLogOut] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const profileResult = useSelector((state) => {
    return state.profile.profileResult;
  });

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  useEffect(() => {
    if (!Object.keys(profileResult).length) {
      dispatch(ProfileThunk());
    }
  }, []);
  return (
    <header
      id="header"
      className={"header" + " " + (openMenu ? "open" : "header")}
    >
      <div className="row" syle={{ zIndex: 100000, position: "relative" }}>
        {showAlert ? <NoAlertComponent top={270} title={"No Alerts."} /> : ""}

        <h2 className="header__title">{title}</h2>
        <div className="header__right">
          <div className="notification">
            <div
              className="notification__btn"
              onClick={() => {
                setTimeout(() => {
                  setShowAlert(true);
                });
                setTimeout(() => {
                  setShowAlert(false);
                }, 2000);
              }}
            >
              <i className="icon-notification"></i>
              {/* <span className="notification__status"></span> */}
            </div>
          </div>
          <div
            className={"user" + " " + (openLogOut ? "open" : "user")}
            onClick={() => {
              setOpenLogOut(!openLogOut);
            }}
          >
            {profileResult?.avatar ? (
              <img
                src={profileResult?.avatar}
                alt=""
                className="user__avatar"
              />
            ) : (
              <img src={user} alt="" className="user__avatar" />
            )}
            <p className="user__name">{profileResult?.fullName}</p>
            <div
              className="log__out"
              onClick={() => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("verificationIsAuth");
                localStorage.removeItem("acceptToken");
                localStorage.removeItem("zoom");
                window.location.href = "/login";
              }}
            >
              <img src={log__out} alt="" className="log__out__icon" />
              <div className="log__out__text">Log Out</div>
            </div>
          </div>
          <div className="setting__btn">
            <i
              className="icon-setting"
              onClick={() => {
                navigate("/settings");
              }}
            ></i>
          </div>
        </div>
      </div>
    </header>
  );
}
