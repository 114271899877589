export const REGISTRATION_LOADING = 'registration_loading';
export const REGISTRATION_ERROR = 'registration_error';
export const REGISTRATION_SUCCESS = 'registration_success';

const registrationState = {
    reg_loading: false,
    reg_error: null,
    reg_success: false,
}

export function RegistrationReducer(state = registrationState, action) {
    if (action.type === REGISTRATION_LOADING) {
        return {
            ...state,
            reg_loading: true,
        }
    }

    else if (action.type === REGISTRATION_ERROR) {
        return {
            ...state,
            reg_loading: false,
            reg_error: action.paylod.error,
        }
    }

    else if (action.type === REGISTRATION_SUCCESS) {
        return {
            ...state,
            reg_loading: false,
            reg_error: null,
            reg_success: true,
        }
    }
    return state;
}