export const INVESTMENTRISK_SUCCESS = 'investmentrisks_success';

const investmentRistState ={
    investmentRiskResult:[]
};

export function InvestmentrisksReducer(state=investmentRistState,action){
    if (action.type === INVESTMENTRISK_SUCCESS) {
        return {
            ...investmentRistState,
            investmentRiskResult:action.payload.result,
        }
    }
    return state;
}