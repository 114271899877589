import { useEffect, useRef } from "react";

const useInactivityTimeout = (callback, timeout = 1800000) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(callback, timeout);
  };

  useEffect(() => {
    const events = ["mousemove", "keypress", "scroll", "keydown"]; //'click'

    const handleActivity = () => {
      resetTimer();
    };

    events.forEach((event) => window.addEventListener(event, handleActivity));

    resetTimer();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
    };
  }, [callback, timeout]);

  return null;
};

export default useInactivityTimeout;
