export const UPDETE_LIGHT_NTF_INVESTMENT_ALERT_SUCCESS = 'update_light_ntf_investment_alert_success';


const updateInvestmentAlertState = {
    updateLightNTFInvestmentAlertSuccess: false,
}

export function UpdateLighNTFInvestmentAlertReducer(state = updateInvestmentAlertState, action) {

    if (action.type === UPDETE_LIGHT_NTF_INVESTMENT_ALERT_SUCCESS) {
        return {
            ...updateInvestmentAlertState,
            updateLightNTFInvestmentAlertSuccess: true,
        }

    }

    return state;

}