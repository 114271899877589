export const LIGHT_NTF_INVESTMENT_RISK = 'light_ntf_investment_risk';


const lightNTFInvestmentRiskState = {
    lightNTFInvestmentRiskResult: [],
};

export function LightNTFInvestmentRiskReducer(state = lightNTFInvestmentRiskState, action) {
    if (action.type === LIGHT_NTF_INVESTMENT_RISK) {
        return {
            ...lightNTFInvestmentRiskState,
            lightNTFInvestmentRiskResult: action.payload.result,
        }
    }

    return state;

}