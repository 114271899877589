export const CREATE_PARTNER_GUARD_SUCCESS = 'CREATE_PARTNER_GUARD_SUCCESS';
export const CREATE_PARTNER_GUARD_ERROR = 'CREATE_PARTNER_GUARD_ERROR';
export const CREATE_PARTNER_GUARD_CLOSE_MODAL ='CREATE_PARTNER_GUARD_CLOSE_MODAL';

const createPartnerGuardState={
    create_partner_guard_success:false,
    create_partner_guard_error:null,
}

export function CreatePartnerGuardReducer(state = createPartnerGuardState,action){
    if(action.type === CREATE_PARTNER_GUARD_SUCCESS){
        return {
            ...createPartnerGuardState,
            create_partner_guard_success:true,
            create_partner_guard_error:null,
        }
    }
    else if(action.type === CREATE_PARTNER_GUARD_ERROR){
        return {
            ...createPartnerGuardState,
            create_partner_guard_success:false,
            create_partner_guard_error:action.payload.error,
        }   
    }
    else if (action.type === CREATE_PARTNER_GUARD_CLOSE_MODAL){
        return {
            ...createPartnerGuardState,
            create_partner_guard_success:false,
            create_partner_guard_error:null,
        }  
    }
    return state;
}