import { TRASH_SUCCESS } from '../Reducer/TrashReducer';

import { ApiGetTrash } from '../Api';


export function TrashThunk() {
    return (dispatch, getState) => {
        ApiGetTrash()
            .then((result) => {
                dispatch({
                    type: TRASH_SUCCESS,
                    payload: {
                        result: result.data.data.trashes,
                    }

                })
            })

    }
}