import { React, useState, useEffect, useRef } from 'react';

import copy from '../../Assets/img/icons/copy.svg';
import arrow from '../../Assets/img/icons/arrow.svg';

import { WhitelistForm, WalletAddresses } from '../../Components';
import OutsideClickHandler from 'react-outside-click-handler';

import { ApiGetWalletNetworks, ApiGetWallets } from '../../Api';
import { WalletShildAccept } from '../../Modal/WalletShildAccept';


export function AddWalletCard({ formik, sendWhiteList, addressCurentValue, setAddressCurentValue, networkValue, walletData, getLimitValues }) {
    const coldprops = {
        percent: 100, // is require
        colorSlice: '#214175',
        backgroundColor: '#3e98c7',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 200,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const [showWhiteList, setShowWhiteList] = useState(true);
    const [whiteListIsOpen, setWhiteListIsOpen] = useState(false);
    const [addWhiteLidtForm, setAddWhiteLidtForm] = useState([{ id: 1, value: '' }, { id: 2, value: '' }]);

    const [limitBlockIsOpen, setLimitBlockIsOpen] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openWalletForm, setOpenWalletForm] = useState(false);
    const [WalletFormValue, setWalletFormValue] = useState([
        // { id: 1, value: 'Hot Wallet 1 Trezor BTC' },
        // { id: 2, value: 'Cold Wallet 1 Trezor ETH' },
        // { id: 3, value: 'Hot Wallet 2 Trezor BTC' },
        // { id: 4, value: 'Cold Wallet 2 Trezor ETH' },
    ]);
    const [copyFromWalletFormValue, setcopyFromWalletFormValue] = useState([
    ]);

    const [copyFormValue, setCopyFormValue] = useState('Copy from Wallet');

    const [mainInputValue, setMainInputValue] = useState({
        value: '',
    });


    const [openAssetsvalue, setOpenAssetsvalue] = useState(false);

    const limit = useRef(null);

    const [assetsValue, setAssetsValue] = useState('ETHEREUM');
    const [assetsItem, setAssetsItem] = useState([]);
    const [file, setFile] = useState('Copy from File');

    useEffect(() => {
        ApiGetWalletNetworks()
            .then((result) => {
                const assets = [];
                for (let i = 0; i < result.data.data.length; i++) {
                    assets.push({
                        id: i,
                        value: result.data.data[i],
                    });
                }

                setAssetsItem(
                    [...assets]

                );
            })
            .catch((error) => {

            })

    }, []);

    useEffect(() => {

        if (walletData && walletData?.network) {
            setAssetsValue(walletData?.network)
            const network = [];
            network.push({
                id: 1,
                value: walletData?.network[0].toUpperCase() + assetsValue?.slice(1, assetsValue.length).toLowerCase()
            })
            setWalletFormValue([...network]);
        }
        else {
            setWalletFormValue([
                { id: 1, value: 'Ethereum' },
                { id: 2, value: 'Rinkeby' },
                { id: 3, value: 'Bitcoin' },
                { id: 4, value: 'Bitcoin_testnet' }])
        }

    }, [walletData]);



    useEffect(() => {
        if (walletData) {
            const currentWalletData = walletData.addresses.map((e, index) => {
                return {
                    index: index,
                    value: e
                }
            })
            setAddressCurentValue(currentWalletData)
        }
        else {
            setAddressCurentValue(addressCurentValue)
        }

    }, []);


    // const balanceonchange = (e) => {
    //     formik.setFieldValue('balance_limit', new Intl.NumberFormat('en-US').format(e.target.value.replaceAll(/[^0-9]/g, '')))
    // }


    useEffect(() => {
        ApiGetWallets()
            .then((result) => {
                setcopyFromWalletFormValue([...result.data.data]);
            })
    }, []);


    return (
        <div className="modal__body">
            <div className="wallet__addresses">
                <div className="wallet__addresses__top">
                    <div className='wallet__address__container'>

                        <OutsideClickHandler onOutsideClick={(event) => {
                            setOpenAssetsvalue(false)
                        }}>

                            <div className="wallet__addresses__top__left">
                                <div className="wallet__addresses__top__label" style={{ margin: '5px 30px' }}>Asset</div>
                                <div className="wallet__type assets__wallet__type"
                                    onClick={(() => {
                                        if (!walletData) {
                                            setOpenAssetsvalue(!openAssetsvalue)
                                            setCopyFormValue('Copy from Wallet');
                                        }

                                    })}>
                                    <img src={arrow} alt="" className="wallet__type__arrow" />
                                    <div className="wallet__type__item selected selected__item">{assetsValue[0].toUpperCase() + assetsValue?.slice(1, assetsValue.length).toLowerCase()}</div>
                                    {
                                        openAssetsvalue ?
                                            <> {
                                                assetsItem.filter((e) => e.value?.toLowerCase() !== assetsValue?.toLowerCase()).map((element) => {
                                                    const label = element?.value[0].toUpperCase() + element?.value.slice(1, element?.value.length).toLowerCase();
                                                    return <div className="wallet__type__item" key={Math.random()}
                                                        onClick={(() => {
                                                            setAssetsValue(element?.value)
                                                            networkValue(element?.value)
                                                        })}>{label}</div>
                                                })
                                            }
                                            </> : ''
                                    }
                                </div>
                            </div>


                        </OutsideClickHandler>

                        <div className='addresses_items'>
                            <div className="wallet__addresses__top__middle">
                                <div className="wallet__addresses__top__label">Wallet Addresses</div>
                            </div>
                            <WalletAddresses main={true} placeholder={'Wallet Address'} id="main" value={mainInputValue.value}
                                action={(e) => {
                                    setAddressCurentValue([...addressCurentValue, {
                                        index: addressCurentValue?.length,
                                        value: e.value
                                    }])

                                }}
                            />

                            {
                                addressCurentValue?.map((dt, index) => {
                                    return <WalletAddresses walletData={walletData} placeholder={'Wallet Address'} id={index} key={index} dt={dt} value={dt.value}
                                        onChange={(e) => {
                                            setAddressCurentValue(addressCurentValue?.map(item => {
                                                if (item.index === index) {
                                                    return {
                                                        ...item,
                                                        value: e.value
                                                    }
                                                }
                                                return item;
                                            }))

                                        }}
                                        action={(e) => { }}

                                        deleteItem={((event) => {
                                            const arry = addressCurentValue.filter((item) => item.index !== event.index)
                                            setAddressCurentValue(arry)

                                        })}
                                    />
                                })
                            }

                        </div>

                        <div>
                            <div className="wallet__addresses__top__middle">
                                <div className="wallet__addresses__top__label" style={{ opacity: '0' }}>Whitelist</div>
                            </div>
                            <div className="wallet__addresses__top__right">
                                <button className="wallet__addresses__top__right__btn" onClick={(() => {
                                    setWhiteListIsOpen(!whiteListIsOpen)
                                })}>Whitelist</button>
                            </div>
                        </div>

                    </div>
                    {
                        whiteListIsOpen ?
                            <div className="whitelist__ad__block__wrapper">
                                <div className="whitelist__ad__block">
                                    <div className='close__card__content whitelist__close__card'
                                        onClick={(() => {
                                            setWhiteListIsOpen(false)
                                        })}>
                                        <span className="card__close"
                                        >
                                            <span></span><span></span>
                                        </span>
                                    </div>
                                    <div className="WhitelistForm__container">


                                        {
                                            addWhiteLidtForm.map((element) => {
                                                return <WhitelistForm
                                                    key={element.id} value={element.value} index={element.id}
                                                    onChangeValue={(value, index) => {
                                                        element.value = value;
                                                        sendWhiteList([...addWhiteLidtForm])
                                                    }}

                                                />
                                            })
                                        }

                                    </div>
                                    <span className="add__wallet__btn__plus__bg"
                                        onClick={(() => {
                                            setAddWhiteLidtForm([...addWhiteLidtForm, {
                                                id: addWhiteLidtForm.length + 1,
                                            }])
                                        })}
                                    >
                                        <span></span><span></span>
                                    </span>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <OutsideClickHandler onOutsideClick={(event) => {
                                            setOpenForm(false)
                                        }}>
                                            <div className={'dropdown' + ' ' + (openForm ? 'open' : 'dropdown')}>

                                                <div className="dropdown__selected" onClick={((event) => {
                                                    setOpenForm(!openForm)

                                                })}>
                                                    Copy from
                                                    <img src={arrow} alt="" className="dropdown__arrow" />
                                                </div> {
                                                    openForm ?

                                                        <div className="dropdown__body">
                                                            <div className="dropdown__copy">
                                                                {/* Copy from File */}
                                                                {file}
                                                                <div >
                                                                    <label className="dropdown__copy__icon__wrapper" htmlFor="filePicker">
                                                                        <img src={copy} alt="" className="dropdown__copy__icon" />
                                                                    </label>

                                                                    <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={((event) => {
                                                                        setFile(event.target.value)
                                                                    })}></input>
                                                                    { }
                                                                </div>
                                                                {/* <label htmlFor="filePicker" style={{ background: "grey", padding: "5px 10px" }}>
                                                                    My custom choose file label
                                                                </label>
                                                                <input id="filePicker" style={{ visibility: "hidden" }} type={"file"}></input> */}
                                                            </div>
                                                            <div className={'dropdown__sub' + ' ' + (openWalletForm ? 'open' : 'dropdown__sub')}>
                                                                <div className="dropdown__sub__selected" onClick={(() => {
                                                                    setOpenWalletForm(!openWalletForm)
                                                                })}>
                                                                    {copyFormValue}
                                                                    <img src={arrow} alt="" className="dropdown__arrow" style={{ right: '8px' }} />
                                                                </div>
                                                                {
                                                                    openWalletForm ?
                                                                        <div className="dropdown__sub__body" >
                                                                            {

                                                                                copyFromWalletFormValue.filter((e) => e.network === assetsValue.toUpperCase()).map((element) => {
                                                                                    return <div className="dropdown__sub__item" key={element.id}
                                                                                        onClick={() => {
                                                                                            setCopyFormValue(element?.name)
                                                                                            // setcopyFromWalletFormValue(element.name)
                                                                                            setOpenWalletForm(false)
                                                                                        }}
                                                                                    >{element.name}</div>
                                                                                })
                                                                            }


                                                                        </div> : ''

                                                                }

                                                            </div>
                                                        </div>
                                                        : ''}

                                            </div>
                                        </OutsideClickHandler>
                                    </div>

                                </div>
                            </div> : ''
                    }
                </div>


                {/* balance */}
                <form onSubmit={formik.handleSubmit}>
                    <div className="wallet__addresses__bottom">
                        <div className="wallet__addresses__bottom__left">
                            <div className="limit__block">
                                <div className="limit__block__top balance__block__top">Balance limit</div>
                                <div className="input-box limit__block__bottom">
                                    <span className="prefix">$</span>
                                    <input className=" flex_input" placeholder='5,000,000' name="balance_limit" id="balance_limit"
                                        value={formik.values.balance_limit}
                                        onChange={((e) => {
                                            // formik.setFieldValue('balance_limit', new Intl.NumberFormat('en-US').format(e.target.value.replaceAll(/[^0-9]/g, '')))
                                            formik.setFieldValue('balance_limit', e.target.value)
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="wallet__addresses__bottom__middle">

                            <ul className="limit__block__tabs">
                                <li className={'limit__block__tabs__list' + ' ' + (showWhiteList ? 'active' : 'limit__block__tabs__list')}
                                    onClick={(() => {
                                        setShowWhiteList(true)

                                    })}>Whitelisted</li>
                                <li className={'limit__block__tabs__list' + ' ' + (!showWhiteList ? 'active' : 'limit__block__tabs__list')}
                                    onClick={(() => {
                                        setShowWhiteList(false)
                                    })}>Not Whitelisted</li>
                            </ul>
                            {showWhiteList ?
                                <div className='limit__block__wrapper_container'>
                                    <div className="limit__block__wrapper">

                                        <div className="limit__block">
                                            <div className="limit__block__top active balance__block__top">Single Tx limit</div>
                                            <div className="input-box limit__block__bottom">
                                                <span className="prefix">$</span>
                                                <input className="flex_input" placeholder='50,000' name="whitelisted_single_tx_limit" id="whitelisted_single_tx_limit"
                                                    value={formik.values.whitelisted_single_tx_limit}
                                                    onChange={((e) => {
                                                        formik.setFieldValue('whitelisted_single_tx_limit', e.target.value)
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        <div className="limit__block">
                                            <div className="limit__block__top active"
                                                onClick={() => {
                                                    setLimitBlockIsOpen(true)
                                                }}
                                            >
                                                <div>{limit.current ? `${limit.current} hours` : '1h'} limit</div>
                                            </div>
                                            <div className="input-box limit__block__bottom">
                                                <span className="prefix">$</span>
                                                <input className="flex_input" placeholder='20,000' name="whitelisted_limit" id="whitelisted_limit"
                                                    value={formik.values.whitelisted_limit}
                                                    onChange={((e) => {
                                                        formik.setFieldValue('whitelisted_limit', e.target.value)
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="limit__block" >
                                            <div className="limit__block__top active"
                                            // onClick={() => {
                                            //     setLimitBlockIsOpen(!limitBlockIsOpen)
                                            // }}
                                            >1h  limit</div>
                                            <div className="input-box limit__block__bottom">
                                                <span className="prefix">$</span>
                                                <input className="flex_input" placeholder='30,000' disabled type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <OutsideClickHandler
                                        onOutsideClick={(event) => {
                                            setLimitBlockIsOpen(false)

                                        }}


                                    >
                                        {
                                            limitBlockIsOpen ?
                                                <div className='walletShildAccept'>
                                                    <WalletShildAccept
                                                        modalClose={((event) => {
                                                            setLimitBlockIsOpen(event)
                                                        })}
                                                        sendValues={({ days, hours, minutes }) => {
                                                            const calculatedLimit = Number((Number(days) * 24 + Number(hours) + Number(minutes) / 60).toFixed(1));
                                                            limit.current = calculatedLimit;
                                                            getLimitValues(calculatedLimit)
                                                        }}
                                                    />
                                                </div> : null
                                        }


                                    </OutsideClickHandler>

                                </div>

                                :
                                <div className='limit__block__wrapper_container'>
                                    <div className="limit__block__wrapper">

                                        <div className="limit__block">
                                            <div className="limit__block__top active balance__block__top">Single Tx limit</div>
                                            <div className="input-box limit__block__bottom">
                                                <span className="prefix">$</span>
                                                <input className="flex_input" placeholder='50,000'
                                                    name="not_whitelisted_single_tx_limit" id="not_whitelisted_single_tx_limit"
                                                    value={formik.values.not_whitelisted_single_tx_limit}
                                                    onChange={((e) => {
                                                        formik.setFieldValue('not_whitelisted_single_tx_limit', e.target.value)
                                                    })}

                                                />
                                            </div>
                                        </div>
                                        <div className="limit__block">
                                            <div className="limit__block__top active"
                                            // onClick={() => {
                                            //     setLimitBlockIsOpen(!limitBlockIsOpen)
                                            // }}
                                            >
                                                <div>1h limit</div>
                                            </div>
                                            <div className="input-box limit__block__bottom">
                                                <span className="prefix">$</span>
                                                <input className="flex_input" placeholder='20,000' name="not_whitelisted_window" id="not_whitelisted_window"
                                                    value={formik.values.not_whitelisted_window}
                                                    onChange={((e) => {
                                                        formik.setFieldValue('not_whitelisted_window', e.target.value)
                                                    })}

                                                />
                                            </div>
                                        </div>

                                        <div className="limit__block">
                                            <div className="limit__block__top active"
                                            // onClick={() => {
                                            //     setLimitBlockIsOpen(!limitBlockIsOpen)
                                            // }}
                                            >
                                                1h limit
                                            </div>
                                            <div className="input-box limit__block__bottom">
                                                <span className="prefix">$</span>
                                                <input className="flex_input" placeholder='30,000' name="not_whitelisted_limit" id="not_whitelisted_limit"
                                                    value={formik.values.not_whitelisted_limit}
                                                    onChange={((e) => {
                                                        formik.setFieldValue('not_whitelisted_limit', e.target.value)
                                                    })}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <OutsideClickHandler
                                        onOutsideClick={(event) => {
                                            setLimitBlockIsOpen(false)

                                        }}>
                                        {
                                            limitBlockIsOpen ?
                                                <div className='walletShildAccept'>
                                                    <WalletShildAccept modalClose={((event) => {
                                                        setLimitBlockIsOpen(event)
                                                    })} />
                                                </div> : null
                                        }


                                    </OutsideClickHandler> */}
                                </div>
                            }
                        </div>

                        <div className="wallet__addresses__bottom__right">
                            <div className="anomalies__check__wrapper">
                                <input type="checkbox" className="anomalies__check" name="anomalies_detection" id="anomalies_detection"
                                    value={formik.values.anomalies_detection}
                                    onChange={formik.handleChange} />
                                {/* <label className="anomalies__label"> */}
                                <label htmlFor="anomalies_detection" className="anomalies__label">
                                    <span className="anomalies__text">
                                        Anomalies Detection
                                        <span className="anomalies__check__box"></span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </form>


                {/* balance */}



            </div>
        </div>
    )
}