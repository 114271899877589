import { React, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiFilter } from '../../Api';
import { CONNECTION_ALL_FILTER } from '../../Reducer/ConectionFilterAllReducer';


import low from '../../Assets/img/icons/low.svg';
import high from '../../Assets/img/icons/high.svg';
import { useDispatch, useSelector } from 'react-redux';
import reset from '../../Assets/img/icons/reset.svg';



export function FilterComponent({ urlpath, activeclassName }) {
    const dispatch = useDispatch();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const searchData = useRef({
        assetId: [],
        blockchainId: [],
        platformId: [],
    });


    const [assetsResult, setAssetsResult] = useState([]);

    const navigate = useNavigate();

    const [blockchainsResult, setBlockainResult] = useState([]);

    const [platformResult, setPLatformResult] = useState([]);

    const connectionFilterData = useSelector((state) => {
        return state.conectionFilterAllReducer.connectionFilterData;
    })


    useEffect(() => {
        loadConection('', '');
    }, []);


    useEffect(() => {
        if (connectionFilterData.assets) {
            setAssetsResult(connectionFilterData.assets)
        }
        if (connectionFilterData.blockchains) {
            setBlockainResult(connectionFilterData.blockchains)
        }

        if (connectionFilterData.platforms) {
            setPLatformResult(connectionFilterData.platforms)
        }

    }, [connectionFilterData]);

    const loadConection = (assetId, blockchainId) => {
        ApiFilter(assetId, blockchainId)
            .then((result) => {
                dispatch({
                    type: CONNECTION_ALL_FILTER,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => {

            })
    }

    const updateQueries = () => {
        const url = urlpath;
        const paramsStr = JSON.stringify({
            assets: searchData.current.assetId,
            blockchains: searchData.current.blockchainId,
            platforms: searchData.current.platformId,
            orderRisk:searchData.current.orderRisk
        });
        const path = `${url}?q=${paramsStr}`;
        navigate(path);
    }

    return (
        <>
            <div className={"filter__block " + activeclassName}>
                <div className="filter__block__btn__block"></div>
                <div className="filter__block__scroll">
                    <h3 className="filter__block__title">Filter</h3>
                    <button className="reset__btn"
                        onClick={(() => {
                            searchData.current = {
                                ...searchData.current || {},
                                assetId: [],
                                blockchainId: [],
                                platformId: [],
                                orderRisk:''
                            };

                            updateQueries();
                        })}><img src={reset} /></button>
                    <div className="filter__risk__block">
                        <div className="risk__btn__row">
                            <button className="risk__btn" onClick={(() => {
                                searchData.current = {
                                    ...searchData.current || {},
                                    orderRisk: 'DESC',
                                };
                                updateQueries();
                            })}> <img src={high} /></button>
                            <span className="risk__btn__text">Low risk .....</span>
                        </div>
                        <div className="risk__btn__row">
                            <button className="risk__btn" onClick={(() => {
                                searchData.current = {
                                    ...searchData.current || {},
                                    orderRisk: 'ASC',
                                }
                                updateQueries();
                            })}><img src={low} /></button>
                            <span className="risk__btn__text">High risk .....</span>
                        </div>
                    </div>
                    {
                        assetsResult.length > 0 ?

                            <div className="asset__wrapper">
                                <h3 className="asset__title">Asset</h3>
                                <ul className="asset__block">
                                    {
                                        assetsResult?.map((element, index) => {
                                            const elementId = uuid();
                                            return <li className="asset__list" key={index + 1}>
                                                <input type="checkbox" className="asset__list__check"
                                                    checked={searchData.current.assetId.includes(element.id)}
                                                    id={elementId}
                                                    value={element?.id}
                                                    onChange={((event) => {
                                                        if (event.target.checked) {
                                                            searchData.current.assetId.push(element.id);
                                                        }
                                                        else {
                                                            const fIndex = searchData.current.assetId.findIndex((e) => e === element.id);
                                                            if (fIndex > -1) {
                                                                searchData.current.assetId.splice(fIndex, 1);
                                                            }
                                                        }
                                                        updateQueries();
                                                    })}
                                                />
                                                <label htmlFor={elementId} className="asset__list__label">
                                                    <span className="asset__list__box"></span>
                                                    <span className="asset__list__text">{element?.symbol}</span>
                                                </label>
                                            </li>
                                        })
                                    }

                                </ul>
                            </div> : null}
                    {
                        blockchainsResult.length > 0 ?
                            <div className="asset__wrapper">

                                <h3 className="asset__title">Blockchain</h3>


                                <ul className="asset__block">
                                    {
                                        blockchainsResult?.map((element, index) => {
                                            const elementId = uuid();
                                            return <li className='asset__list' key={index + 1}>
                                                <input type="checkbox" className="asset__list__check"
                                                    checked={searchData.current.blockchainId.includes(element.id)}
                                                    id={elementId}
                                                    value={element?.id}
                                                    onChange={((event) => {
                                                        if (event.target.checked) {
                                                            searchData.current.blockchainId.push(element.id);
                                                        }
                                                        else {
                                                            const fIndex = searchData.current.blockchainId.findIndex((e) => e === element.id);
                                                            if (fIndex > -1) {
                                                                searchData.current.blockchainId.splice(fIndex, 1);
                                                            }
                                                        }
                                                        updateQueries();
                                                    })}
                                                />
                                                <label htmlFor={elementId} className="asset__list__label">
                                                    <span className="asset__list__box"></span>
                                                    <span className='asset__list__text'>{element?.name}</span>
                                                </label>
                                            </li>
                                        })
                                    }


                                </ul>
                            </div> : null}
                    {
                        platformResult?.length > 0 ?
                            <div className="asset__wrapper">

                                <h3 className="asset__title">Platform</h3>
                                <ul className="asset__block">
                                    {
                                        platformResult.map((element, index) => {
                                            const elementId = uuid();
                                            return <li className='asset__list' key={index + 1} >
                                                <input type="checkbox" className="asset__list__check"
                                                    checked={searchData.current.platformId.includes(element.id)}
                                                    id={elementId}
                                                    value={element?.id}
                                                    onChange={((event) => {
                                                        if (event.target.checked) {
                                                            searchData.current.platformId.push(element.id);
                                                        }
                                                        else {
                                                            const fIndex = searchData.current.platformId.findIndex((e) => e === element.id);
                                                            if (fIndex > -1) {
                                                                searchData.current.platformId.splice(fIndex, 1);
                                                            }
                                                        }
                                                        updateQueries();
                                                    })}
                                                />
                                                <label htmlFor={elementId} className="asset__list__label">
                                                    <span className="asset__list__box"></span>
                                                    <span className='asset__list__text'>{element?.name}</span>
                                                </label>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div> : null
                    }

                </div>
            </div>
        </>
    )
}
