import {CREATE_PARTNER_GUARD_SUCCESS,CREATE_PARTNER_GUARD_ERROR} from '../Reducer/CreatePartnerGuard';

import {ApiCreatePartnerGuard} from '../Api';


export function CreatePartnerGuardThunk(id){
    return (dispatch,getState) =>{
        ApiCreatePartnerGuard(id)
        .then((result)=>{
            dispatch({
                type:CREATE_PARTNER_GUARD_SUCCESS
            })
        })
        .catch((error)=>{
            dispatch({
                type: CREATE_PARTNER_GUARD_ERROR,
                payload: {
                    error: error?.response?.data?.error?.message,
                }

            })
        })
    }
}