import { useState } from 'react';
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { ApiGetAllLightNT } from '../../Api';
import { GET_LIGHT_NTF_SUCCESS } from '../../Reducer/GetLightNtfReducer';

import { LightNTFItemComponent } from '../../Components/LightNTFItem';

import { CREATE_LIGHT_NTF_CLOSE_MODAL } from '../../Reducer/CreateLightNTFReducer';
import {CreateLightNtfThunk} from '../../Thunks/CreateLightNtfThunk';

export function AddLightNTFModal({ modalClose }) {

    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);

    const [searchInputValue, setSearchInputValue] = useState('');


    const lightNTFResult = useSelector((state) => {
        return state.getLightNTFReducer.lightNtfResult;
    });

    const light_ntf_error = useSelector((state) => {
        return state.createLightNTFReducer.create_light_ntf_error;
    });

    const create_light_ntf_success = useSelector((state) => {
        return state.createLightNTFReducer.create_light_ntf_success;
    });



    useEffect(() => {
        getAllLightNT('');
    }, []);


    useEffect(() => {
        if(create_light_ntf_success){
            getAllLightNT('');
        }
      
    }, [create_light_ntf_success])


    const getAllLightNT = (value) => {
        ApiGetAllLightNT(value)
            .then((result) => {
                dispatch({
                    type: GET_LIGHT_NTF_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        if (light_ntf_error) {
            setShowError(true)
        }
        else {
            setShowError(false)
        }

    }, [light_ntf_error]);



    return (
        <>
            <div className="card empty nft">
                <div className="card__header" >
                    <div className="row light_defi_row ">
                        {
                            !showError ?
                                <h2 className="card__title">
                                    {/* BAYC */}
                                    <span className="card__title__type">Add NFT Collection</span>
                                </h2> :
                                <span className='error__message login_requests__message'>{light_ntf_error}</span>
                        }

                        <div className='close__card__content'>
                            <span className="card__close" onClick={(() => {
                                modalClose(false)
                                dispatch({
                                    type: CREATE_LIGHT_NTF_CLOSE_MODAL
                                })
                            })}>
                                <span></span><span></span>
                            </span>
                        </div>

                    </div>
                </div>


                <div className="card__nft__wrapper">
                    <div className='light_ntf_item_content'>
                        <div className="ntf__filter__content">
                            <input className='ntf__filter__input' placeholder='Search Collection' value={searchInputValue}
                                onChange={((event) => {
                                    setSearchInputValue(event.target.value)
                                    getAllLightNT(event.target.value);
                                })} />
                        </div>
                        <div className='light_ntf_item'>
                            <div className='light_ntf_item_information'>
                                <span className='light_ntf_item_span all_conections_ntf' onClick={(() => {
                                  
                                        dispatch(CreateLightNtfThunk(0));
                                })}>Add All Collections</span>
                            </div>

                        </div>
                        {
                            lightNTFResult ?
                                <div className='card__nft__wrapper_content'>

                                    {
                                        lightNTFResult.map((element, index) => {
                                            return <LightNTFItemComponent element={element} key={index + 1} />
                                        })
                                    }

                                </div>
                                : null

                        }
                    </div>
                </div>
            </div>


        </>
    )
}