import { React } from 'react';

export function ApprovedModal({ cancel }) {

    return (
        <div className='approves__modal'>
            <div className="card__product__stake__amount open delete_module">
                <h3 className="title">Your account will be reviewed and approved by the administrator. Upon       approval, a notification email will be send.</h3>
                <div className='btn_content'>
                    <button className="card__product__stake__amount__confirm approve_btn" onClick={(() => {
                        cancel(false)
                   
                    })}>Close</button>
                </div>

            </div>
        </div>
    )
}