import { React,useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as yup from 'yup';

import {Loading} from '../../../../../../../Components/index';
import {WebHookUrlThunk} from '../../../../../../../Thunks/index';

export function WebHookForm() {

    const dispatch = useDispatch();
    const [stateError, setStateError] = useState();

      const webHook_Url_Error = useSelector((state) => {
        return state.webHookUrl.web_hook_url_error;
    });

    const webHook_Url_loading = useSelector((state) => {
        return state.webHookUrl.web_hook_url_loading;
    });


    const webHook_Url_success = useSelector((state) => {
        return state.webHookUrl.web_hook_url_success;
    });
    
        const schema = yup.object().shape({
            webhookUrl: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.'),
        })

        const formik = useFormik({
            enableReinitialize: true,
            initialValues: {
                webhookUrl: ''
            },
            validationSchema: schema,
            onSubmit: values => {
                dispatch(WebHookUrlThunk(values));
            }
        });

        const deleteErrorButton = (fieldKey) => {
            if (fieldKey) {
                setStateError(null)
    
            }
        };
           useEffect(() => {
            if (webHook_Url_Error) {
                setStateError(webHook_Url_Error);
            }
        }, [webHook_Url_Error]);


    return <>
        <form className="password__change__block" onSubmit={formik.handleSubmit}>
            <h4 className="password__change__block__title">Add webhook URL</h4>
            <span className='error__message requests__message'>{stateError}</span>
            <label htmlFor="webhookUrl" className="password__change__label">
                <span className="password__change__label__text">Url</span>
              
                <input placeholder="Url" type="text"  className={'password__change__input' + ' ' + (formik.touched.webhookUrl && formik.errors.webhookUrl  ? 'form__content__error' : 'password__change__input')} id="webhookUrl" name="webhookUrl"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={() => deleteErrorButton('webhookUrl')}
                />
                  {formik.touched.webhookUrl && formik.errors.webhookUrl ? <span className='error__message' style={{margin:'3px auto'}}>{formik.errors.webhookUrl}</span> : null}
            </label>
        

            <button className="password__change__btn">Save {webHook_Url_loading ? <Loading /> : null}</button>
        </form> 
    </>
}