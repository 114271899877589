import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Header } from "../../../Components";

import {
  UserSettings,
  LoginSettings,
  DevelopersSettings,
  NotificationSettings,
} from "./Components";

import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";

export function Settings() {
  const dispatch = useDispatch();

  const [settingTab, setSettingsTab] = useState(1);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  return (
    <>
      <Header title={"Settings"} />
      <main className={"main setting_main " + (openMenu ? "open" : "")}>
        <div className="setting">
          <div className="" style={{ display: "flex" }}>
            <UserSettings />
            <div className="login__settings__wrapper">
              <div className="settings__tabs__wrapper">
                <ul className="settings__tabs">
                  <li
                    className="settings__tabs__list"
                    onClick={() => {
                      setSettingsTab(1);
                    }}
                  >
                    <a
                      id="settings__tabs__link-1"
                      className={
                        "settings__tabs__link" +
                        " " +
                        (settingTab === 1
                          ? "settings__tabs__link active"
                          : "settings__tabs__link")
                      }
                    >
                      Login Settings
                    </a>
                  </li>
                  <li
                    className="settings__tabs__list"
                    onClick={() => {
                      setSettingsTab(2);
                    }}
                  >
                    <a
                      id="settings__tabs__link-2"
                      className={
                        "settings__tabs__link" +
                        " " +
                        (settingTab === 2
                          ? "settings__tabs__link active"
                          : "settings__tabs__link")
                      }
                    >
                      Notification Settings
                    </a>
                  </li>
                  <li
                    className="settings__tabs__list"
                    onClick={() => {
                      setSettingsTab(3);
                    }}
                  >
                    <a
                      id="settings__tabs__link-3"
                      className={
                        "settings__tabs__link" +
                        " " +
                        (settingTab === 3
                          ? "settings__tabs__link active"
                          : "settings__tabs__link")
                      }
                    >
                      For Developers
                    </a>
                  </li>
                </ul>
              </div>
              <div className="login__settings__body">
                {settingTab === 1 ? <LoginSettings /> : null}

                {settingTab === 2 ? <NotificationSettings /> : null}
                {settingTab === 3 ? <DevelopersSettings /> : null}
              </div>
              <p style={{ textAlign: "end" }} className="version__title">
                Version 1.2
              </p>
            </div>
          </div>
        </div>
        <div className="zoom__page__btn" style={{ margin: "10px" }}>
          <img
            src={zoom_pluse}
            width="24"
            height="24"
            alt=""
            className="zoom__page__btn__plus"
            onClick={zoomClose}
          />
          <img
            src={zoom_minus}
            width="24"
            height="24"
            alt=""
            className="zoom__page__btn__minus"
            onClick={zoom}
          />
        </div>
      </main>
    </>
  );
}
