import { React, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  Header,
  PartnerGuardItem,
  PartnerGuardFilter,
  LogoLoading,
  PartnerGuardAside,
} from "../../../Components/index";

import { AddPartnerGuardModal } from "../../../Modal/AddPartnerGuardModal";

import { GetPartnerGuardThunk } from "../../../Thunks/GetPartnerGuardThunk";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";

import {
  PARTNER_GUARD_FETCHED,
  PARTNER_GUARD_PRODUCT_SUCCESS,
} from "../../../Reducer/GetPartnerGuardProductReducer";

import { CREATE_PARTNER_GUARD_CLOSE_MODAL } from "../../../Reducer/CreatePartnerGuard";

import { PartnerGuardInvestmentRiskThunk } from "../../../Thunks/PartnerGuardInvestmentRiskThunk";
import { PartnerGuardInvestmentRiskAlertThunk } from "../../../Thunks/PartnerGuardInvestmentAlertThunk";

import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";
import filter from "../../../Assets/img/icons/filter.svg";
import top from "../../../Assets/img/icons/to__top.svg";

let interval;

export function PartnerGuard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let searchData = {};
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [openFilter, SetOpenFilter] = useState(false);
  const [searchParams, setsearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const partnerGuardResult = useSelector((state) => {
    return state.getPartnerGuardReducer.partnerGuardResult;
  });

  const partner_guard_success = useSelector((state) => {
    return state.createPartnerGuardReducer.create_partner_guard_success;
  });

  const isPartnerGuardfched = useSelector((state) => {
    return state.getPartnerGuardProductReducer.isPartnerGuardFetched;
  });

  useEffect(() => {
    document.getElementById("scrollContent").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isPartnerGuardfched) {
      if (partnerGuardResult?.length === 0 || modalIsOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [isPartnerGuardfched, partnerGuardResult.length, modalIsOpen]);

  useEffect(() => {
    if (partnerGuardResult?.length === 0 && !modalIsOpen) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [partnerGuardResult.length, modalIsOpen]);

  useEffect(() => {
    dispatch({
      type: PARTNER_GUARD_FETCHED,
    });
  }, []);

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  const getPartnerGuard = () => {
    const params = { ...searchData };
    dispatch(GetPartnerGuardThunk(params.orderRisk));
  };

  useEffect(() => {
    if (partner_guard_success) {
      setsearchParams(JSON.parse("{}"));
      dispatch({
        type: CREATE_PARTNER_GUARD_CLOSE_MODAL,
      });
      dispatch(GetPartnerGuardThunk());
    }
  }, [partner_guard_success]);

  useEffect(() => {
    searchData = {};
    const search = searchParams.get("q");
    searchData = JSON.parse(search || "{}");
    if (interval) {
      clearInterval(interval);
    }
    getPartnerGuard();
    interval = setInterval(() => {
      getPartnerGuard(false);
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [searchParams.toString()]);

  useEffect(() => {
    navigate("/counterparty-risk");
  }, []);

  return (
    <>
      <Header title={"Counterparty Risk"} />

      <main className={"main" + " " + (openMenu ? "open" : "main")}>
        <div className="content" id="scrollContent">
          <div className="row">
            {modalIsOpen ? (
              <AddPartnerGuardModal
                modalIsOpen={modalIsOpen}
                title={"Counterparty Risk"}
                modalClose={() => {
                  setIsOpen(false);
                }}
              />
            ) : (
              ""
            )}

            {partnerGuardResult.length
              ? partnerGuardResult.map((element) => {
                  return (
                    <PartnerGuardItem
                      key={element?.id}
                      element={element}
                      reloadListCallback={() => {
                        getPartnerGuard();
                        dispatch(PartnerGuardInvestmentRiskThunk());
                        if (modalIsOpen) {
                          dispatch(GetPartnerGuardThunk());
                        }
                      }}
                    />
                  );
                })
              : null}

            <div className="layout__block">
              <div className="layount__right__container">
                <img
                  src={filter}
                  alt=""
                  className="filter__icon"
                  onClick={() => {
                    SetOpenFilter(!openFilter);
                  }}
                />

                <PartnerGuardFilter
                  activeclassName={openFilter ? "open" : "close"}
                  urlpath={"/counterparty-risk"}
                />

                <div className="zoom__page__btn">
                  <img
                    src={zoom_pluse}
                    width="24"
                    height="24"
                    alt=""
                    className="zoom__page__btn__plus"
                    onClick={zoomClose}
                  />
                  <img
                    src={zoom_minus}
                    width="24"
                    height="24"
                    alt=""
                    className="zoom__page__btn__minus"
                    onClick={zoom}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* {
                        loading ?
                            <LogoLoading /> : null
                    } */}
        </div>
        <PartnerGuardAside
          openModal={(event) => {
            setIsOpen(event);
          }}
        />

        {showTopBtn ? (
          <a
            className="to__top"
            onClick={() => {
              document.getElementById("scrollContent").scrollTo(0, 0);
            }}
          >
            <img src={top} alt="top" className="to__top__icon" />
          </a>
        ) : (
          ""
        )}
      </main>
    </>
  );
}
