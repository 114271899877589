import "./App.css";
import "./Assets/css/style.scss";
import { IndexedRoute, Routes } from "./Routes";

function App() {
  return (
    <div className="App">
      <IndexedRoute />
    </div>
  );
}

export default App;
