import { WEBHOOK_URL_SUCCESS, WEBHOOK_URL_ERROR, WEBHOOK_URL_LOADING } from '../Reducer/WebhookUrlReducer';

import { ApiWebhookUrl } from '../Api';

export function WebHookUrlThunk({ webhookUrl }) {
    return (dispatch, getState) => {
        dispatch({
            type: WEBHOOK_URL_LOADING
        })
        ApiWebhookUrl(webhookUrl)
            .then((result) => {
                dispatch({
                    type: WEBHOOK_URL_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: WEBHOOK_URL_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}