import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreateLightNtfThunk } from '../../Thunks/CreateLightNtfThunk';


export function LightNTFItemComponent({ element }) {


    const dispatch = useDispatch();

    return (
        <>
            <div  className={'light_ntf_item' + ' ' + (element.isExist ? 'light_ntf_item_isExist' : 'light_ntf_item')} 
            onClick={(() => {
                if (!element.isExist) {
                    dispatch(CreateLightNtfThunk(element.id));
                }
            })}>
                <div className='light_ntf_item_information'>
                    <img className='light_ntf_item_img' src={element?.image} />
                    <span className='light_ntf_item_span'>{element?.name}</span>
                </div>

                <span>{Math.round(element?.risk)}</span>
            </div>
        </>
    )
}