export const VERIFICATION_SUCCESS = 'verification_success';
export const VERIFICATION_LOADING = 'verification_loading';
export const VERIFICATION_ERROR = 'verification_error';
export const VERIFICATIONT_IS_AUTH = 'verification_is_auth';

const twoFACodeVerificationState = {
    verification_loading: false,
    verification_error: null,
    verification_success: false,
    verification_is_auth: localStorage.getItem('verificationIsAuth')

}

export function TwoFACodeVerificationReducer(state = twoFACodeVerificationState, action) {
    if (action.type === VERIFICATION_LOADING) {
        return {
            ...state,
            verification_loading: true,
        }
    }

    else if (action.type === VERIFICATION_ERROR) {
        return {
            ...state,
            verification_loading: false,
            verification_error: action.paylod.error,
        }
    }

    else if (action.type === VERIFICATION_SUCCESS) {
        // localStorage.setItem('accessToken',action.paylod.token)
        return {
            ...state,
            verification_success:true,

        }
    }
    else if (action.type === VERIFICATIONT_IS_AUTH) {
        localStorage.setItem('verificationIsAuth', true);
        // localStorage.setItem('accessToken',action.paylod.token);
        return {
            ...state,
            verification_is_auth: true,
        }
    }


    return state;
}