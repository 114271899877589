import { React, useEffect, useState } from 'react';

import { DropDown } from '../../Components/Dropdown/index';
import { useDispatch, useSelector } from 'react-redux';

import { AssetsThunks } from '../../Thunks/AssetsThunk';
import { BlockchainsThunk } from '../../Thunks/BlockchainsThunk';
import { PlatformsThunk } from '../../Thunks/PlatformsThunks';
import { CreateDefishieldThunk } from '../../Thunks/CreteDefiShildThunk';
import { SET_DEFISHILD_SUCCESS_FALSE } from '../../Reducer/CreateDefishieldReducer';
import { SET_BLOCKCHATIN_DATA } from '../../Reducer/BlockchainsReducer';
import { SET_PLATFORM_DATA} from '../../Reducer/PlatformsReducer';


export function AddAddDeFiShieldModal({ modalClose, title }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();

    const [dropDownError, setDropDownError] = useState();

    const [assetId, setAssetId] = useState();
    const [blockchainId, setBlockchainId] = useState();
    const [platformId, setPlatformId] = useState();
    const [showSaveBtn, setShowSaveBtn] = useState(false);
    const [assetsDefaultValue, setassetsDefaultValue] = useState(null);
    const [assetsValue, setAssetsValue] = useState(null);
    const [platformDefaultValue, setplatformDefaultValue] = useState(null);
    const [platformValue, setPlatformValue] = useState(null);


    const [blockchainsmDefaultValue, setBlockchainsmDefaultValue] = useState(null);
    const [blockchainsValue, setBlockchainsValue] = useState(null);

    const [openSave, setOpenSave] = useState(true);


    const [allBlockchain, setAllBlockchain] = useState(false);
    const [allPlatform, setAllPlatform] = useState(false);


    const assetsResult = useSelector((state) => {
        return state.assets.assetsResult;
    });

    const blockchainsResult = useSelector((state) => {
        return state.blockchains.blockchainsResult;
    });

    const platformResult = useSelector((state) => {
        return state.platforms.plarformsresult;
    });

    const createDefiShildError = useSelector((state) => {
        return state.creteDefiShild.create_defishild_error;
    });

    const createDefiShildSuccess = useSelector((state) => {
        return state.creteDefiShild.create_defishild_success;
    });




    useEffect(() => {
    }, [windowWidth]);


    useEffect(() => {
        !assetsResult?.length && dispatch(AssetsThunks());
    }, []);

    useEffect(() => {
        if (createDefiShildSuccess) {
            dispatch({
                type: SET_BLOCKCHATIN_DATA,
                payload: {
                    result: []
                }
            });
            dispatch({
                type: SET_PLATFORM_DATA,
                payload: {
                    result: []
                }
            });
            setAssetsValue(null)
            setassetsDefaultValue(null)
            setAssetId(null)
            setplatformDefaultValue(null);
            setPlatformValue(null);
            setBlockchainsmDefaultValue(null);
            setBlockchainsValue(null);
            setDropDownError(null);
         
            dispatch({ type: SET_DEFISHILD_SUCCESS_FALSE });
           
        }
    }, [createDefiShildSuccess]);


    const assetsHandleChange = (selectedOption) => {
        setDropDownError(null)
        dispatch({
            type: SET_BLOCKCHATIN_DATA,
            payload: {
                result: []
            }
        });
        dispatch({
            type: SET_PLATFORM_DATA,
            payload: {
                result: []
            }
        });
        if (!selectedOption) {
            return;
        }
        const id = selectedOption.value;
        setassetsDefaultValue(selectedOption.default)
        setAssetsValue(selectedOption.assetsValue)
        setAssetId(id);
        setAllBlockchain(true)
        dispatch(BlockchainsThunk(id));

    };


    const blockchainsHandleChange = (selectedOption) => {
        setDropDownError(null)
        dispatch({
            type: SET_PLATFORM_DATA,
            payload: {
                result: []
            }
        });
        if (!selectedOption) {
            setBlockchainsmDefaultValue(null);
            return;
        }
        const name = selectedOption.label;
        setBlockchainId(selectedOption.value);
        setBlockchainsmDefaultValue(selectedOption.default);
        setBlockchainsValue(selectedOption.blockChainValue);
        setAllPlatform(true);

        dispatch(PlatformsThunk(assetId, name));

    };

    const platformHandleChange = (selectedOption) => {
        setDropDownError(null)
        if (!selectedOption) {
            setShowSaveBtn(false);
            setplatformDefaultValue(null);
            return;
        }
        setShowSaveBtn(true);
        setplatformDefaultValue(selectedOption.default);
        setPlatformId(selectedOption.value);
        setPlatformValue(selectedOption.platformvalue)
    }

    const createDefiShild = () => {
        dispatch(CreateDefishieldThunk(assetId, blockchainId, platformId));


    }


    const renderColorClassName = (risk) => {
        if (risk <= 100 && risk >= 80) {
            return {
                className: 'card__product__number card__product__green',
            };
        }

        else if (risk < 80 && risk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        }

        else if (risk < 60) {
            return {
                className: 'card__product__number card__product__red',
            };
        }
        else if (risk === null || 0) {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_DEFISHILD_SUCCESS_FALSE
            })

        }
    }, []);

    useEffect(() => {
        if (createDefiShildError) {
            setDropDownError(createDefiShildError)
        }

    }, [createDefiShildError]);






    const asset = Math.round(assetsValue ? assetsValue : assetsDefaultValue);
    const blockchain = Math.round(blockchainsValue ? blockchainsValue : blockchainsmDefaultValue);
    const pllatform = Math.round(platformValue ? platformValue : platformDefaultValue);


    return (

        <div className={'card  new__defi empty defi' + ' ' + (openSave ? 'card  new__defi empty defi' : 'card new new__defi empty defi')}>
            <div className="card__header">
                <div className="row">
                    <h2 className="card__title">
                        New Product
                    </h2>
                    <div className='close__card__content'
                        onClick={(() => {
                            modalClose(false)
                            dispatch({
                                type: SET_BLOCKCHATIN_DATA,
                                payload: {
                                    result: []
                                }
                            });
                            dispatch({
                                type: SET_PLATFORM_DATA,
                                payload: {
                                    result: []
                                }
                            });
                        })}

                    >
                        <span className="card__close">
                            <span></span><span></span>
                        </span>
                    </div>

                </div>
            </div>
            {dropDownError ?
                <div className="card__price card__wallet__content">
                    <div className="">
                        <div className="card__wallet__rates" style={{ marginLeft: '10px', color: '#be6161' }}>{dropDownError}</div>
                    </div>
                </div> : <div className="card__price card__wallet__content">
                    <div className="">
                        <div className="card__wallet__rates" style={{ marginLeft: '10px' }}>Staked amount</div>
                    </div>
                </div>
            }

            <div className="card__product__wrapper">
                <div style={{ display: 'flex' }} >
                    <div style={{ width: '100%' }}>
                        <DropDown
                            onChange={assetsHandleChange}
                            options={assetsResult}
                            placeholder="Choose Asset"
                            allassets={true}
                            title={title}
                        >{
                                assetsValue || assetsDefaultValue ?
                                    <div className={renderColorClassName(Number(asset))?.className}>{asset}</div> :
                                    <div className="card__product__number"> -</div>
                            }
                        </DropDown>


                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                        <DropDown
                            onChange={blockchainsHandleChange}
                            options={blockchainsResult}
                            placeholder="Choose Blockchain"
                            allblockchains={allBlockchain}
                            title={title}
                        >
                            {
                                blockchainsValue || blockchainsmDefaultValue ?
                                    <div className={renderColorClassName(Number(blockchain))?.className}>{blockchain}</div> :
                                    <div className="card__product__number"> -</div>
                            }
                        </DropDown>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                        <DropDown
                            onChange={platformHandleChange}
                            options={platformResult}
                            placeholder="Choose Platform"
                            allPlatform={allPlatform}
                            title={title}
                        >
                            {
                                platformValue || platformDefaultValue ?
                                    <div className={renderColorClassName(Number(pllatform))?.className}>{pllatform}</div> :
                                    <div className="card__product__number empty"> -</div>
                            }
                        </DropDown>
                    </div>
                </div>

            </div>

            <div className={'card__product__stake__block' + ' ' + (title === 'LightDefi' ? 'card__product__stake__block__light_defi' : 'card__product__stake__block')}>
                <button className='card__product__stake'>Stake</button>
                <button className="card__product__unstacked" >Unstake</button>
            </div>

            <div className="card__risk">
                <h3 className="card__risk__title">Create Product</h3>
                <div className="card__risk__alert active">
                    <p className="card__risk__alert__title">Alert</p>
                    <p className="card__risk__alert__number">0</p>
                </div>
                <div className="card__risk__chart">
                    <div className="card__risk__chart__min">
                        <p className="card__risk__chart__number">0</p>
                        <p className="card__risk__chart__number__of">of 100</p>
                    </div>
                </div>
                <div className="card__risk__info">
                    <i className="icon-info-icon"></i>
                </div>
                <div className="card__risk__range active">
                    <div className="card__risk__range__bg"></div>
                    <div className="card__risk__range__btn">
                        <div className="card__risk__range__btn__min"></div>
                    </div>
                </div>
            </div>
            <div className="unstacking">
                Auto Unstacking
                <span>30</span>
            </div>
            <div className={'add__wallet new' + ' ' + (openSave ? 'add__wallet save' : 'add__wallet new')}>
                <div className="add__wallet__btn__bg">
                    <div className="plus__wrapper"
                        onClick={(() => {
                            setOpenSave(true)
                        })}>
                        <span></span><span></span>
                    </div>
                    <button className={'save__text' + ' ' + (!showSaveBtn ? 'btn_disabled' : 'save__text')}
                        onClick={createDefiShild} disabled={!showSaveBtn}>Save</button>
                </div>
            </div>
            <div className="new__layer"></div>
        </div>
    )
}
