export const GET_PROFILE = 'get_profile';

const profileState={
    profileResult:{},
}

export function GetProfileReducer(state=profileState,action){
    if(action.type === GET_PROFILE){
        return {
            ...profileState,
            profileResult:action.payload.result,
        }
    }
    return state;

}