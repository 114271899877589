export const PARTNER_GUARD_INVESTMENT_RISK = 'PARTNER_GUARD_INVESTMENT_RISK';


const partnerGuardInvestmentRiskState = {
  partnerGuardInvestmentRiskResult: [],
};

export function ParnerGuardInvestmentRiskReducer(state = partnerGuardInvestmentRiskState, action) {
    if (action.type === PARTNER_GUARD_INVESTMENT_RISK) {
        return {
            ...partnerGuardInvestmentRiskState,
            partnerGuardInvestmentRiskResult: action.payload.result,
        }
    }

    return state;

}