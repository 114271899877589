import {UPDETE_LIGHT_NTF_INVESTMENT_ALERT_SUCCESS} from '../Reducer/UpdateNTFInvestmentAlertReducer';
import {ApiUpdateLightNTFAlert} from '../Api';

export function UpdateLightNTFInvestmentAlertThunk(alert,type){
    return (dispatch,getState)=>{
        ApiUpdateLightNTFAlert(alert, type)
        .then((result)=>{
            return dispatch({
                type:UPDETE_LIGHT_NTF_INVESTMENT_ALERT_SUCCESS
            })
        })
        .catch((error)=>{
        })
    
    }
}