import { useState } from 'react';
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { ApiGetPartnerGuardAppProduct } from '../../Api';

import {PartnerGuardProductsItemComponent} from '../../Components/PartnerGuardProductsItemComponent';

import {PARTNER_GUARD_PRODUCT_SUCCESS} from '../../Reducer/GetPartnerGuardProductReducer';

import {CREATE_PARTNER_GUARD_CLOSE_MODAL} from '../../Reducer/CreatePartnerGuard';

import {CreatePartnerGuardThunk} from '../../Thunks/CreatePartnerGuardThunk';

export function AddPartnerGuardModal({ modalClose }) {

    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);

    const [searchInputValue, setSearchInputValue] = useState('');


    const partnerGuardResult = useSelector((state) => {
        return state.getPartnerGuardProductReducer.partnerGuardProductResult;
    });

    const partner_guard_error = useSelector((state) => {
        return state.createPartnerGuardReducer.create_partner_guard_error;
    });

    const partner_guard_success = useSelector((state) => {
        return state.createPartnerGuardReducer.create_partner_guard_success;
    });



    useEffect(() => {
        getAllPartnerGuard('');
    }, []);


    useEffect(() => {
        if(partner_guard_success){
            getAllPartnerGuard('');
        }
     
    }, [partner_guard_success])


    const getAllPartnerGuard = (value) => {
        ApiGetPartnerGuardAppProduct(value)
            .then((result) => {
                dispatch({
                    type: PARTNER_GUARD_PRODUCT_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        if (partner_guard_error) {
            setShowError(true)
        }
        else {
            setShowError(false)
        }

    }, [partner_guard_error]);



    return (
        <>
            <div className="card empty nft">
                <div className="card__header" >
                    <div className="row light_defi_row ">
                        {
                            !showError ?
                                <h2 className="card__title">
                                    <span className="card__title__type">Add Counterparty</span>
                                </h2> :
                                <span className='error__message login_requests__message'>{partner_guard_error}</span>
                        }

                        <div className='close__card__content'>
                            <span className="card__close" 
                            onClick={(() => {
                                modalClose(false)
                                dispatch({
                                    type: CREATE_PARTNER_GUARD_CLOSE_MODAL
                                })
                            })}
                            >
                                <span></span><span></span>
                            </span>
                        </div>

                    </div>
                </div>


                <div className="card__nft__wrapper">
                    <div className='light_ntf_item_content'>
                        <div className="ntf__filter__content">
                            <input className='ntf__filter__input' placeholder='Search Counterparty' value={searchInputValue}
                                onChange={((event) => {
                                    setSearchInputValue(event.target.value)
                                    getAllPartnerGuard(event.target.value);
                                })} />
                        </div>
                        <div className='light_ntf_item'>
                            <div className='light_ntf_item_information'>
                                <span className='light_ntf_item_span all_conections_ntf partner__guard__title' 
                                onClick={(() => {
                                  
                                        dispatch(CreatePartnerGuardThunk(0));
                                })}
                                >Add All Counterparties</span>
                            </div>

                        </div>
                        {
                            partnerGuardResult ?
                                <div className='card__nft__wrapper_content'>

                                    {
                                        partnerGuardResult.map((element, index) => {
                                            return <PartnerGuardProductsItemComponent element={element} key={index + 1} />
                                        })
                                    }

                                </div>
                                : null

                        }
                    </div>
                </div>
            </div>


        </>
    )
}