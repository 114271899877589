export const OPEN_MENU = 'oprn_menu';

const menuState = {
    open: false,
}

export function MenuReducer(state = menuState, action) {
    if (action.type === OPEN_MENU) {
        return {
            ...state,
            open: action.payload.open,
        }
    }
    return state;
}