import { React, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { Loading } from '../../../../../../../Components/index';

import { EditWebHookUrlThunk } from '../../../../../../../Thunks/EditWebHookUrlThunk';

import { ApiGetSetting } from '../../../../../../../Api';
import { GET_SETTINGS_SUCCESS } from '../../../../../../../Reducer/GetSettingsReducer';


export function EditWebHookForm({ showEdit, showPassword }) {

    const dispatch = useDispatch();
    const [stateError, setStateError] = useState();

    const [showSuccessMessage,setShowSuccessMessage] = useState(false);

    const edit_web_hook_url_error = useSelector((state) => {
        return state.editWebHookUrl.edit_web_hook_url_error;
    });

    const edit_web_hook_url_loading =useSelector((state) => {
        return state.editWebHookUrl.edit_web_hook_url_loading;
    });

    const edit_web_hook_url_success = useSelector((state)=>{
        return state.editWebHookUrl.edit_web_hook_url_success;
    });

    const schema = yup.object().shape({
        webhookUrl: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.'),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            webhookUrl: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            dispatch(EditWebHookUrlThunk(values));
        }
    });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };
       useEffect(() => {
        if (edit_web_hook_url_error) {
            setStateError(edit_web_hook_url_error);
        }
    }, [edit_web_hook_url_error]);


    useEffect(()=>{
        if(edit_web_hook_url_success){
            setTimeout(()=>{
                setShowSuccessMessage(true)
            })
            setTimeout(()=>{
                setShowSuccessMessage(false);
                showPassword(false);
                showEdit(false);
            },3000)
        }
        ApiGetSetting()
        .then((result) => {
            dispatch({
                type: GET_SETTINGS_SUCCESS,
                payload: {
                    result: result.data.data || null
                }
            });
        })

        .catch((error) => {
        })

    },[edit_web_hook_url_success]);


    return <>
        <form className="password__change__block" onSubmit={formik.handleSubmit}>
            <h4 className="password__change__block__title">Update webhook URL</h4>
            <span className='error__message requests__message'>{stateError}</span>
            {
                showSuccessMessage ? 
                <span className='success_message'>URL changed successfully</span>:null
            }
        
            <label htmlFor="webhookUrl" className="password__change__label">
                <span className="password__change__label__text">Url</span>
                
                <input placeholder="Url" type="text"  className={'password__change__input' + ' ' + (formik.touched.webhookUrl && formik.errors.webhookUrl  ? 'form__content__error' : 'password__change__input')} id="webhookUrl" name="webhookUrl"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={() => deleteErrorButton('webhookUrl')}
                />
                {formik.touched.webhookUrl && formik.errors.webhookUrl ? <span className='error__message' style={{margin:'3px auto'}}>{formik.errors.webhookUrl}</span> : null}
            </label>
          

            <button className="password__change__btn">Update {edit_web_hook_url_loading ?<Loading />:null}</button>
        </form>
    </>
}