export const WALLET_SUCCESS = 'wallet_success';
export const WALLET_FETCHED  ='WALLET_FETCHED';

const walletState={
    walletResult:[],
    isWalletFetched:false,
}

export function WalletReducer(state=walletState,action){
    if(action.type === WALLET_SUCCESS){
      
        return {
            ...state,
            walletResult:action.paylod.walletResult,
            isWalletFetched:action.paylod.walletResult.length? false : true,
        }
    }

    else if(action.type ===  WALLET_FETCHED){
        return {
            ...state,
            walletResult: [],
            isWalletFetched:false,  
        }
    }

    return state;
}