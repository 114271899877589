import { TWO_FA_GENERATE_SUCCESS } from '../Reducer/TwoFAGenerateReducer';
import { ApiTwoFAGenerate } from '../Api';

export function TwoFAGenerateThunk() {
    return (dispatch, getState) => {
        ApiTwoFAGenerate().
            then((result) => {
                dispatch({
                    type: TWO_FA_GENERATE_SUCCESS,
                    payload: {
                        qrImage: result?.data?.data?.secret
                    }
                })
            })
            .catch((error) => {
            })
    }

}