export const CONNECTION_ALL_FILTER = 'CONNECTION_ALL_FILTER';


const connectionFilter = {
    connectionFilterData: [],
}

export function ConectionFilterAllReducer(state = connectionFilter, action) {

    if (action.type === CONNECTION_ALL_FILTER) {
        return {
            ...connectionFilter,
            connectionFilterData: action.payload.result
        }
    }
    return state;
}