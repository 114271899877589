import {PARTNER_GUARD_INVESTMENT_RISK_ALERT} from '../Reducer/PartnerGuardInvestmentRiskAlertReducer';
import {ApiGetPartnerGuardGeneral} from '../Api';


export function PartnerGuardInvestmentRiskAlertThunk(){
    return (dispatch,getState) =>{
        ApiGetPartnerGuardGeneral()
        .then((result)=>{
            dispatch({
                type:PARTNER_GUARD_INVESTMENT_RISK_ALERT,
                payload:{
                    result:result.data.data  
                }
            })
        })
    }
}