import { React,useState } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { DeleteCardModule } from '../../../../../Modal/DeleteCard';

import remove from '../../../../../Assets/img/icons/remove.png';



export function LightNFTTrash({element,restoreItem, deleteItem}) {

const [openDeleteModal, setOpenDeleteModal] = useState(false);


    return (
        <>
            <div className="card cold nft trash defi">
                <div className="card__header">
                    <div className="row trash__card__row" style={{justifyContent:'center'}}>
                        <h2 className="card__title trash__card__title">
                            {element?.lightNFTProduct?.lightNFTId?.name}
                            {/* <span className="card__title__type">(Bored Ape Yacht Club)</span> */}
                        </h2>
                        <div className='close__card__content'
                         onClick={(()=>{
                            setOpenDeleteModal(true)
                        })}>
                        <span className="card__close">
                            <span></span><span></span>
                        </span>
                        </div>
                      
                    </div>
                </div>



                <div className="card__nft__wrapper">
                    <img src={element?.lightNFTProduct?.lightNFTId?.image} alt="nft1" className="card__nft__img" />
                </div>

                
                <div style={{marginTop:'24px'}}>
                </div>
                <div className="card__risk">
                    <h3 className="card__risk__title">NFT Project Risk</h3>
                    <div className="card__risk__alert">
                        <p className="card__risk__alert__title">Alert</p>
                        <p className="card__risk__alert__number">60</p>
                    </div>
                    <div className="card__risk__chart">
                        <div className="card__risk__chart__min">
                            <p className="card__risk__chart__number">{Math.round(element?.lightNFTProduct?.lightNFTId?.risk)}</p>
                            <p className="card__risk__chart__number__of">of 100</p>
                        </div>
                    </div>
                    <div className="card__risk__info">
                        <i className="icon-info-icon"></i>
                    </div>
                    <div className="card__risk__range">
                        <div className="card__risk__range__bg"></div>
                        <div className="card__risk__range__btn">
                            <div className="card__risk__range__btn__min"></div>
                        </div>
                    </div>
                </div>

                <div className="trash__wallet" onClick={(() => {
                    restoreItem(element?.id)
                })}>
                    <img width="44" height="48" src={remove} alt="" className="trash__wallet__icon" />
                </div>
                <div className="trash__layer"></div>
            </div>
            <OutsideClickHandler onOutsideClick={(event) => {
                    setOpenDeleteModal(false)
                }}>
                {
                    openDeleteModal ?
                        <DeleteCardModule title="You are going to delete this product"
                            text="This product will be deleted and you will not be able to restore it."
                            element={element} deleteItem={(() => {
                                deleteItem(element.id)
                                setOpenDeleteModal(false)
                            })} cancel={(() => {
                                setOpenDeleteModal(false)
                            })} /> : ''
                }
                </OutsideClickHandler>
        </>
    )

}