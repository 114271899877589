import { LOGIN_SUCCESS, LOGIN_LOADING, LOGIN_ERROR } from '../Reducer/LoginReducer';
import { ApiLogin } from '../Api';

export function LoginThunk({ email, password }) {
    return (dispatch, getState) => {
        dispatch({
            type: LOGIN_LOADING
        });
        ApiLogin(email, password)
            .then((result) => {
                localStorage.setItem('accessToken', result.data.data.access_token)
                dispatch({
                    type: LOGIN_SUCCESS,
                    paylod: {
                        token: result.data.data.access_token,
                        verification: result?.data?.data?.set_verification,
                    }
                })
            })
            .catch((error) => {
                dispatch({
                    type: LOGIN_ERROR,
                    paylod: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}
