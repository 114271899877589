export const GET_WALLET_BY_ID = 'GET_WALLET_BY_ID';

const wallerinitialState = {
    wallerResult: [],
}

export function WalletGetByIdReducer(state = wallerinitialState, action) {
    if (action.type === GET_WALLET_BY_ID) {
        return {
            ...state,
            wallerResult: action.payload.result,
        }
    }
    return state;
}
