import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AccessPasswordThunk } from '../../../../../../../Thunks/index';

import { Loading } from '../../../../../../../Components/index';
import { changeAccessPasswordSuccess } from '../../../../../../../Reducer/AccessPasswordReducer';

import view from '../../../../../../../Assets/img/icons/eye.svg';

export function AccessPassword({ sendShowPassword }) {

    const dispatch = useDispatch();
    const [stateError, setStateError] = useState();
    const [passwordShown, setPasswordShown] = useState(false);

    const schema = yup.object().shape({
        access_password: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.').min(8, 'The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol.').matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."),
    })

    const formik = useFormik({
        initialValues: {
            access_password: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            dispatch(AccessPasswordThunk({password:values.access_password}));
        }
    });

    const access_password_error = useSelector((state) => {
        return state.accessPassword.access_password_error;
    });

    const access_password_success = useSelector((state) => {
        return state.accessPassword.access_password_success;
    });

    const access_password_loading = useSelector((state) => {
        return state.accessPassword.access_password_loading;
    });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };
    useEffect(() => {
        if (access_password_error) {
            setStateError(access_password_error);
        }
    }, [access_password_error]);

    useEffect(() => {
        if (access_password_success) {
            sendShowPassword(true);
            dispatch(changeAccessPasswordSuccess());
        }

    }, [access_password_success]);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

    return (
        <>
            <form className="password__change__block" onSubmit={formik.handleSubmit}>
                <h4 className="password__change__block__title">Enter Password</h4>
                <span className='error__message requests__message'>{stateError}</span>
                <>
                <div>
                
                <div className='notificaten__password__content'>
                <input placeholder="Password"  className={'password__change__input' + ' ' + (formik.touched.access_password && formik.errors.access_password  ? 'form__content__error' : 'password__change__input')} name="access_password" id="access_password"
                    value={formik.values.access_password}
                    onChange={formik.handleChange}
                    onFocus={() => deleteErrorButton('access_password')}
                    type={passwordShown ? "text" : "password"}
                    autoComplete="new-password"
                />
                <img className='notificaten__password__icon' src={view}  onClick={togglePassword}/>
            
                </div>
                </div>
                {formik.touched.access_password && formik.errors.access_password ? <span className='error__message accept_password__message'>{formik.errors.access_password}</span> : null}
                </>
             
               
              
                <button className="password__change__btn">Accept {access_password_loading ? <Loading /> : null}</button>
            </form>
        </>
    )
}