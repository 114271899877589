import { React } from 'react';


export function DeleteCardModule({ title, text, element, cancel, deleteItem }) {
    return (
        <div>
            <div className="card__product__stake__amount open delete_module">
                <h2 className="title title__name">{title}</h2>
                <h3 className="title">{text}</h3>
                <div className='btn_content'>
                    <button className="card__product__stake__amount__confirm" onClick={(() => {
                        deleteItem(element?.id)
                    })}>Delete</button>
                    <button className="card__product__stake__amount__confirm" onClick={(() => {
                        cancel(false)
                    })}>Cancel</button>
                </div>

            </div>
        </div>

    )
}