import {
  VERIFICATION_LOADING,
  VERIFICATION_ERROR,
  VERIFICATIONT_IS_AUTH,
  VERIFICATION_SUCCESS,
} from "../Reducer/TwoFACodeVerificationReducer";
import { ApiTwoFACodeVerification } from "../Api";

export function TwoFACodeVerificationThunk({ code }) {
  return (dispatch, getState) => {
    dispatch({
      type: VERIFICATION_LOADING,
    });
    ApiTwoFACodeVerification(code)
      .then((result) => {
        // dispatch({
        //     type:VERIFICATIONT_IS_AUTH,
        //     paylod:{
        //         token:result.data.access_token
        //     }
        // });
        localStorage.setItem("accessToken", result?.data?.data?.access_token);
        localStorage.setItem("refreshToken", result?.data?.data?.refresh_token);

        dispatch({
          type: VERIFICATION_SUCCESS,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
          return;
        }
        dispatch({
          type: VERIFICATION_ERROR,
          paylod: {
            error: error?.response?.data?.error?.message,
          },
        });
      });
  };
}
