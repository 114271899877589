import { React } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Auth, Login, QRVerify, ForgotPassword, PasswordReset } from "../Pages";

export function AuthRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />}>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/qr-verify" element={<QRVerify />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/psw-reset" element={<PasswordReset />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}
