import {GET_PROFILE} from '../Reducer/GetProfileReducer';
import {ApiGetProfile} from '../Api';

export function ProfileThunk(){
    return(dispatch,getState)=>{
        ApiGetProfile().
        then((result)=>{
            dispatch({
                type:GET_PROFILE,
                payload:{
                    result:result.data.data,
                }
            })
           
        })
        .catch((error)=>{
        })
    }
}