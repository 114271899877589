import { PASSWORD_RESET_LOADING, PASSWORD_RESET_ERROR, PASSWORD_RESET_SUCCESS } from '../Reducer/PasswordResetReducer';

import { ApiResetPassword } from '../Api';


export function ResetPasswordThunk({code,password}) {
    return (dispatch, getState) => {
        dispatch({
            type: PASSWORD_RESET_LOADING
        });
        ApiResetPassword(code,password)
            .then((result) => {
                dispatch({
                    type: PASSWORD_RESET_SUCCESS
                });
            })
            .catch((error) => {
                dispatch({
                    type: PASSWORD_RESET_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                });
            })
    }

}