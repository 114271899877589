export const TRASH_SUCCESS = 'tras_success';
export const TRUSH_FETCHED = 'TRUSH_FETCHED';

const trashState = {

    trashResult: [],
    isTrushFetched:false,

};

export function TrashReducer(state = trashState, action) {
    if (action.type === TRASH_SUCCESS) {
        return {
            ...state,
            trashResult: action.payload.result,
            isTrushFetched: action.payload.result.length ? false : true,
        }
    }
  else  if(action.type === TRUSH_FETCHED){
        return {
            ...state,
            trashResult: [],
            isTrushFetched:false,  
        }
    }

    return state;
}