import { React, useState, useEffect, useCallback, useRef } from "react";
import { MainRoutes } from "./MainRoutes";
import { AuthRoutes } from "./AuthRoutes";
import { useSelector } from "react-redux";
import moment from "moment";

export function IndexedRoute() {
  const verification_is_auth = useSelector((state) => {
    return state.codeVerification.verification_is_auth;
  });
  const [events] = useState(["click", "load", "scroll", "change", "keydown"]);
  const logOutTime = 30; // minute

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 1000 * 60 * logOutTime);
  };

  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = logOutTime;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes() || 0;

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("verificationIsAuth");
        localStorage.removeItem("acceptToken");
        localStorage.removeItem("zoom");
        window.location.href = "/login";
      }
    }, 1000);
  };

  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (verification_is_auth) {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
  }, [verification_is_auth]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  return <>{verification_is_auth ? <MainRoutes /> : <AuthRoutes />}</>;
}

export default IndexedRoute;
