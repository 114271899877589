export const GET_LIGHT_NTF_SUCCESS = 'GET_LIGHT_NTF_SUCCESS';

const getLightNtfState = {
    lightNtfResult: [],
};

export function GetLightNTFReducer(state = getLightNtfState, action) {

    if (action.type === GET_LIGHT_NTF_SUCCESS) {
        return {
            ...getLightNtfState,
            lightNtfResult: action.payload.result,
        }
    }

    return state;
}