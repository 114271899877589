export const CREATE_LIGHT_NTF_SUCCESS = 'CREATE_LIGHT_NTF_SUCCESS';
export const CREATE_LIGHT_NTF_ERROR = 'CREATE_LIGHT_NTF_ERROR';
export const CREATE_LIGHT_NTF_CLOSE_MODAL ='CREATE_LIGHT_NTF_CLOSE_MODAL';

const createLightNTFState={
    create_light_ntf_success:false,
    create_light_ntf_error:null,
}

export function CreateLightNTFReducer(state = createLightNTFState,action){
    if(action.type === CREATE_LIGHT_NTF_SUCCESS){
        return {
            ...createLightNTFState,
            create_light_ntf_success:true,
            create_light_ntf_error:null,
        }
    }
    else if(action.type === CREATE_LIGHT_NTF_ERROR){
        return {
            ...createLightNTFState,
            create_light_ntf_success:false,
            create_light_ntf_error:action.payload.error,
        }   
    }
    else if (action.type === CREATE_LIGHT_NTF_CLOSE_MODAL){
        return {
            ...createLightNTFState,
            create_light_ntf_success:false,
            create_light_ntf_error:null,
        }  
    }
    return state;
}