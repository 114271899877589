import { React, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { useFormik } from 'formik';
import * as yup from 'yup';

import arrow from '../../Assets/img/icons/arrow.svg';

import { AddWalletCard } from '../../Components/index';

import { CreateWalletShilThunk } from '../../Thunks/CreateWalletShildThunk';

// import { EditWalletShilThunk } from '../../Thunks/EditWalletShilThunk';
import { EditWalletShilThunk } from '../../Thunks/EditWalletShildThunk';

import { CREATE_WALLET_SHILD_SUCCESS_CLOSE_MODAL } from '../../Reducer/CreateWalletShildReducer';

import { EDIT_WALLET_SHILD_SUCCESS_CLOSE_MODAL } from '../../Reducer/EditWalletShildReducer';


export function AddWallet({ closeModal, element }) {
    const dispatch = useDispatch();
    const divRef = useRef(null);

    const [addWalletCard, setAddWalletCard] = useState([{ id: 1 }]);

    const [walletItem, setWalletItem] = useState([
        { id: 1, value: 'Cold Wallet', wallet_service_type: 'Cold' },
        { id: 2, value: 'Hot Wallet', wallet_service_type: 'Hot' },
    ]
    );

    const [value, setValue] = useState('Cold Wallet');
    const [walletServiceType, salletServiceType] = useState('Cold');
    const [openWallet, setOpenWallet] = useState(false);

    const [addressCurentValue, setAddressCurentValue] = useState([]);

    const [assetsValue, setAssetsValue] = useState('ETHEREUM');


    const [whiteListItem, setWhiteListItem] = useState([]);

    const [showAddessError, setShowAddessError] = useState();


    const create_wallet_shild_success = useSelector((state) => {
        return state.createWalletShildReducer.crete_wallet_shild_success;
    });

    const create_wallet_shild_error = useSelector((state) => {
        return state.createWalletShildReducer.crete_wallet_shild_error;
    });

    const edit_wallet_shild_success = useSelector((state) => {
        return state.editWalletShildReducer.edit_wallet_shild_success;
    });

    const edit_wallet_error = useSelector((state) => {
        return state.editWalletShildReducer.edit_wallet_shild_error;
    });


    const schema = yup.object().shape({
        trezor: yup.string().matches(/^[^-\s]/, 'This field cannot contain spaces.'),
        balance_limit: yup.string(),
        whitelisted_single_tx_limit: yup.string(),
        whitelisted_window: yup.string(),
        whitelisted_limit: yup.string(),
        whitelisted_single_tx_limit: yup.string(),
        not_whitelisted_window: yup.string(),
        not_whitelisted_limit: yup.string(),
        not_whitelisted_single_tx_limit: yup.string(),
        anomalies_detection: yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            trezor: element?.name || '',
            balance_limit: element?.params?.balance_limit || '',
            //  balance_limit: new Intl.NumberFormat('en-US').format(element?.params?.balance_limit?.toString().replaceAll(',', '')) || '',
            whitelisted_single_tx_limit: element?.params?.whitelisted_single_tx_limit || '',
            whitelisted_window: element?.params?.whitelisted_window || '',
            whitelisted_limit: element?.params.whitelisted_limit || '',
            not_whitelisted_window: element?.params?.not_whitelisted_window || '',
            not_whitelisted_limit: element?.params?.not_whitelisted_limit || '',
            not_whitelisted_single_tx_limit: element?.params?.not_whitelisted_single_tx_limit || '',
            anomalies_detection: false,
        },
        validationSchema: schema,
        onSubmit: values => {
            if (element && element.id) {
                dispatch(EditWalletShilThunk(element?.id, {
                    wallet_type: "USER",
                    wallet_service_type: walletServiceType,
                    name: values.trezor,
                    network: assetsValue.toUpperCase(),
                    anomalies_detection: values.anomalies_detection,
                    addresses: addressCurentValue?.map(item => item.value),
                    params: {
                        whitelist: whiteListItem?.map(item => item.value).filter(it => it),
                        // balance_limit: values.balance_limit.replaceAll(',', ''),
                        balance_limit: values.balance_limit,
                        not_whitelisted_single_tx_limit: values.not_whitelisted_single_tx_limit,
                        not_whitelisted_window: values.not_whitelisted_window,
                        not_whitelisted_limit: values.not_whitelisted_limit,
                        whitelisted_limit: values.whitelisted_limit,
                        whitelisted_single_tx_limit: values.whitelisted_single_tx_limit,
                        whitelisted_window: values.whitelisted_window
                    },
                }))
                // }
                // else {
                //     setShowAddessError('Wallet Address is a required field.');
                // }
            }
            else {
                // if ((addressCurentValue?.filter(item => item.value).length)) {
                dispatch(CreateWalletShilThunk({
                    wallet_type: "USER",
                    wallet_service_type: walletServiceType,
                    name: values.trezor,
                    network: assetsValue.toUpperCase(),
                    anomalies_detection: values.anomalies_detection,
                    addresses: addressCurentValue?.map(item => item.value),
                    params: {
                        whitelist: whiteListItem?.map(item => item.value).filter(it => it),
                        balance_limit: values.balance_limit,
                        not_whitelisted_single_tx_limit: values.not_whitelisted_single_tx_limit,
                        not_whitelisted_window: values.not_whitelisted_window,
                        not_whitelisted_limit: values.not_whitelisted_limit,
                        whitelisted_limit: values.whitelisted_limit,
                        whitelisted_single_tx_limit: values.whitelisted_single_tx_limit,
                        whitelisted_window: values.whitelisted_window

                    },
                }))
                // }
                // else {
                //     setShowAddessError('Wallet Address is a required field.');
                // }
            }

        }
    });



    useEffect(() => {
        if (create_wallet_shild_success) {
            closeModal(false);
        }
    }, [create_wallet_shild_success]);


    useEffect(() => {
        if (edit_wallet_shild_success) {
            closeModal(false);
        }
    }, [edit_wallet_shild_success]);



    useEffect(() => {
        if (create_wallet_shild_success) {
            closeModal(false);
            dispatch({
                type: CREATE_WALLET_SHILD_SUCCESS_CLOSE_MODAL
            })

        }
    }, [create_wallet_shild_success]);


    useEffect(() => {
        if (edit_wallet_shild_success) {
            closeModal(false);
            dispatch({
                type: EDIT_WALLET_SHILD_SUCCESS_CLOSE_MODAL
            })

        }
    }, [edit_wallet_shild_success]);


    useEffect(() => {
        if (element && element?.id) {
            salletServiceType(element?.wallet_service_type)
            setAssetsValue(element?.network)
            walletItem?.filter((item) => {
                if (item.wallet_service_type === element?.wallet_service_type) {
                    setValue(item.value)
                }
            });
        }
    }, [element]);


    function getLimitValues(limit) {
        formik.setFieldValue('whitelisted_window', limit);
    }

    return (
        <div className='modal__wrapper__container'>
            <div className="modal__wrapper">
                <div className="modal">
                    <span className='error__message login_requests__message'>{showAddessError}</span>
                    <span className='error__message login_requests__message'>{create_wallet_shild_error}</span>
                    <span className='error__message login_requests__message'>{edit_wallet_error}</span>
                    <div className="modal__header wallet__modal">

                        <OutsideClickHandler onOutsideClick={(event) => {
                            //event.stopPropagation()
                            setOpenWallet(false)
                            dispatch({
                                type: CREATE_WALLET_SHILD_SUCCESS_CLOSE_MODAL
                            })
                            dispatch({
                                type: EDIT_WALLET_SHILD_SUCCESS_CLOSE_MODAL
                            })
                        }}>
                            <div className="wallet__type wallet__first__type" onClick={(() => {
                                setOpenWallet(!openWallet)
                            })}>
                                <img src={arrow} alt="" className="wallet__type__arrow" />
                                <div className="wallet__type__item selected">{value}</div>
                                {
                                    openWallet ? <>
                                        {
                                            walletItem.filter((e) => e.value !== value).map((element) => {
                                                return <div className="wallet__type__item" key={element.id} onClick={(() => {
                                                    setValue(element.value)
                                                    salletServiceType(element.wallet_service_type)
                                                })}>{element.value}</div>
                                            })
                                        }
                                    </> : ''
                                }
                            </div>
                        </OutsideClickHandler>
                        <form onSubmit={formik.handleSubmit}
                            style={{ width: '100%', marginLeft: '170px' }}>
                            <div className='wallet__form__header'>
                                <div>
                                    <input className="wallet__type__btn" placeholder='Custody' id="trezor" name="trezor" value={formik.values.trezor}
                                        onChange={formik.handleChange} />
                                    {formik.touched.trezor && formik.errors.trezor ? <span className='error__message'>{formik.errors.trezor}</span> : null}
                                </div>

                                <button className="wallet__type__save">SAVE</button>

                            </div>
                        </form>
                    </div>
                    <>
                        {
                            addWalletCard.map((item) => {
                                return <AddWalletCard key={item.id}
                                    element={item}
                                    walletData={element}
                                    formik={formik}
                                    schema={schema}
                                    addressCurentValue={addressCurentValue}
                                    setAddressCurentValue={setAddressCurentValue}
                                    getLimitValues={getLimitValues}

                                    sendWhiteList={((event) => {
                                        setWhiteListItem(event);
                                    })}

                                    networkValue={((event) => {
                                        setAssetsValue(event)
                                    })}
                                />
                            })

                        }
                    </>
                    <div>
                        <div style={{ width: '100%', marginTop: '70px' }} className="divref" ref={divRef}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}