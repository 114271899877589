export const LIGHT_DEFI_INVESTMENT_RISK = 'light_defi_investment_risk';


const lightDefiInvestmentRiskState = {
    lightDefiInvestmentRiskResult: [],
};

export function LightDefiInvestmentRiskReducer(state = lightDefiInvestmentRiskState, action) {
    if (action.type === LIGHT_DEFI_INVESTMENT_RISK) {
        return {
            ...lightDefiInvestmentRiskState,
            lightDefiInvestmentRiskResult: action.payload.result,
        }
    }

    return state;

}