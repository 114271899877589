export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_LOADING = 'login_loading';
export const LOGIN_ERROR = 'login_error';
export const RESET_LOGIN = 'login_reset';
const loginState = {
    error: null,
    loading: false,
    success: false,
    token: localStorage.getItem('accessToken'),
    set_verification:0,
}

export function LoginReducer(state = loginState, action) {
    if (action.type === LOGIN_LOADING) {
        return {
            ...state,
            loading: true,
        }
    }
    else if (action.type === LOGIN_ERROR) {
        return {
            ...state,
            loading: false,
            error: action.paylod.error,
        }
    }
    else if (action.type === LOGIN_SUCCESS) {
        return {
            ...state,
            loading: false,
            error: null,
            success: true,
            token:action.paylod.token,
            set_verification:action.paylod.verification,
        }
    }
    else if(action.type === RESET_LOGIN){
        return {
            ...state,
            error: null,
            loading: false,
            success: false,
            token: localStorage.getItem('accessToken'),
            set_verification:0,
        }
    }
    return state;
}
