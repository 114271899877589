import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import { Line } from 'rc-progress';

import { ApiUpdateDefishieldInvestmentRisks, ApiUpdateLightDefiAlert,ApiUpdateLightNTFAlert,ApiUpdatePartnerGuardAlert,ApiUpdateWalletAlert } from '../../Api';
import { useDispatch } from 'react-redux';
import { DefiShildInvestmentRiskAlertThunk } from '../../Thunks/DefiShildInvestmentRiskAlertThunk';
import { UpdateLightDefiInvestmentAlertThunk } from '../../Thunks/UpdateLightDefiInvestmentAlertThunk';
import { LightDefiInvestmentRiskAlertThunk } from '../../Thunks/LightDefiInvestmentRiskAlertThunk';
import { LighNTFInvestmentRiskAlertThunk } from '../../Thunks/LightNTFInvestmentRiskAlertThunk';
import {PartnerGuardInvestmentRiskAlertThunk} from '../../Thunks/PartnerGuardInvestmentAlertThunk';
import {WalletInvestmentRiskAlertThunk} from '../../Thunks/WalletInvestmentRiskAlertThunk';


export function CurrentDefiCard({ element, title }) {
    const dispach = useDispatch();

    const [openProgress, setOpenProgress] = useState(false);
    const [alertvalue, setValue] = useState(0);

    const investmentRiskAlertResult = useSelector((state) => {
        return state.defiShildInvestmentRiskAlert.investment_risk_alert_result;
    });

    const lightDefiInvestmentRiskAlertResult = useSelector((state) => {
        return state.lightDefiInvestmentRiskAlert.lightDefiInvestmentRiskAlertResult;
    });

    const lightNTFinvestmentRiskAlertResult = useSelector((state) => {
        return state.lightNTFInvestmentRiskAlertReducer.lightntfInvestmentRiskAlertResult;
    });

    const partnerGuardinvestmentRiskAlertResult = useSelector((state) => {
        return state.partnerGuardInvestmentRiskAlertReducer.partnerGuardInvestmentRiskAlertResult;
       });

       const walletinvestmentRiskAlertResult = useSelector((state) => {
        return state.WalletInvestmentRiskAlertReducer.walletInvestmentRiskAlertResult;
    });



    const handleChange = (value,e) => {
       // e.preventDefault()
        setValue(value)
    }

    const renderClassName = (color) => {
        switch (color) {
            case 'green':
                return {
                    stroke: '#4DC7B5',
                };
            case 'yellow':
                return {
                    stroke: '#EFB661',
                };
            case 'red':
                return {
                    stroke: '#F27281',
                };

            default:
                return {
                    stroke: '#F27281'
                }
        }

    }
    const alertOnChangeComplete = () => {
        if (title === 'defiShild') {
            ApiUpdateDefishieldInvestmentRisks(+alertvalue, 2)
                .then((result) => {
                    dispach(DefiShildInvestmentRiskAlertThunk());
                })
                .catch((error) => {
                })
        }
        else if (title === 'lightDefi') {
            dispach(UpdateLightDefiInvestmentAlertThunk(+alertvalue, 2));
            ApiUpdateLightDefiAlert(+alertvalue, 2)
                .then((result) => {
                    dispach(LightDefiInvestmentRiskAlertThunk());
                })
                .catch((error) => {
                })


        }

        else if (title === 'lightNTF') {
            ApiUpdateLightNTFAlert(+alertvalue, 2)
                .then((result) => {
                    dispach(LighNTFInvestmentRiskAlertThunk());
                })
                .catch((error) => {
                })

        }


        else if (title === 'partnerGuard'){
            ApiUpdatePartnerGuardAlert(+alertvalue, 2)
            .then((result) => {
                dispach(PartnerGuardInvestmentRiskAlertThunk());
            })
            .catch((error) => {
            })

        }

        else if (title === 'wallet'){
            ApiUpdateWalletAlert(+alertvalue, 2)
            .then((result) => {
             dispach(WalletInvestmentRiskAlertThunk());
            })
            .catch((error) => {
            })

        }
        
        
    }

    useEffect(() => {
        if (investmentRiskAlertResult.length && title === 'defiShild') {
            investmentRiskAlertResult.map((element) => {
                if (element && element.type && element.type === 2) {
                    setValue(+element.alert)
                }
            });

        };
    }, [investmentRiskAlertResult, title]);


    useEffect(() => {
        if (lightDefiInvestmentRiskAlertResult.length && title === 'lightDefi') {
            lightDefiInvestmentRiskAlertResult.map((element) => {
                if (element && element.type && element.type === 2) {
                    setValue(+element.alert)
                }
            });

        };
    }, [lightDefiInvestmentRiskAlertResult, title]);


    useEffect(() => {
        if (lightNTFinvestmentRiskAlertResult.length && title === 'lightNTF') {
            lightNTFinvestmentRiskAlertResult.map((element) => {
                if (element && element.type && element.type === 2) {
                    setValue(+element.alert)
                }
            });

        };
    }, [lightNTFinvestmentRiskAlertResult, title]);

    useEffect(() => {
        if (walletinvestmentRiskAlertResult.length && title === 'wallet') {
            walletinvestmentRiskAlertResult.map((element) => {
                if (element && element.type && element.type === 2) {
                    setValue(+element.alert)
                }
            });

        };
    }, [walletinvestmentRiskAlertResult, title]);




    useEffect(() => {
        if (partnerGuardinvestmentRiskAlertResult.length && title === 'partnerGuard') {
            partnerGuardinvestmentRiskAlertResult.map((element) => {
                if (element && element.type && element.type === 2) {
                    setValue(+element.alert)
                }
            });

        };
    }, [partnerGuardinvestmentRiskAlertResult, title]);



    return (
        <>
            <div className="aside__card cold">
                <div className="row">
                    <div className="aside__card__left">
                        <h3 className="aside__card__title">{title==='lightNTF' ? 'NFT Portfolio Value ' : title==='partnerGuard' ? 'Counterparties Risk' :'Portfolio  Risk'}</h3>
                        {
                            element?.currentDayInvestmentRisk ?
                                <p className="aside__card__numbers">{Math.round(element?.currentDayInvestmentRisk)}/<span>100</span></p> :
                                <p className="aside__card__numbers">{'--'}/<span>100</span></p>
                        }

                    </div>
                    <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')} onClick={(() => {
                          if (openProgress) {
                            alertOnChangeComplete()
                        }
                        setOpenProgress(!openProgress)
                    })}>
                        <p className="card__risk__alert__title">Alert</p>
                        <p className={'card__risk__alert__number' + ' ' + (alertvalue === 0 ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{alertvalue}</p>
                    </div>
                    {
                        openProgress ? 
                        <div className={'card__risk__range__hr active current__card investment_card' + ' ' + (alertvalue === 0 ? 'horizontal_slid_nit_value investment_card' : 'card__risk__range__hr active current__card investment_card')}>
                            <Slider
                                min={0}
                                max={100}
                                value={alertvalue}
                                orientation='horizontal'
                                onChange={handleChange}
                                tooltip={false}
                                onChangeComplete={alertOnChangeComplete}
                            />
                        </div> : ''
                    }
                </div>
                <Line percent={element?.currentDayInvestmentRisk} strokeWidth={4} strokeColor={renderClassName(element?.currentDayInvestmentRiskTrustScore)?.stroke} className='aside__card__progress' />
            </div>
        </>
    )
}
