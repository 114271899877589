export const UPDETE_WALLET_INVESTMENT_ALERT_SUCCESS = 'UPDETE_WALLET_INVESTMENT_ALERT_SUCCESS';


const updateInvestmentAlertState = {
    updateWalletInvestmentAlertSuccess: false,
}

export function UpdateWalletInvestmentAlertReducer(state = updateInvestmentAlertState, action) {

    if (action.type === UPDETE_WALLET_INVESTMENT_ALERT_SUCCESS) {
        return {
            ...updateInvestmentAlertState,
            updateWalletInvestmentAlertSuccess: true,
        }

    }

    return state;

}