import axios from "axios";
export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "Application/json",
  },
});
export const apiVerify = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "Application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("accessToken");
  if (
    token &&
    (!config.headers.non_auth || config.headers.non_auth === "false")
  ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  delete config.headers.non_auth;
  return config;
});

// api.interceptors.response.use(
//   async (response) => {
//     return response;
//   },
//   (error) => {
//     const { response } = error;
//     const { status } = response;
//     if (status >= 500) {
//       // alert('Unknown error, Something went wrong!')
//     }
//     if (status === 401 && error.config.url === "auth/refresh-token") {
//       localStorage.removeItem("accessToken");
//       localStorage.removeItem("verificationIsAuth");
//       window.location.href = "/login";
//     }
//     throw error;
//   }
// );

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// const refreshToken = async () => {
//   const storedRefreshToken = localStorage.getItem("refreshToken");
//   if (!storedRefreshToken) {
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("verificationIsAuth");
//     window.location.href = "/login";
//     return;
//   }

//   const { data } = await api.post("auth/refresh-token", {
//     refresh_token: storedRefreshToken,
//   });

//   const { data: response } = data;
//   localStorage.setItem("refreshToken", response.refresh_token);
//   localStorage.setItem("accessToken", response.access_token);
//   return {
//     accessToken: response.access_token,
//   };
// };

const refreshToken = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  if (!storedRefreshToken) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("verificationIsAuth");
    window.location.href = "/login";
    return;
  }

  try {
    const { data } = await api.post("auth/refresh-token", {
      refresh_token: storedRefreshToken,
    });

    const { data: response } = data;
    if (!response) {
      throw new Error("Invalid response from refresh token endpoint");
    }

    localStorage.setItem("refreshToken", response.refresh_token);
    localStorage.setItem("accessToken", response.access_token);
    return {
      accessToken: response.access_token,
    };
  } catch (error) {
    console.error("Refresh token error:", error);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("verificationIsAuth");
    window.location.href = "/login";
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err?.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({
            resolve,
            reject,
          });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      return refreshToken()
        .then((data) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.accessToken;
          originalRequest.headers["Authorization"] =
            "Bearer " + data.accessToken;
          processQueue(null, data.accessToken);
          isRefreshing = false;
          return axios(originalRequest);
        })
        .catch((err) => {
          processQueue(err, null);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("verificationIsAuth");
          window.location.href = "/login";
          isRefreshing = false;
        });
    }

    return Promise.reject(err);
  }
);
