import {GET_PARTNER_GUARD_SUCCESS} from '../Reducer/GetPartnerGuardReducer';
import {ApiGetPartnerGuard} from '../Api';

export function GetPartnerGuardThunk(orderRisk){
    return (dispatch,getState) =>{
        ApiGetPartnerGuard(orderRisk)
        .then((result)=>{
            dispatch({
                type:GET_PARTNER_GUARD_SUCCESS,
                payload:{
                    result:result.data.data
                }
            })
        })
    }
}