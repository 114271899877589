export const PASSWORD_RESET_LOADING ='password_reset_loading';
export const PASSWORD_RESET_ERROR ='password_reset_error';
export const PASSWORD_RESET_SUCCESS ='password_reset_success';

const passwordResetState ={
    password_reset_loading:false,
    password_reset_error:null,
    password_reset_success:false,
};

export function PasswordResetReducer(state=passwordResetState,action){
    if(action.type === PASSWORD_RESET_LOADING){
        return {
            ...passwordResetState,
            password_reset_loading:true,
        }
    }

    else if(action.type === PASSWORD_RESET_ERROR){
        return {
            ...passwordResetState,
            password_reset_loading:false,
            password_reset_error:action.payload.error ,
        }
    }

    else if(action.type ===PASSWORD_RESET_SUCCESS ){
        return {
            ...passwordResetState,
            password_reset_loading:false,
            password_reset_error:null,
            password_reset_success:true,
        }
    }
    return state;
}
