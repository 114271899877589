import  {CREATE_WALLET_SHILD_SUCCESS,CREATE_WALLET_SHILD_LOADING,CREATE_WALLET_SHILD_ERROR} from '../Reducer/CreateWalletShildReducer';
import {ApiCreateWallet} from '../Api';

export function CreateWalletShilThunk(network,wallet_type,wallet_service_type,name,addresses,params){
    return (dispatch,getState) =>{
        dispatch({
            type:CREATE_WALLET_SHILD_LOADING
        });
        ApiCreateWallet(network,wallet_type,wallet_service_type,name,addresses,params)
        .then((result)=>{
            dispatch({
                type:CREATE_WALLET_SHILD_SUCCESS
            });
        })
        .catch((error)=>{
            dispatch({
                type:CREATE_WALLET_SHILD_ERROR,
                payload:{
                    error:error?.response?.data?.error?.message,
                }
            });
        })
    }
}
