export const EDIT_WEBHOOK_URL_SUCCESS = 'edit_web_hook_url_success';
export const EDIT_WEBHOOK_URL_ERROR = 'edit_web_hook_url_error';
export const EDIT_WEBHOOK_URL_LOADING = 'edit_web_hook_url_loading';
export const EDIT_WEBHOOK_URL_CHANGE ='EDIT_WEBHOOK_URL_CHANGE';

export const editWebHookState = {
    edit_web_hook_url_error: null,
    edit_web_hook_url_loading: false,
    edit_web_hook_url_success: false,
}


export const changeUrlSuccessState = () => {
    return {
        type: EDIT_WEBHOOK_URL_CHANGE
    }
}

export function EditWebHookUrlReducer(state = editWebHookState, action) {
    if (action.type === EDIT_WEBHOOK_URL_LOADING) {
        return {
            ...editWebHookState,
            edit_web_hook_url_loading: true,
        }
    }
    else if (action.type === EDIT_WEBHOOK_URL_ERROR) {
        return {
            ...editWebHookState,
            edit_web_hook_url_loading: false,
            edit_web_hook_url_error: action.payload.error,
            edit_web_hook_url_success: false,
        }
    }
    else if (action.type === EDIT_WEBHOOK_URL_SUCCESS) {
        return {
            ...editWebHookState,
            edit_web_hook_url_loading: false,
            edit_web_hook_url_error: null,
            edit_web_hook_url_success: true
        }
    }
    else if (action.type === EDIT_WEBHOOK_URL_CHANGE) {
        return {
            ...editWebHookState,
            edit_web_hook_url_success: false
        }
    }
    return state;
}