export const WALLET_INVESTMENT_RISK_ALERT = 'WALLET_INVESTMENT_RISK_ALERT';

const walletInvestmentRiskAlertState={
    walletInvestmentRiskAlertResult:[], 
}

export function WalletInvestmentRiskAlertReducer(state=walletInvestmentRiskAlertState,action){
    if(action.type === WALLET_INVESTMENT_RISK_ALERT){
        return {
            ...walletInvestmentRiskAlertState,
            walletInvestmentRiskAlertResult:action.payload.result,
        }

    }
    return state;
}