import {CREATE_LIGHTDEFI_ERROR,CREATE_LIGHTDEFI_LOADING,CREATE_LIGHTDEFI_SUCCESS} from '../Reducer/CreateLightDefiReducer';

import { ApiCreateLightDefi } from '../Api';


export function CreateLightDefiThunk(assetId, blockchainId, platformId) {
    return (dispatch, getState) => {
        dispatch({
            type: CREATE_LIGHTDEFI_LOADING
        })
        ApiCreateLightDefi(assetId, blockchainId, platformId)
            .then((result) => {
                dispatch({
                    type: CREATE_LIGHTDEFI_SUCCESS,
                })
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_LIGHTDEFI_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}