import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { Sider } from "../../Components";

export function Main() {
  const zoom = useSelector((state) => {
    return state.zoomReducer.zoom;
  });

  return (
    <div className={"wrapper" + " " + (!zoom ? "zoom__page" : "wrapper")}>
      <Sider />
      <Outlet />
    </div>
  );
}
