export const ACCESSPASSWORD_SUCCESS = 'accesspassword_success';
export const ACCESSPASSWORD_LOADING ='accesspassword_loading';
export const ACCESSPASSWORD_ERROR ='accesspassword_error';
export const ACCESSPASSWORD_FALSY ='ACCESSPASSWORD_FALSY';

const  accessPasswordState ={
    access_password_success:false,
    access_password_loading:false,
    access_password_error:null,
    accept_token:localStorage.getItem('acceptToken')
};

export const changeAccessPasswordSuccess = () => {
    return {
        type: ACCESSPASSWORD_FALSY
    }
};



export function AccessPasswordReducer(state=accessPasswordState,action){
    if(action.type === ACCESSPASSWORD_LOADING){
       return {
        ...accessPasswordState,
        access_password_loading:true,
        }
    }
    else if(action.type === ACCESSPASSWORD_ERROR){
        return {
            ...accessPasswordState,
            access_password_loading:false,
            access_password_error:action.payload.error,
        }
    }
    else if (action.type === ACCESSPASSWORD_SUCCESS){
        return {
            ...accessPasswordState,
            access_password_loading:false,
            access_password_error:null,
            access_password_success:true,
          accept_token:action.payload.accept_token
        }   
    }
    else if (action.type === ACCESSPASSWORD_FALSY) {
        return {
            ...accessPasswordState,
            access_password_success: false,
        }
    }

return state;
}