export const LIGHT_NTF_INVESTMENT_RISK_ALERT = 'light_ntf_investment_risk_alert';

const  lightNTFInvestmentRiskAlertState={
    lightntfInvestmentRiskAlertResult:[], 
}

export function LightNTFInvestmentRiskAlertReducer(state=lightNTFInvestmentRiskAlertState,action){
    if(action.type === LIGHT_NTF_INVESTMENT_RISK_ALERT){
        return {
            ...lightNTFInvestmentRiskAlertState,
            lightntfInvestmentRiskAlertResult:action.payload.result,
        }

    }
    return state;
}