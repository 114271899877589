export const OPEN_PAGE_SUCCESS = 'open_page_success';
export const OPEN_PAGE_LOADING = 'OPEN_PAGE_LOADING';


const openpageState = {
    deFiShield: true,
    lightDeFi: true,
    lightNFT: true,
    walletShield: true,
    partnersGuard:true,
    listening: true
}


export function OpenPageReducer(state = openpageState, action) {
    if (action.type === OPEN_PAGE_SUCCESS) {
        return {
            ...state,
            deFiShield:action.payload.deFiShield,
            lightDeFi:action.payload.lightDeFi,
            lightNFT:action.payload.lightNFT,
            walletShield:action.payload.walletShield,
            partnersGuard:action.payload.partnersGuard,
            listeningGuard: true 
        }
    }
    return state;
}

