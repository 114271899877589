import { React, useEffect } from 'react';

import plus from '../../Assets/img/icons/plus.png';


import { useFormik } from 'formik';
import * as yup from 'yup';

import * as _ from 'lodash';


export function WalletAddresses({ value, onChange = () => { }, main, action, placeholder, focus, dt, deleteItem,walletData }) {
    const schema = yup.object().shape({
        value: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.'),
    });
    const formik = useFormik({
        initialValues: {
            value: value 
        },
        isInitialValid: false,
        validationSchema: schema,
        enableReinitialize: true
    });

    function callUpdateAction(values) {
        if (main || Object.keys(formik.errors).length) {
            return;
        }
        action(values ? values : formik.values);
    }


    useEffect(() => {
    }, [formik.errors])

    return (
        <div className='wallet_address_content'>
            <div className="alerts__input__wrapper address_input__wrapper">
                <input onBlur={() => {
                    callUpdateAction()
                }} placeholder={placeholder} name="value" type="text" value={formik.values.value} className="alerts__input adderss_input" onChange={(e) => {
                    formik.handleChange(e);
                    onChange({ ...formik.values, value: e.target.value });
                }}
                    onFocus={() => {
                        focus && focus('value')
                    }}
                />
            </div>

            {/* {!main && !walletData ?
                <div className="alerts__input__add wallet__add"  onClick={(()=>{
                    deleteItem(dt)
                })}>
                    <div className="alerts__input__add__icon wallet__remove" 
                   >-</div>
                </div> : null} */}
                 <div className="alerts__input__add wallet__add"  onClick={(()=>{
                    deleteItem(dt)
                })}>
                    <div className="alerts__input__add__icon wallet__remove" 
                   >-</div>
                   </div>
            {formik.errors.value ? <span className='custom__error__message'>{formik.errors.value}</span> : null}
            {main ?
                <div className="alerts__input__add wallet__add"
                    onClick={() => {
                        if (!formik.isValid) {
                            return;
                        }
                        action(formik.values)
                        formik.resetForm();
                    }}>
                    <img src={plus} width="14" height="14" alt="plus" className="alerts__input__add__icon " />
                </div> : null}
        </div>)
}