import { React, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  AddDefiShild,
  Header,
  DefiCard,
  AllDefiShildList,
  NoAlertComponent,
} from "../../../Components";
import { AddAddDeFiShieldModal } from "../../../Modal/AddDeFiShield";

import { DeFiShieldThunk } from "../../../Thunks/DeFiShieldThunk";

import antenna from "../../../Assets/img/icons/antenna.png";
import fox from "../../../Assets/img/icons/fox.png";
import coinbase from "../../../Assets/img/icons/coinbase.png";
import phantom from "../../../Assets/img/icons/phantom.png";
import grid from "../../../Assets/img/icons/grid.svg";
import list from "../../../Assets/img/icons/list.svg";
import no_active from "../../../Assets/img/icons/a.svg";
import active_list from "../../../Assets/img/icons/active_list.svg";
import top from "../../../Assets/img/icons/to__top.svg";

import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";
import filter from "../../../Assets/img/icons/filter.svg";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";

import { FilterComponent } from "../../../Components/Filter";

import { NoFilterResultModal } from "../../../Modal/NoFilterResult";

import { LogoLoading } from "../../../Components/index";

import { DEFISHILD_FETCHED } from "../../../Reducer/DeFiShieldReducer";

let interval;

export function DeFiShield() {
  let searchData = {};

  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [tab, showTab] = useState(1);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const defiShieldResult = useSelector((state) => {
    return state.defiShield.defishieldResult;
  });

  const isDefiShieldFetched = useSelector(
    (store) => store.defiShield.isDefiShieldFetched
  );

  const createDefiShildSuccess = useSelector((state) => {
    return state.creteDefiShild.create_defishild_success;
  });

  const [showFilter, setShowFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showFilterMessage, setShowFilterMessage] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isDefiShieldFetched) {
      if (defiShieldResult?.length === 0 || modalIsOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [isDefiShieldFetched, defiShieldResult.length, modalIsOpen]);

  useEffect(() => {
    searchData = {};
    const search = searchParams.get("q");
    searchData = JSON.parse(search || "{}");
    if (interval) {
      clearInterval(interval);
    }
    loadLighDefiList();
    interval = setInterval(() => {
      loadLighDefiList(false);
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [searchParams.toString()]);

  useEffect(() => {
    navigate("/defi-shield");
  }, []);

  useEffect(() => {
    document.getElementById("scrollContent").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  useEffect(() => {
    if (createDefiShildSuccess) {
      setSearchParams(JSON.parse("{}"));
      loadLighDefiList();
    }
  }, [createDefiShildSuccess]);

  useEffect(() => {
    searchData = {};
    const search = searchParams.get("q");
    searchData = JSON.parse(search || "{}");

    if (search && defiShieldResult.length < 1) {
      setShowFilterMessage(true);
    } else {
      setShowFilterMessage(false);
    }
  }, [searchParams, defiShieldResult]);

  const loadLighDefiList = () => {
    const params = { ...searchData };
    dispatch(
      DeFiShieldThunk(
        params?.assets || "",
        params.blockchains || "",
        params.platforms || "",
        params.orderRisk
      )
    );
  };

  useEffect(() => {
    if (defiShieldResult?.length === 0 && !modalIsOpen) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [defiShieldResult.length, modalIsOpen]);

  useEffect(() => {
    dispatch({
      type: DEFISHILD_FETCHED,
    });
  }, []);

  return (
    <>
      <Header title={"DeFi Shield"} />
      <main className={"main" + " " + (openMenu ? "open" : "main")}>
        {showAlert ? (
          <NoAlertComponent
            top={0}
            title={
              "This functionality is not available for your account. Please contact LightCore."
            }
          />
        ) : null}
        <div className="content" id="scrollContent">
          <>
            <div className="row">
              <div className="layout__block">
                <div>
                  <img
                    src={filter}
                    alt=""
                    className="filter__icon"
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                </div>

                <FilterComponent
                  activeclassName={showFilter ? "open" : "close"}
                  urlpath={"/defi-shield"}
                />

                <div
                  className="layout__grid"
                  onClick={() => {
                    showTab(1);
                  }}
                >
                  {tab === 1 ? (
                    <img src={grid} alt="" className="layout__grid__icon" />
                  ) : (
                    <img
                      src={no_active}
                      alt=""
                      className="layout__grid__icon"
                    />
                  )}
                </div>
                <div
                  className="layout__list"
                  onClick={() => {
                    showTab(2);
                  }}
                >
                  {tab === 2 ? (
                    <img
                      src={active_list}
                      alt=""
                      className="layout__list__icon"
                    />
                  ) : (
                    <img src={list} alt="" className="layout__list__icon" />
                  )}
                </div>
                <div className="zoom__page__btn">
                  <img
                    src={zoom_pluse}
                    width="24"
                    height="24"
                    alt=""
                    className="zoom__page__btn__plus"
                    onClick={zoomClose}
                  />
                  <img
                    src={zoom_minus}
                    width="24"
                    height="24"
                    alt=""
                    className="zoom__page__btn__minus"
                    onClick={zoom}
                  />
                </div>

                <div
                  className="add__defi__block"
                  onClick={() => {
                    setTimeout(() => {
                      setShowAlert(true);
                    });
                    setTimeout(() => {
                      setShowAlert(false);
                    }, 2000);
                  }}
                >
                  <div className="add__defi__item">
                    <img
                      src={antenna}
                      width="24"
                      height="24"
                      alt=""
                      className="add__defi__item__icon"
                    />
                  </div>
                  <div className="add__defi__item">
                    <img
                      src={fox}
                      width="24"
                      height="24"
                      alt=""
                      className="add__defi__item__icon"
                    />
                  </div>
                  <div className="add__defi__item">
                    <img
                      src={coinbase}
                      width="24"
                      height="24"
                      alt=""
                      className="add__defi__item__icon"
                    />
                  </div>
                  <div className="add__defi__item">
                    <img
                      src={phantom}
                      width="24"
                      height="24"
                      alt=""
                      className="add__defi__item__icon"
                    />
                  </div>
                </div>
              </div>
              {modalIsOpen ? (
                <AddAddDeFiShieldModal
                  modalIsOpen={modalIsOpen}
                  title={"DefiShield"}
                  modalClose={() => {
                    setIsOpen(false);
                  }}
                />
              ) : (
                ""
              )}
              <>
                <>
                  {tab === 1 ? (
                    <>
                      {defiShieldResult.map((element, index) => {
                        return (
                          <DefiCard
                            key={index}
                            element={element}
                            reloadListCallback={() => {
                              loadLighDefiList();
                            }}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="content_list">
                        <div className="row">
                          {defiShieldResult.map((element) => {
                            return (
                              <AllDefiShildList
                                key={element.id}
                                element={element}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </>
              </>
            </div>
            {loading ? <LogoLoading /> : null}
            {showFilterMessage ? (
              <NoFilterResultModal title={"DeFi Shield"} />
            ) : null}

            {showTopBtn ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                className="to__top"
                onClick={() => {
                  document.getElementById("scrollContent").scrollTo(0, 0);
                }}
              >
                <img src={top} alt="top" className="to__top__icon" />
              </a>
            ) : null}
          </>
        </div>
        <AddDefiShild
          openModal={(event) => {
            setIsOpen(event);
          }}
        />
      </main>
    </>
  );
}
