import { React, useState } from 'react';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import { DeleteCardModule } from '../../Modal/DeleteCard';
import { NoAlertComponent } from '../../Components/NoAlert';
import OutsideClickHandler from 'react-outside-click-handler'
import { ApiDeleteWalletsShield } from '../../Api';
import Modal from 'react-modal';

import { AddWallet } from '../../Modal/index';

import { WalletAddressesComponent } from '../../Components/WalletAddresses';



export function WalletCard({ element, reloadListCallback }) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [openProgress, setOpenProgress] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (value) => {
        setValue(value)
    };


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0',
            border: 'none',
            backgroundColor: 'none'
        },
    };



    const coldprops = {
        percent: 100, // is require
        colorSlice: '#4DC7B5',
        // colorCircle: "rgb(174 174 192 / 10%)",
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const deleteWallet = (id) => {
        ApiDeleteWalletsShield(id)
            .then((result) => {
            })
            .catch((error) => {

            })
    };

    function closeModal() {
        setIsOpen(false);
    };



    return (
        <>
            <div className="card cold">

                <div className="card__header wallet__shild__card__header">
                    <div className="row">
                        <div className="card__settings" style={{ fontSize: '12px' }}
                            onClick={(() => {
                                setIsOpen(true)
                            })}>
                            <i className="icon-setting"></i>
                        </div>

                        {/* <h2 className="card__title">
                            {element?.network[0].toUpperCase() + element?.network.slice(1, element?.network.length).toLowerCase()}
                            {
                                element?.name ? 
                                <span className="card__title__type"> {(element?.name)}</span> : 
                                <span className="card__title__type" style={{opacity:'0'}}>none</span>
                            }
                           
                        </h2> */}
                        <h2 className="card__title">
                            {
                                element?.name ?
                                    <span className='heder_title'> {element?.name}</span> :
                                    <span className="card__title__type" style={{ opacity: '0' }}>none</span>
                            }


                            {
                                element?.network ?
                                    <span className="card__title__type">(
                                        {element?.network[0].toUpperCase() + element?.network.slice(1, element?.network.length).toLowerCase()})</span> :
                                    <span className="card__title__type" style={{ opacity: '0' }}>none</span>
                            }
                        </h2>



                        <div className='close__card__content  wallet__close__card' onClick={(() => {
                            setOpenDeleteModal(!openDeleteModal)
                        })}>
                            <span className="card__close"
                            >
                                <span></span><span></span>
                            </span>
                        </div>


                    </div>
                </div>


                {
                    openDeleteModal ?
                        <DeleteCardModule
                            title="You are going to delete the Wallet"
                            text="This Wallet will be deleted, but can be restored from the Recycle Bin."
                            element={element}
                            deleteItem={(id) => {
                                deleteWallet(id)
                                setOpenDeleteModal(false)
                                reloadListCallback()
                            }}
                            cancel={((event) => {
                                setOpenDeleteModal(event)
                            })} /> : ''
                }



                <div className="card__price">

                    <>    $ {element?.totalPrice ? new Intl.NumberFormat('en-US').format(Math.round(element?.totalPrice)) : 0}</>


                </div>
                <div className="card__wallet">
                    {
                        element?.addressesBalance?.map((element, index) => {
                            return <WalletAddressesComponent element={element} key={index + 1} />
                        })
                    }


                </div>
                <div className="card__risk">
                    <h3 className="card__risk__title">Wallet  Risk</h3>
                    <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')}
                        onClick={(() => {
                            setOpenProgress(!openProgress)
                        })}>
                        <p className="card__risk__alert__title">Alert</p>
                        <p className={'card__risk__alert__number' + ' ' + (value === 0 ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{value}</p>
                    </div>
                    <div className="card__risk__chart">
                        <CircularProgressBar {...coldprops}>
                            <div className='card__round__shadow'></div>
                            <div className='card__risk__chart__min__wrapper'>
                                <p className="card__risk__chart__number">{coldprops.percent}</p>
                                <p className="card__risk__chart__number__of">of 100</p>
                            </div>
                        </CircularProgressBar>
                    </div>

                    <div className="card__risk__info" onClick={(() => {
                        setTimeout(() => {
                            setShowAlert(true)
                        })
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 2000)
                    })}>
                        <i className="icon-info-icon"></i>
                    </div>
                    {openProgress ?
                        <div className={'card__risk__range active card__slider' + ' ' + (value === 0 ? 'card__slider_not_value' : 'card__risk__range active card__slider')}>
                            <Slider
                                min={0}
                                max={100}
                                value={value}
                                orientation='vertical'
                                onChange={handleChange}
                                tooltip={false}

                            />
                        </div>
                        : null}
                </div>
            </div>
            <OutsideClickHandler onOutsideClick={(event) => {
                // event.stopPropagation()
                setShowAlert(false)

            }}>
                {
                    showAlert ?
                        <NoAlertComponent top={60} title={'No Alerts.'} /> : ''
                }
            </OutsideClickHandler>

            <div className='wallet__modal__container'>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                >

                    <AddWallet element={element} closeModal={((event) => {
                        setIsOpen(event);
                    })} />
                </Modal>
            </div>
        </>
    )
}