import { React, useEffect, useState } from 'react';

import email from '../../../../../../../Assets/img/icons/email.png';
import check from '../../../../../../../Assets/img/icons/check__icon.png';
import close from '../../../../../../../Assets/img/icons/close.png';
import plus from '../../../../../../../Assets/img/icons/plus.png';

import OutsideClickHandler from 'react-outside-click-handler';

import { useFormik } from 'formik';
import * as yup from 'yup';

import * as _ from 'lodash';


export function SettingsEmilInput({ value, active, onChange = () => { }, main, id, action, onDelete, placeholder, focus }) {
    const [stateError, setStateError] = useState();
    const schema = yup.object().shape({
        value: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.').email('Please enter a valid email address.'),
    });
    const formik = useFormik({
        initialValues: {
            active: active,
            value: value
        },
        isInitialValid: false,
        validationSchema: schema,
        enableReinitialize: true
    });

    function callUpdateAction(values) {
        if (main || Object.keys(formik.errors).length) {
            return;
        }
        action(values ? values : formik.values);
    }

    useEffect(() => {
    }, [formik.errors])

    return (<>
    <div className='alerts__input__wrapper__container'>
        <div className={"alerts__input__wrapper " + (!main ? formik.values.active ? "active" : "disable" : '')}>
            <div className="alerts__input__icon__wrapper">
                <img width="20" height="16" src={email} alt=""
                    className="alerts__input__icon" />
            </div>
        
                <input onBlur={() => {
                    callUpdateAction()
                }} placeholder={placeholder} name="value" type="text" value={formik.values.value} className="alerts__input" onChange={(e) => {
                    formik.handleChange(e);
                    onChange({ ...formik.values, value: e.target.value });
                }}
                    onFocus={() => {
                        focus && focus('value')
                    }}

                />
                <input type="checkbox" value={formik.values.active} checked={formik.values.active} className="alerts__check__input" name='active' id={`email_setting_${id}`} onChange={(e) => {
                    formik.handleChange(e);
                    onChange({ ...formik.values, active: e.target.checked, valid: formik.errors });
                    callUpdateAction({ active: e.target.checked });
                }} />
                <label htmlFor={`email_setting_${id}`} className="alerts__input__check">
                    <img src={check} width="12" height="12" alt=""
                        className="alerts__input__check__icon" />
                </label>
            {!main ? <img src={close} alt="" className="remove__input" onClick={() => onDelete()} /> : null}
         
        </div>
        {formik.errors.value ? <span className='custom__error__message'>{formik.errors.value}</span> : null}
        </div>
     
        {main ?
            <div className="alerts__input__add email__input__add" onClick={() => {
                if (!formik.isValid) {
                    return;
                }
                action(formik.values)?.then(() => {
                    formik.resetForm();
                });
            }}>
                <img src={plus} width="14" height="14" alt="plus" className="alerts__input__add__icon" />
            </div> : null}
    </>)
}