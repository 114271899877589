export { Main } from './Main';
export { WalletsShield } from './Main/WalletsShield';
export { DeFiShield } from './Main/DeFiShield';
export { Settings } from './Main/Settings';
export { LightDeFi } from './Main/LightDeFi';
export { LightNFT } from './Main/LightNFT';
export { Trash } from './Main/Trash';
export { LightcoreActivate } from './Main/LightcoreActivate';
export { PartnerGuard } from './Main/PartnerGuard';
export { Listing } from './Main/Listing'

export { Auth } from './Auth';
export { Login } from './Auth/Login';
export { QRVerify } from './Auth/QRVerify';
export { ForgotPassword } from './Auth/ForgotPassword';
export { PasswordReset } from './Auth/PasswordReset';