import { REGISTRATION_LOADING, REGISTRATION_ERROR, REGISTRATION_SUCCESS } from '../Reducer/RegistrationReducer';
import { ApiRegistration } from '../Api';

export function RegistrationThunk(fullName, email, phone, company, position) {
    return (dispatch, getState) => {
        dispatch({
            type: REGISTRATION_LOADING
        });
        ApiRegistration(fullName, email, phone, company, position)
            .then((result) => {
                dispatch({
                    type: REGISTRATION_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: REGISTRATION_ERROR,
                    paylod: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}
