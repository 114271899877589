import { PLATFORMS_SUCCESS } from '../Reducer/PlatformsReducer';

import { ApiGetPlatforms } from '../Api';

export function PlatformsThunk(id,name) {
    return (dispatch, getState) => {
        ApiGetPlatforms(id,name)
            .then((result) => {
                dispatch({
                    type: PLATFORMS_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => {
            })
    }
}