import { useState,useEffect } from "react";
import { ApiAddNotificationSetting, ApiUpdateNotificationSettings,ApiDeleteNotificationSettings } from "../../../../../../Api";
import { NOTIFICATION_SETTINGS_TYPES } from "../../../../../../Constants/NotificationSettingTypes";
import { SettingPhoneInput } from "./SettingPhoneInput";


export function PhoneSettings({ reloadData, phones }) {

    const [stateError, setStateError] = useState();
    const [phoneError, setPhoneError] = useState();

    const [mainInputValue, setMainInputValue] = useState({
        value: '',
        active: false
    });

    function addNotificationPhone(
        receiver,
        isActive,
        type
    ) {
        const sendingData = {
            receiver,
            type,
            isActive
        }
        return ApiAddNotificationSetting(sendingData)
            .then(() => {
                reloadData();
            })
            .catch((error) => {
                setPhoneError(error?.response?.data?.error?.message)
            })
    }

    function updateNotificationPhone(
        id,
        receiver,
        isActive,
        type
    ) {
        const sendingData = {
            receiver,
            type,
            isActive
        }
        return ApiUpdateNotificationSettings(id, sendingData)
            .then(() => {
                reloadData();
            })
            .catch((error) => {
                setPhoneError(error?.response?.data?.error?.message)
            })
    }

    function deleteNotificationPhone(
        id
    ) {
        return ApiDeleteNotificationSettings(id)
            .then(() => {
                reloadData();
            });
    }

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    useEffect(() => {
        if (phoneError) {
            setStateError(phoneError);
        }
    }, [phoneError]);

    return (
        <>
            <div className="password__change__block">
                <div className="alerts__input__box__all">
                {phoneError ?
                    <span className='settings_error_message'>{stateError}</span> : null
                }
                    {
                        phones?.map((dt, index) => {
                            return <SettingPhoneInput placeholder={'Phone Call (CSOC)'} id={index} key={index} value={dt.receiver} active={dt.isActive} action={(e) => {
                           return  updateNotificationPhone(dt.id, e.value, e.active, NOTIFICATION_SETTINGS_TYPES.PHONE);
                            }}
                            onDelete={() => {
                                deleteNotificationPhone(dt.id);
                            }} 
                            />
                        })
                    }
                    <SettingPhoneInput main={true} placeholder={'Phone Call (CSOC)'} id="main" value={mainInputValue.value} active={mainInputValue.active}
                      focus={(event) => {
                        deleteErrorButton(event)
                    }} 
                    action={(e) => {
                    return addNotificationPhone(e.value, e.active, NOTIFICATION_SETTINGS_TYPES.PHONE);
                    }} />
                </div>
            </div>
        </>
    )
}