export const UPDETE_LIGHT_DEFI_INVESTMENT_ALERT_SUCCESS = 'update_light_defi_investment_alert_success';


const updateInvestmentAlertState = {
    updateLightDefiInvestmentAlertSuccess: false,
}

export function UpdateLightDefiInvestmentAlertReducer(state = updateInvestmentAlertState, action) {

    if (action.type === UPDETE_LIGHT_DEFI_INVESTMENT_ALERT_SUCCESS) {
        return {
            ...updateInvestmentAlertState,
            updateLightDefiInvestmentAlertSuccess: true,
        }

    }

    return state;

}