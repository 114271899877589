import { CHANGE_QR_CODE_LOADING, CHANGE_QR_CODE_ERROR, CHANGE_QR_CODE_SUCCESS } from '../Reducer/ChangeQRCodeReducer';
import { ApiChangeQrCode } from '../Api';

export function ChangeQRCodeThunk({ code, password }) {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_QR_CODE_LOADING,
        });
        ApiChangeQrCode(code, password)
            .then((result) => {
                dispatch({
                    type: CHANGE_QR_CODE_SUCCESS,
                    paylod:{
                        qrImage:result?.data?.data?.secret,
                    }
                })
            })
            .catch((error) => {
                dispatch({
                    type: CHANGE_QR_CODE_ERROR,
                    paylod: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })

    }
}