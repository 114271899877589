import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import {
  LoginReducer,
  RegistrationReducer,
  TwoFAGenerateReducer,
  TwoFACodeVerificationReducer,
  WalletReducer,
  WalletAddressesReducer,
  GetProfileReducer,
  ChangePasswordReducer,
  CreatePasswordReducer,
  ChangeQRCodeReducer,
  DeFiShieldReducer,
  MenuReducer,
  AssetsReducer,
  BlockchainsReducer,
  PlatformsReducer,
  CreteDefiShildReduicer,
  InvestmentrisksReducer,
  AccessPasswordReducer,
  WebHookUrlReducer,
  EditWebHookUrlReducer,
  GetSettingsReducer,
  DefiShildInvestmentRiskAlertReducer,
  ForgotPasswordReducer,
  PasswordResetReducer,
  TrashReducer,
  ZoomMainReducer,
  OpenPageReducer,
  GetLightDefiReducer,
  CreteLightDefiReduicer,
  LightDefiInvestmentRiskReducer,
  LightDefiInvestmentRiskAlertReducer,
  UpdateLightDefiInvestmentAlertReducer,
  GetLightNTFReducer,
  GetLightNTFProductReducer,
  CreateLightNTFReducer,
  DeleteLightNTFProductReducer,
  CreateWalletShildReducer,
  LightNTFInvestmentRiskReducer,
  UpdateLighNTFInvestmentAlertReducer,
  LightNTFInvestmentRiskAlertReducer,
  LightDefiChartProductSecurityReducer,
  AssetChartStatisticReducer,
  BlockchainChartStatisticReducer,
  PlatformChartStatisticReducer,
  ConectionFilterAllReducer,
  WalletGetByIdReducer,
  EditWalletShildReducer,
  GetPartnerGuardProductReducer,
  GetPartnerGuardReducer,
  CreatePartnerGuardReducer,
  DeletePartnerGuardProductReducer,
  ParnerGuardInvestmentRiskReducer,
  UpdatePartnerGuardInvestmentAlertReducer,
  PartnerGuardInvestmentRiskAlertReducer,
  WalletInvestmentRiskReducer,
  WalletInvestmentRiskAlertReducer,
  UpdateWalletInvestmentAlertReducer,
} from "./Reducer";
import { ListingReducer } from "./Reducer/ListeningReducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  registration: RegistrationReducer,
  twoFAGenerate: TwoFAGenerateReducer,
  codeVerification: TwoFACodeVerificationReducer,
  wallet: WalletReducer,
  WalletAddressesReducer,
  profile: GetProfileReducer,
  changePassword: ChangePasswordReducer,
  createPassword: CreatePasswordReducer,
  changeQrCode: ChangeQRCodeReducer,
  defiShield: DeFiShieldReducer,
  menu: MenuReducer,
  assets: AssetsReducer,
  blockchains: BlockchainsReducer,
  platforms: PlatformsReducer,
  creteDefiShild: CreteDefiShildReduicer,
  investmentRisk: InvestmentrisksReducer,
  accessPassword: AccessPasswordReducer,
  webHookUrl: WebHookUrlReducer,
  getSettings: GetSettingsReducer,
  editWebHookUrl: EditWebHookUrlReducer,
  defiShildInvestmentRiskAlert: DefiShildInvestmentRiskAlertReducer,
  forgotPassword: ForgotPasswordReducer,
  passwordReset: PasswordResetReducer,
  trush: TrashReducer,
  zoomReducer: ZoomMainReducer,
  openPage: OpenPageReducer,
  GetLightDefi: GetLightDefiReducer,
  creteLightDefiReduicer: CreteLightDefiReduicer,
  lightDefiInvestmentRisk: LightDefiInvestmentRiskReducer,
  lightDefiInvestmentRiskAlert: LightDefiInvestmentRiskAlertReducer,
  updateLightDefiInvestmentAlert: UpdateLightDefiInvestmentAlertReducer,
  getLightNTFReducer: GetLightNTFReducer,
  getLightNTFProductReducer: GetLightNTFProductReducer,
  createLightNTFReducer: CreateLightNTFReducer,
  deleteLightNTFProductReducer: DeleteLightNTFProductReducer,
  createWalletShildReducer: CreateWalletShildReducer,
  lightNTFInvestmentRiskReducer: LightNTFInvestmentRiskReducer,
  updateLighNTFInvestmentAlertReducer: UpdateLighNTFInvestmentAlertReducer,
  lightNTFInvestmentRiskAlertReducer: LightNTFInvestmentRiskAlertReducer,
  lightDefiChartProductSecurityReducer: LightDefiChartProductSecurityReducer,
  assetChartStatisticReducer: AssetChartStatisticReducer,
  blockchainChartStatisticReducer: BlockchainChartStatisticReducer,
  platformChartStatisticReducer: PlatformChartStatisticReducer,
  conectionFilterAllReducer: ConectionFilterAllReducer,
  walletGetByIdReducer: WalletGetByIdReducer,
  editWalletShildReducer: EditWalletShildReducer,
  getPartnerGuardProductReducer: GetPartnerGuardProductReducer,
  getPartnerGuardReducer: GetPartnerGuardReducer,
  createPartnerGuardReducer: CreatePartnerGuardReducer,
  deletePartnerGuardProductReducer: DeletePartnerGuardProductReducer,
  parnerGuardInvestmentRiskReducer: ParnerGuardInvestmentRiskReducer,
  updatePartnerGuardInvestmentAlertReducer:
    UpdatePartnerGuardInvestmentAlertReducer,
  partnerGuardInvestmentRiskAlertReducer:
    PartnerGuardInvestmentRiskAlertReducer,
  WalletInvestmentRiskReducer: WalletInvestmentRiskReducer,
  WalletInvestmentRiskAlertReducer: WalletInvestmentRiskAlertReducer,
  UpdateWalletInvestmentAlertReducer: UpdateWalletInvestmentAlertReducer,
  ListingReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
