import {LIGHT_DEFI_INVESTMENT_RISK_ALERT} from '../Reducer/LightDefiInvestmentRiskAlertReducer';
import {ApiGetLightDefiGeneral} from '../Api';


export function LightDefiInvestmentRiskAlertThunk(){
    return (dispatch,getState) =>{
        ApiGetLightDefiGeneral()
        .then((result)=>{
            dispatch({
                type:LIGHT_DEFI_INVESTMENT_RISK_ALERT,
                payload:{
                    result:result.data.data  
                }
            })
        })
    }
}