import { useEffect, useState } from 'react';

import { EmailSettings } from './EmailSettings';
import { SocialSettings } from './SocialSettings';
import { PhoneSettings } from './PhoneSettings';
import {SMSSettings} from './SMSSettings';

import { ApiGetAllNotificationSettings } from '../../../../../Api';
import { NOTIFICATION_SETTINGS_TYPES } from '../../../../../Constants/NotificationSettingTypes';

import {NoAlertComponent} from '../../../../../Components/NoAlert';

import email from '../../../../../Assets/img/icons/email__icon.svg';
import chat from '../../../../../Assets/img/icons/chat__icon.svg';
import phone from '../../../../../Assets/img/icons/phone__not__icon.svg';
import phone_2 from '../../../../../Assets/img/icons/phone__icon.svg';


export function NotificationSettings() {

    const [settingsData, setSettingsData] = useState({});

    const [notificationTab, setNotificationTab] = useState(1);

    const [showAlert,setShowAlert] =useState(false);

    function loadAllNotificationSettingsData() {
        ApiGetAllNotificationSettings()
            .then(({ data }) => data)
            .then(({ data }) => {
                const emails = data.filter((e) => e.type === NOTIFICATION_SETTINGS_TYPES.EMAIL);
                const social = data.filter((e) => e.type === NOTIFICATION_SETTINGS_TYPES.SLACK
                    || e.type === NOTIFICATION_SETTINGS_TYPES.TELEGRAM
                    || e.type === NOTIFICATION_SETTINGS_TYPES.WHATSAPP
                )
                const phones = data.filter((e) => e.type === NOTIFICATION_SETTINGS_TYPES.PHONE);
                const sms = data.filter((e) => e.type === NOTIFICATION_SETTINGS_TYPES.SMS);
                setSettingsData({ emails, social, phones,sms });
            });
    }

    useEffect(() => {
        loadAllNotificationSettingsData();
    }, []);

    return (
        <>
            <div className="login__settings" id="login__settings-2" >
            {
                    showAlert ?
                     <NoAlertComponent top={0} title={'This functionality is not available for your account. Please contact LightCore.'} /> : null
                    
                }
                <div className="login__settings__tabs">
                    <div className={'login__settings__tabs__list' + ' ' + (notificationTab === 1 ? 'login__settings__tabs__list active' : 'login__settings__tabs__list')} onClick={(() => {
                        setNotificationTab(1)
                    })}>
                        <img width="20" height="16" src={email} alt="" className="login__settings__tabs__icon" />
                    </div>
                    <div className={'login__settings__tabs__list' + ' ' + (notificationTab === 2 ? 'login__settings__tabs__list active' : 'login__settings__tabs__list')} 
                    onClick={(() => {
                        setTimeout(() => {
                            setShowAlert(true)
                        })
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 2000)
                    })}>
                        <img width="20" height="20" src={chat} alt="" className="login__settings__tabs__icon" />
                    </div>
                    <div className={'login__settings__tabs__list' + ' ' + (notificationTab === 3 ? 'login__settings__tabs__list active' : 'login__settings__tabs__list')} onClick={(() => {
                        // setNotificationTab(3)
                        setTimeout(() => {
                            setShowAlert(true)
                        })
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 2000)
                    })}>
                        <img width="21" height="23" src={phone} alt="" className="login__settings__tabs__icon" />
                    </div>
                    <div className={'login__settings__tabs__list' + ' ' + (notificationTab === 4 ? 'login__settings__tabs__list active' : 'login__settings__tabs__list')} onClick={(() => {
                        // setNotificationTab(4)
                        setTimeout(() => {
                            setShowAlert(true)
                        })
                        setTimeout(() => {
                            setShowAlert(false)
                        }, 2000)
                    })}>
                        <img width="17" height="19" src={phone_2} alt="" className="login__settings__tabs__icon" />
                    </div>
                </div>

                {notificationTab === 1 ?
                    <EmailSettings emails={settingsData.emails} reloadData={loadAllNotificationSettingsData} /> : null
                }
                {
                    notificationTab === 2 ?
                        <SocialSettings social={settingsData.social} reloadData={loadAllNotificationSettingsData} />
                        : null
                }
                {
                    notificationTab === 3 ?
                        <SMSSettings sms={settingsData.sms} reloadData={loadAllNotificationSettingsData} /> : null
                }

                {
                     notificationTab === 4 ?
                        <PhoneSettings phones={settingsData.phones} reloadData={loadAllNotificationSettingsData} /> : null
                }
            </div>
        </>
    )
}

