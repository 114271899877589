import { useState,useEffect } from "react";
import { ApiAddNotificationSetting, ApiUpdateNotificationSettings, ApiDeleteNotificationSettings } from "../../../../../../Api";

import { SettingSocialInput } from "./SettingSocialInput";

import * as _ from 'lodash';

export function SocialSettings({ social, reloadData }) {

    const [stateError, setStateError] = useState();
    const [socialError, setSoccialError] = useState();

    const [mainInputValue, setMainInputValue] = useState({
        value: '',
        active: false
    });

    function addNotificationSocial(
        receiver,
        isActive,
        type
    ) {
        const sendingData = {
            receiver,
            type,
            isActive
        }
        return ApiAddNotificationSetting(sendingData)
            .then(() => {
                reloadData();
            })
            .catch((error) => {
                setSoccialError(error?.response?.data?.error?.message)
            })
    }

    function updateNotificationSocial(
        id,
        receiver,
        isActive,
        type
    ) {
        const sendingData = {
            receiver,
            type,
            isActive
        }
        return ApiUpdateNotificationSettings(id, sendingData)
            .then(() => {
                reloadData();
            })
            .catch((error) => {
                setSoccialError(error?.response?.data?.error?.message)
            })
    }


    function deleteNotificationSocial(
        id
    ) {
        return ApiDeleteNotificationSettings(id)
            .then(() => {
                reloadData();
            });
    }


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    useEffect(() => {
        if (socialError) {
            setStateError(socialError);
        }
    }, [socialError]);

    return <>



        <div className="password__change__block">
            <div className="alerts__input__box__all">
                {socialError ?
                    <span className='settings_error_message'>{stateError}</span> : null
                }
                {
                    _.cloneDeep(social)?.map((dt, index) => {
                        return <SettingSocialInput key={dt.id} placeholder="" id={index} value={dt.receiver} active={dt.isActive} type={dt.type} action={(e) => {
                            return updateNotificationSocial(dt.id, e.value, e.active, e.type);
                        }} onDelete={() => {
                            deleteNotificationSocial(dt.id);
                        }} />
                    })
                }
                <SettingSocialInput main={true} id="main" value={mainInputValue.value} active={mainInputValue.active}
                    focus={(event) => {
                        deleteErrorButton(event)
                    }}
                    action={(e) => {
                        return addNotificationSocial(e.value, e.active, e.type);
                    }} />
            </div>
        </div>
    </>
}