import { React } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import logo from "../../Assets/img/site/logo.svg";
import trash from "../../Assets/img/icons/trash.svg";
import open from "../../Assets/img/icons/menu__open.png";
import logo_login from "../../Assets/img/site/logo__login.svg";
import { useDispatch, useSelector } from "react-redux";

import { OPEN_MENU } from "../../Reducer/MenuReducer";

import { useLocation } from "react-router-dom";

export function Sider() {
  const { state } = useLocation();

  const dispatch = useDispatch();
  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  return (
    <nav className={"nav" + " " + (openMenu ? "open" : "nav")}>
      <figure className="logo">
        <a href="#" className="logo__link">
          <img width="32" height="36" src={logo} alt="" className="logo__img" />
          <img
            src={logo_login}
            width="200"
            height="36"
            alt=""
            className="logo__img__big"
          />
        </a>
      </figure>
      <ul className="nav__block">
        <li className="nav__list">
          <NavLink
            activeclassname="active"
            to="/assetsinsight"
            className={
              "nav__link" +
              " " +
              (state && state === "Listing" ? "active" : "nav__link")
            }
          >
            <i className="icon-line-chart"></i>
            <span className="nav__text">Assets Insight</span>
          </NavLink>
        </li>
        <li className="nav__list">
          <NavLink
            activeclassname="active"
            to="/defi-shield"
            className={
              "nav__link" +
              " " +
              (state && state === "DeFi Shield" ? "active" : "nav__link")
            }
          >
            <i className="icon-dfi-shield"></i>
            <span className="nav__text">DeFi Shield</span>
          </NavLink>
        </li>
        <li className="nav__list">
          <NavLink
            activeclassname="active"
            to="/light-defi"
            className={
              "nav__link" +
              " " +
              (state && state === "Light DeFi" ? "active" : "nav__link")
            }
          >
            <i className="icon-investment"></i>
            <span className="nav__text">Light DeFi</span>
          </NavLink>
        </li>
        <li className="nav__list">
          <NavLink
            activeclassname="active"
            to="/counterparty-risk"
            className={
              "nav__link" +
              " " +
              (state && state === "Counterparty Risk" ? "active" : "nav__link")
            }
          >
            <i className="icon-new-bank"></i>
            <span className="nav__text">Counterparty Risk</span>
          </NavLink>
        </li>
        <li className="nav__list">
          <NavLink
            activeclassname="active"
            to="/light-nft"
            className={
              "nav__link" +
              " " +
              (state && state === "Light NFT" ? "active" : "nav__link")
            }
          >
            <i className="icon-nft"></i>
            <span className="nav__text">Light NFT</span>
          </NavLink>
        </li>
        <li className="nav__list">
          <NavLink
            activeclassname="active"
            to="/wallet-shield"
            className={
              "nav__link" +
              " " +
              (state && state === "Wallets Shield" ? "active" : "nav__link")
            }
          >
            <i className="icon-wallet"></i>
            <span className="nav__text">Custody Shield</span>
          </NavLink>
        </li>
        <li className="nav__list trash">
          <NavLink activeclassname="active" to="/recycle" className="nav__link">
            <img
              width="25"
              height="25"
              src={trash}
              className="trash__icon"
              alt=""
            />
            <span className="nav__text">Recycle</span>
          </NavLink>
        </li>
      </ul>
      <div className={"menu__open" + " " + (openMenu ? "open" : "menu__open")}>
        <div
          className="menu__open__btn"
          onClick={() => {
            dispatch({
              type: OPEN_MENU,
              payload: {
                open: !openMenu,
              },
            });
          }}
        >
          <img
            src={open}
            width="19"
            height="19"
            alt=""
            className="menu__open__icon"
          />
        </div>
      </div>
    </nav>
  );
}
