import { React } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Header } from "../../../Components/Header";
import l_big from "../../../Assets/img/login/l_big.png";
import l_mid from "../../../Assets/img/login/l_mid.png";
import l_small from "../../../Assets/img/login/l_small.png";
import l_round from "../../../Assets/img/login/l_round.png";
import l_logo from "../../../Assets/img/login/l_logo.png";

export function LightcoreActivate() {
  const { state } = useLocation();
  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  return (
    <>
      <Header title={""} />
      <main
        className={
          "main empty__trash" + " " + (openMenu ? "open" : "main empty__trash")
        }
      >
        <div className="active__page__main__container">
          <div className="active__page__main">
            <h4
              className="light_core_activate_text"
              style={{ fontSize: "50px", fontWeight: "600" }}
            >
              {state}
            </h4>
            <h3 className="light_core_activate_text">
              Please contact LightCore
            </h3>
            <h2 className="light_core_activate_title">
              {" "}
              to activate this product.
            </h2>
            <div className="loading__layer active_loading__layer">
              <div className="loading__wrapper">
                <img src={l_big} alt="" className="l_big" />
                <img src={l_mid} alt="" className="l_mid" />
                <img src={l_small} alt="" className="l_small" />
                <img src={l_round} alt="" className="l_round" />
                <img src={l_logo} alt="" className="l_logo" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
