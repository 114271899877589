import React, { useEffect, useState } from 'react';


export function AlerInputComponent({ top, alertvalue, closeModal, changeAlertValue }) {

    const [inputValue, setInputValue] = useState('');
    const [showError, SetShowError] = useState(false);

    const deleteQrErrorButton = () => {
        SetShowError(null)

    };
    useEffect(() => {
        if (alertvalue) {
            setInputValue(alertvalue);
        }
    }, [alertvalue]);


    return (
        <div>
            <div className='alert__midal alert__input__modal' style={{ top: top }}>

                <div>
                    {
                        showError ?
                            <span className='error_message' style={{ marginBottom: '10px' }}>value must be between 0 and 100</span> : null
                    }

                    <input className='password__change__input' style={{ width: '240px' }} value={inputValue} onChange={((event) => {
                        setInputValue(event.target.value)
                    })}
                        onFocus={() => deleteQrErrorButton('')}

                    />

                </div>


                <div className="alert_btn_content">
                    <button className="login__btn" style={{ width: '100px' }} onClick={(() => {
                        if (inputValue >= 0 && inputValue <= 100 && inputValue != '' || null || undefined) {
                            changeAlertValue(inputValue);
                        }
                        else {
                            SetShowError(true);
                        }
                    })}>Save</button>
                    <button className="login__btn" style={{ width: '100px' }} onClick={(() => {
                        closeModal(false)
                    })}>Close</button>
                </div>

            </div>

        </div>)
}