import { CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS } from '../Reducer/ChangePassword';
import { ApiChangePassword } from '../Api';

export function ChangePasswordThunk({ oldPassword, newPassword }) {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_PASSWORD_LOADING
        })
        ApiChangePassword(oldPassword, newPassword)
            .then((result) => {
                dispatch({
                    type: CHANGE_PASSWORD_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: CHANGE_PASSWORD_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}