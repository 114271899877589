import  {EDIT_WALLET_SHILD_SUCCESS,EDIT_WALLET_SHILD_LOADING,EDIT_WALLET_SHILD_ERROR} from '../Reducer/EditWalletShildReducer';
import {ApiUpdateWallet} from '../Api';

export function EditWalletShilThunk(walletId,network,wallet_type,wallet_service_type,name,addresses,params){
    return (dispatch,getState) =>{
        dispatch({
            type:EDIT_WALLET_SHILD_LOADING
        });
        ApiUpdateWallet(walletId,network,wallet_type,wallet_service_type,name,addresses,params)
        .then((result)=>{
            dispatch({ 
                type:EDIT_WALLET_SHILD_SUCCESS
            });
        })
        .catch((error)=>{
            dispatch({
                type:EDIT_WALLET_SHILD_ERROR,
                payload:{
                    error:error?.response?.data?.error?.message,
                }
            });
        })
    }
}