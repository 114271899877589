export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';

const initialState = {
    addresses: [],
}

export function WalletAddressesReducer(state = initialState, action) {
    if (action.type === SET_WALLET_ADDRESS) {
        return {
            ...state,
            addresses: [...state.addresses, action.payload],
        }
    }
    return state;
}
