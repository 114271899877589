import {GET_LIGHT_DEFI} from '../Reducer/GetLightDefiReducer';
import {ApiGetLightDeFi} from '../Api';


export function GetLightDefiThunk(assetId,blockchainId,platformId,orderRisk){
    return (dispatch,getState) =>{
        ApiGetLightDeFi(assetId,blockchainId,platformId,orderRisk)
        .then((result)=>{
            dispatch({
                type:GET_LIGHT_DEFI,
                payload:{
                    result:result.data.data
                }
            })
        })
       

    }
}