import { BLOCKCHAINS_SUCCESS } from '../Reducer/BlockchainsReducer';

import { ApiBlockchains } from '../Api';

export function BlockchainsThunk(assetId, platformId) {
    return (dispatch, getState) => {
        ApiBlockchains(assetId, platformId)
            .then((result) => {
                dispatch({
                    type: BLOCKCHAINS_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    }
}