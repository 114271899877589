export const CREATE_WALLET_SHILD_SUCCESS = 'CREATE_WALLET_SHILD_SUCCESS';
export const CREATE_WALLET_SHILD_LOADING = 'CREATE_WALLET_SHILD_LOADING';
export const CREATE_WALLET_SHILD_ERROR = 'CREATE_WALLET_SHILD_ERROR';
export const CREATE_WALLET_SHILD_SUCCESS_CLOSE_MODAL = 'CREATE_WALLET_SHILD_SUCCESS_CLOSE_MODAL';

const createWalletShildState ={
crete_wallet_shild_success:false,
crete_wallet_shild_error:null,
crete_wallet_shild_loading:false,
};

export function CreateWalletShildReducer(state=createWalletShildState,action){

    if(action.type === CREATE_WALLET_SHILD_SUCCESS){

        return {
            ...createWalletShildState,
            crete_wallet_shild_success:true,
            crete_wallet_shild_error:null,
            crete_wallet_shild_loading:false,
        }
    }

    else if(action.type === CREATE_WALLET_SHILD_LOADING ){

        return {
            ...createWalletShildState,
            crete_wallet_shild_success:false,
            crete_wallet_shild_error:null,
            crete_wallet_shild_loading:true,
        }
    }

    else if(action.type === CREATE_WALLET_SHILD_ERROR){
        return {
            ...createWalletShildState,
            crete_wallet_shild_success:false,
            crete_wallet_shild_error:action.payload.error,
            crete_wallet_shild_loading:false,
        }
    }
    
    else if(action.type === CREATE_WALLET_SHILD_SUCCESS_CLOSE_MODAL ){

        return {
            ...createWalletShildState,
            crete_wallet_shild_success:false,
            crete_wallet_shild_error:null,
            crete_wallet_shild_loading:false,
        }
    }

    return state;

}