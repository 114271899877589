import { useState, useEffect } from "react";
import { SettingsEmilInput } from "./SettingEmailInput";

import { ApiAddNotificationSetting, ApiDeleteNotificationSettings, ApiUpdateNotificationSettings } from "../../../../../../Api";
import { NOTIFICATION_SETTINGS_TYPES } from "../../../../../../Constants/NotificationSettingTypes";

import plus from '../../../../../../Assets/img/icons/plus.png';
import { type } from "@testing-library/user-event/dist/type";

import * as _ from 'lodash';

export function EmailSettings({ emails, reloadData }) {

    const [stateError, setStateError] = useState();
    const [emailError, setEmailError] = useState();

    const [mainInputValue, setMainInputValue] = useState({
        value: '',
        active: false
    });

    function addNotificationEmail(
        receiver,
        isActive,
        type,
    ) {
        const sendingData = {
            receiver,
            type,
            isActive
        }
        return ApiAddNotificationSetting(sendingData)
            .then(() => {
                reloadData();
            })
            .catch((error) => {
                setEmailError(error?.response?.data?.error?.message)
            })

    }

    function updateNotificationEmail(
        id,
        receiver,
        isActive,
        type
    ) {
        const sendingData = {
            receiver,
            type,
            isActive
        }
        return ApiUpdateNotificationSettings(id, sendingData)
            .then(() => {
                reloadData();
            })
            .catch((error) => {
                setEmailError(error?.response?.data?.error?.message)
            })

    }

    function deleteNotificationEmail(
        id
    ) {
        return ApiDeleteNotificationSettings(id)
            .then(() => {
                reloadData();
            });
    }

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    useEffect(() => {
        if (emailError) {
            setStateError(emailError);
        }
    }, [emailError]);

    return (
        <>
            <div className="password__change__block">
                <div className="alerts__input__box__all">
                    {emailError ?
                        <span className='settings_error_message'>{stateError}</span> : null
                    }
                    {
                        _.cloneDeep(emails)?.map((dt, index) => {
                            return <SettingsEmilInput placeholder={'Email'} id={index} key={dt.id} value={dt.receiver} active={dt.isActive} action={(e) => {
                                return updateNotificationEmail(dt.id, e.value, e.active, NOTIFICATION_SETTINGS_TYPES.EMAIL);
                            }} onDelete={() => {
                                deleteNotificationEmail(dt.id);
                            }}
                            />
                        })
                    }
                    <SettingsEmilInput main={true} placeholder={'Email'} id="main" value={mainInputValue.value} active={mainInputValue.active}
                        focus={(event) => {
                            deleteErrorButton(event)
                        }}
                        action={(e) => {
                            return addNotificationEmail(e.value, e.active, NOTIFICATION_SETTINGS_TYPES.EMAIL);
                        }}

                    />
                </div>
            </div>
        </>
    )
}
