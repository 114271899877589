import { React, useEffect, useState } from "react";

import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import { WalletCard } from "../../../Components";
import { Aside, Header } from "../../../Components";
import { AddWallet } from "../../../Modal/index";

import { WalletThunk } from "../../../Thunks/WalletThunk";

import top from "../../../Assets/img/icons/to__top.svg";
import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";

import { LogoLoading } from "../../../Components/index";

import { WALLET_FETCHED } from "../../../Reducer/WalletsReducer";

let interval;

export function WalletsShield() {
  const dispatch = useDispatch();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      border: "none",
      backgroundColor: "none",
    },
  };

  const walletResult = useSelector((state) => {
    return state.wallet.walletResult;
  });

  const create_wallet_shild_success = useSelector((state) => {
    return state.createWalletShildReducer.crete_wallet_shild_success;
  });

  const edit_wallet_shild_success = useSelector((state) => {
    return state.editWalletShildReducer.edit_wallet_shild_success;
  });

  const isWalletFetched = useSelector((state) => {
    return state.wallet.isWalletFetched;
  });

  function closeModal() {
    setIsOpen(false);
  }

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  useEffect(() => {
    dispatch(WalletThunk());
    // if (interval) {
    //     clearInterval(interval);
    // }
    // dispatch(WalletThunk());
    // interval = setInterval(() => {
    //     dispatch(WalletThunk());
    // }, 7500);

    // return () => {
    //     clearInterval(interval);
    // }
  }, []);

  useEffect(() => {
    document.getElementById("scrollContent").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (create_wallet_shild_success) {
      dispatch(WalletThunk());
    }
  }, [create_wallet_shild_success]);

  useEffect(() => {
    if (edit_wallet_shild_success) {
      dispatch(WalletThunk());
    }
  }, [edit_wallet_shild_success]);

  useEffect(() => {
    if (!isWalletFetched && walletResult?.length === 0 && !modalIsOpen) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isWalletFetched, walletResult.length, modalIsOpen]);

  useEffect(() => {
    dispatch({
      type: WALLET_FETCHED,
    });
  }, []);

  return (
    <>
      <Header title={"Custody Shield"} />
      <main className={"main" + " " + (openMenu ? "open" : "main")}>
        <div className="content" id="scrollContent">
          <div className="row">
            {walletResult.length < 1 ? (
              <div
                className="card empty"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <div className="card__header wallet__shild__card__header">
                  <div className="row">
                    <div
                      className="card__settings"
                      style={{ fontSize: "12px" }}
                    >
                      <i className="icon-setting"></i>
                    </div>

                    <h2 className="card__title">
                      New Wallet
                      <span
                        className="card__title__type"
                        style={{ opacity: "0" }}
                      >
                        ewewe
                      </span>
                    </h2>
                    <div className="close__card__content  wallet__close__card">
                      <span className="card__close">
                        <span></span>
                        <span></span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="card__price">$0</div>
                <div className="card__wallet">
                  <div className="card__wallet__row">
                    <div className="card__wallet__rates">201.110 BTC</div>
                    <div className="card__wallet__usd">$ 8,352,000</div>
                  </div>
                  <div className="card__wallet__row">
                    <div className="card__wallet__rates">1,001.554 ETH</div>
                    <div className="card__wallet__usd">$ 3,126,000</div>
                  </div>
                  <div className="card__wallet__row">
                    <div className="card__wallet__rates">3,500,000 USDT</div>
                    <div className="card__wallet__usd">$ 3,500,000</div>
                  </div>
                  <div className="card__wallet__row">
                    <div className="card__wallet__rates">201.110 BTC</div>
                    <div className="card__wallet__usd">$ 8,352,000</div>
                  </div>
                  <div className="card__wallet__row">
                    <div className="card__wallet__rates">1,001.554 ETH</div>
                    <div className="card__wallet__usd">$ 3,126,000</div>
                  </div>
                  <div className="card__wallet__row">
                    <div className="card__wallet__rates">3,500,000 USDT</div>
                    <div className="card__wallet__usd">$ 3,500,000</div>
                  </div>
                </div>
                <div className="card__risk">
                  <h3 className="card__risk__title">Wallet Risk</h3>
                  <div className="card__risk__alert">
                    <p className="card__risk__alert__title">Alert</p>
                    <p className="card__risk__alert__number">60</p>
                  </div>
                  <div className="card__risk__chart">
                    <div className="card__risk__chart__min">
                      <p className="card__risk__chart__number">0</p>
                      <p className="card__risk__chart__number__of">of 100</p>
                    </div>
                  </div>
                  <div className="card__risk__info">
                    <i className="icon-info-icon"></i>
                  </div>
                </div>
              </div>
            ) : null}
            {walletResult.length ? (
              <>
                {walletResult.map((element) => {
                  return (
                    <WalletCard
                      key={element?.id}
                      element={element}
                      reloadListCallback={() => {
                        dispatch(WalletThunk());
                      }}
                      isOpen={(event) => {
                        setIsOpen(event);
                      }}
                    />
                  );
                })}
              </>
            ) : null}
          </div>
          {loading ? <LogoLoading /> : null}

          {showTopBtn ? (
            <a
              className="to__top"
              onClick={() => {
                document.getElementById("scrollContent").scrollTo(0, 0);
              }}
            >
              <img src={top} alt="top" className="to__top__icon" />
            </a>
          ) : null}
          <div className="layout__block">
            <div className="zoom__page__btn">
              <img
                src={zoom_pluse}
                width="24"
                height="24"
                alt=""
                className="zoom__page__btn__plus"
                onClick={zoomClose}
              />
              <img
                src={zoom_minus}
                width="24"
                height="24"
                alt=""
                className="zoom__page__btn__minus"
                onClick={zoom}
              />
            </div>
          </div>
        </div>

        <Aside
          openModal={(event) => {
            setIsOpen(event);
          }}
        />
        <div className="wallet__modal__container">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <AddWallet
              closeModal={(event) => {
                setIsOpen(event);
              }}
            />
          </Modal>
        </div>
      </main>
    </>
  );
}
