import { FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SUCCESS } from '../Reducer/ForgotPasswordReducer';

import { ApiForgotPassword } from '../Api';


export function ForgotPasswordThunk({ email }) {
    return (dispatch, getState) => {
        dispatch({
            type: FORGOT_PASSWORD_LOADING
        });
        ApiForgotPassword(email)
            .then((result) => {
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESS,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FORGOT_PASSWORD_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                });
            })
    }

}
