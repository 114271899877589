export const BLOCKCHAIN_CHART_STATISTIC_SUCCESS = 'BLOCKCHAIN_CHART_STATISTIC_SUCCESS';


const blockChainStatisticState = {
    blockchainStatisticResult: [],
};

export function BlockchainChartStatisticReducer(state=blockChainStatisticState, action) {

    if (action.type === BLOCKCHAIN_CHART_STATISTIC_SUCCESS) {
        return {
            ...blockChainStatisticState,
            blockchainStatisticResult: action.payload.result,
        }
    }

    return state;
}