import React from 'react';
import { useFormik } from 'formik';
import * as  yup from 'yup';

export function WalletShildAccept({ modalClose, sendValues }) {

   const schema = yup.object().shape({
      days: yup.string(),
      hours: yup.string(),
      minutes: yup.string(),
   });

   const walletformik = useFormik({
      initialValues: {
         days: '',
         hours: '',
         minutes:''
      },
      validationSchema: schema,
      onSubmit: values => {
         modalClose();
         sendValues(values);

      }
   },

   );

   return (
      <>
         <form>
            <div style={{ 'width': '100%' }}>
               <div className='wallet_input_form_container' >
                  <div className='wallet_input_form'>
                     <span className='wallet_input_form_title'>Days</span>
                     <input className='wallet_input_form_input' type="text" name='days' id='days'
                        value={walletformik.values.days}
                        onChange={walletformik.handleChange} />
                  </div>
                  <div className='wallet_input_form'>
                     <span className='wallet_input_form_title'>Hours</span>
                     <input className='wallet_input_form_input' type="text" name='hours' id='hours'
                     value={walletformik.values.hours}
                     onChange={walletformik.handleChange}
                      />
                  </div>
                  <div className='wallet_input_form'>
                     <span className='wallet_input_form_title'>Minutes</span>
                     <input className='wallet_input_form_input' type="text" name='minutes' id='minutes'
                     value={walletformik.values.minutes}
                     onChange={walletformik.handleChange}
                      />
                  </div>
               </div>
              
            </div>
            <button type="button" className='wallet_accept__type__save' onClick={walletformik.handleSubmit}>Accept</button>
         </form>
      </>

   )
}

