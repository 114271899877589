import { React } from 'react';


import l_big from '../../Assets/img/login/l_big.png';
import l_mid from '../../Assets/img/login/l_mid.png';
import l_small from '../../Assets/img/login/l_small.png';
import l_round from '../../Assets/img/login/l_round.png';
import l_logo from '../../Assets/img/login/l_logo.png';


export function PageLoadingCompoent() {

    return (
        <>
            <div className="loading__layer active_loading__layer">
                <div className="loading__wrapper">
                    <img src={l_big} alt="" className="l_big" />
                    <img src={l_mid} alt="" className="l_mid" />
                    <img src={l_small} alt="" className="l_small" />
                    <img src={l_round} alt="" className="l_round" />
                    <img src={l_logo} alt="" className="l_logo" />
                </div>
            </div>
        </>
    )
}