export const PARTNER_GUARD_PRODUCT_SUCCESS = 'PARTNER_GUARD_PRODUCT_SUCCESS';
export const PARTNER_GUARD_FETCHED = 'PARTNER_GUARD_FETCHED';

const partnerGuardProductState = {
    partnerGuardProductResult:[],
     isPartnerGuardFetched:false,
}

export function GetPartnerGuardProductReducer(state=partnerGuardProductState,action){
    if (action.type === PARTNER_GUARD_PRODUCT_SUCCESS){
        return {
            ...partnerGuardProductState,
            partnerGuardProductResult:action.payload.result,
            isPartnerGuardFetched:action.payload.result.length? false : true,
        }
    }

    else if(action.type === PARTNER_GUARD_FETCHED){
        return {
            ...partnerGuardProductState, 
            partnerGuardProductResult: [],
            isPartnerGuardFetched:false,  
        }
    }
    
    return state;
}