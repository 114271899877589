export const numberWithComma = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// export const formatCurrency = (price) => {
//   return new Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency: "USD",
//     // minimumFractionDigits: 0,
//     maximumFractionDigits: 2,
//   }).format(price);
// };

export const formatCurrency = (price) => {
  const fractionDigits = price < 0.01 ? 6 : 2;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(price);
};

export const stringToNumber = (value) => {
  // Check if the string is a valid number and does not contain any symbols
  if (/^\d+(\.\d+)?$/.test(value)) {
    return numberWithComma(parseFloat(value));
  }
  return value;
};

export const findLongestString = (arr) => {
  if (arr.length === 0) {
    return null;
  }

  let longestString = arr[0];
  let longestAbovevalue = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i].name.length > longestString.name.length) {
      longestString = arr[i];
    }
    if (arr[i].above && arr[i].above.length > longestAbovevalue.above.length) {
      longestAbovevalue = arr[i];
    }
  }
  return longestAbovevalue.above
    ? longestString.name.length + longestAbovevalue.above.length
    : longestString.name.length;
};
export const removeSpaces = (str) => {
  return str ? str.replace(/\s+/g, "") : str;
};
export const formatPrice = (price) => {
  // Если цена меньше $0.01, то отображаем с 6 знаками после запятой
  if (price < 0.01) {
    return price.toFixed(6);
  }
  // В других случаях можно использовать стандартное форматирование
  return price.toFixed(2);
};
