export const CREATE_LIGHTDEFI_ERROR ='create_lightdefi_error';
export const CREATE_LIGHTDEFI_LOADING ='create_lightdefi_loading';
export const CREATE_LIGHTDEFI_SUCCESS ='create_lightdefi_success';
export const SET_LIGHTDEFI_SUCCESS_FALSE = 'set_lightdefi_success_false';



const creteLightDefiState ={
    create_lightdefi_error:null,
    create_lightdefi_loading:false,
    create_lightdefi_success:false,
}

export function CreteLightDefiReduicer(state=creteLightDefiState,action){
    if(action.type === CREATE_LIGHTDEFI_LOADING){
return {
    ...creteLightDefiState,
    create_lightdefi_loading:true,
}
    }

    else if (action.type === CREATE_LIGHTDEFI_ERROR){
        return {
            ...creteLightDefiState,
            create_lightdefi_loading:false,
            create_lightdefi_error:action.payload.error,
        }
    }

    else if(action.type === CREATE_LIGHTDEFI_SUCCESS){
        return {
            ...creteLightDefiState,
            create_lightdefi_loading:false,
            create_lightdefi_error:null,
            create_lightdefi_success:true,
        }

    }
    else if (action.type === SET_LIGHTDEFI_SUCCESS_FALSE) {
        return {
            ...creteLightDefiState,
            create_lightdefi_error:null,
            create_lightdefi_loading:false,
            create_lightdefi_success: false,
        }

    }
    return state;
}