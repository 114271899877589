import { React, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import * as yup from "yup";

import site from "../../../Assets/img/site/logo__login.svg";
import { Loading } from "../../../Components";
import { LoginThunk } from "../../../Thunks";

import view from "../../../Assets/img/icons/eye.svg";

import { RESET_LOGIN } from "../../../Reducer/LoginReducer";

export function Login() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [stateError, setStateError] = useState();

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.login.loading;
  });

  const error = useSelector((state) => {
    return state.login.error;
  });

  const success = useSelector((state) => {
    return state.login.success;
  });

  const deleteErrorButton = (fieldKey) => {
    if (fieldKey) {
      setStateError(null);
      // setShowForgotPassword(false);
    }
  };

  useEffect(() => {
    if (error) {
      setStateError(error);
    }
  }, [error]);

  useEffect(() => {
    if (error && error[0] === "Incorrect email or password.") {
      setShowForgotPassword(true);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_LOGIN,
      });
    };
  }, []);

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field.")
      .email("Please enter a valid email address."),
    password: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field.")
      .min(
        8,
        // "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."
        "Wrong password."
      )
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        // "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."
        "Wrong password."
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(LoginThunk(values));
    },
  });
  useEffect(() => {
    if (formik.errors.password && formik.touched.password) {
      setShowForgotPassword(true);
    }
  }, [formik.errors.password, formik.touched.password]);

  useEffect(() => {
    if (success) {
      navigate("/qr-verify");
    }
  }, [success]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <form className="login__form" onSubmit={formik.handleSubmit}>
        <img src={site} alt="" className="login__logo" />

        <>
          {error ? (
            <span className="error__message login_requests__message">
              {stateError}
            </span>
          ) : null}
          <div className="login__container"></div>
          <div className="form__content">
            <div
              className={
                "login__input__wrapper" +
                " " +
                (formik.touched.email && formik.errors.email
                  ? "form__content__error"
                  : "login__input__wrapper")
              }
            >
              <input
                type="text"
                className="login__input"
                placeholder="Email"
                name="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onFocus={() => deleteErrorButton("email")}
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <span className="error__message">{formik.errors.email}</span>
            ) : null}
          </div>
        </>
        <>
          <div className="form__content">
            <div
              className={
                "login__input__wrapper" +
                " " +
                (formik.touched.password && formik.errors.password
                  ? "form__content__error"
                  : "login__input__wrapper")
              }
            >
              <input
                className="login__input"
                placeholder="Password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onFocus={() => deleteErrorButton("password")}
                type={passwordShown ? "text" : "password"}
              />
              <img className="view__icon" src={view} onClick={togglePassword} />
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <span className="error__message password__message">
              {formik.errors.password}
            </span>
          ) : null}
        </>
        {showForgotPassword ? (
          <div className="forgot__password__content">
            <p
              className="forgot__passwors_text"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password
            </p>
          </div>
        ) : null}

        <button className="login__btn">
          Login {loading ? <Loading /> : null}
        </button>
      </form>
    </>
  );
}
