export const FORGOT_PASSWORD_LOADING = 'forgot_password_loading';
export const FORGOT_PASSWORD_ERROR = 'forgot_password_error';
export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_success';
export const RESTART_FORGOT_PASSWORD = 'restart_forgot_password';

const forgotPasswordState = {
    forgot_password_loading: false,
    forgot_password_error: null,
    forgot_password_success: false,
}

export function ForgotPasswordReducer(state = forgotPasswordState, action) {
    if (action.type === FORGOT_PASSWORD_LOADING) {
        return {
            ...forgotPasswordState,
            forgot_password_loading: true,

        }
    }

    else if (action.type === FORGOT_PASSWORD_ERROR) {
        return {
            ...forgotPasswordState,
            forgot_password_loading: false,
            forgot_password_error: action.payload.error,

        }
    }

    else if (action.type === FORGOT_PASSWORD_SUCCESS) {
        return {
            ...forgotPasswordState,
            forgot_password_loading: false,
            forgot_password_error: null,
            forgot_password_success: true,

        }
    }
    else if (action.type === RESTART_FORGOT_PASSWORD) {
        return {
            ...forgotPasswordState,
            forgot_password_loading: false,
            forgot_password_error: null,
            forgot_password_success: false,

        }
    }

    return state;
}