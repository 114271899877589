import { React } from 'react';

export function EmptyCircularProgressBar(){
    return (
        <div>
        <div className="card__risk">
            <div className="card__risk__chart">
                <div className="card__risk__chart__min empty_main">
                    <p className="card__risk__chart__number empty_main">0</p>
                    <p className="card__risk__chart__number__of empty_main">of 100</p>
                </div>
            </div>
            <div className="card__risk__range">
                <div className="card__risk__range__bg"></div>
                <div className="card__risk__range__btn">
                    <div className="card__risk__range__btn__min"></div>
                </div>
            </div>
        </div>
    </div>
    )
}