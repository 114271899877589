import React, { useEffect, useState } from 'react';

import menu__open from '../../Assets/img/icons/menu__open.png';

import { Line } from 'react-chartjs-2';

import checked from '../../Assets/img/icons/check__icon.png';

import { useDispatch } from 'react-redux';

import { ApiStatisticsProductSecurity, ApiAssetstatistatics, ApiBlockchainsstatics, ApiPlatformstatics } from '../../Api';

import { CHART_PRODUCT_SECURITY_SUCCESS } from '../../Reducer/LightDefiChartProductSecurityReducer';
import { ASSET_CHART_STATISTIC_SUCCESS } from '../../Reducer/AssetChartStatisticRedcer';
import { BLOCKCHAIN_CHART_STATISTIC_SUCCESS } from '../../Reducer/BlockchainChartStatisticRedcer';
import { PLATFORM_CHART_STATISTIC_SUCCESS } from '../../Reducer/PlatformChartStatisticRedcer';

import * as _ from 'lodash';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    Filler
} from 'chart.js';

import { format } from 'date-fns'
import { useRef } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
);


function getGradient(ctx, chartArea) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(230, 234, 239, 0.4)');
        gradient.addColorStop(0.2, 'rgba(223, 228, 236, 0.4)');
        gradient.addColorStop(0.5, 'rgba(194, 209, 225, 0.4)');
        gradient.addColorStop(0.8, 'rgba(182, 202, 221, 0.4)');
        gradient.addColorStop(1, 'rgba(154, 187, 217, 0.4)');
    }

    return gradient;
}


export function ChartModal({ element, closeModal }) {
    const defaultChartItem = {
        label: '',
        data: [],
        borderColor: '',
        backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
                return;
            }
            return getGradient(ctx, chartArea);
        },
        fill: true
    };

    const dispatch = useDispatch();

    const [selectedPeriod, setSelectedPeriod] = useState([]);

    let chart = useRef();
    let securityData = useRef();
    let assetsData = useRef();
    let blockChainData = useRef();
    let platformData = useRef();
    let chartRange = useRef();

    const [selectedFilters, setSelectedFilters] = useState({ security: true });

    const [dropDownData] = useState(
        [
            { id: 1, title: 'Week', value: 'week' },
            { id: 2, title: 'Month', value: 'month' },
            { id: 3, title: '3 Months', value: 'three_months' },
            { id: 4, title: 'Year', value: 'year' },

        ]
    );

    const defaultPeriod = dropDownData.at(0);

    const [openDropdown, setopenDropdown] = useState(false);


    const [options, setOptions] = useState({
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
        },
        scales: {
            y: {
                ticks: {}
            }
        }
    });


    let labels = [];

    let [data, setData] = useState({
        labels,
        datasets: [
            {
                label: '',
                data: [12, 13, 14, 15, 7, 9, 12],
                borderColor: '#C9CEFF',
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                fill: true,
            }
        ]
    });

    const setInitalValues = () => {
        setSelectedPeriod(dropDownData.at(0));
    };

    useEffect(() => {
        setInitalValues();
        getStatisticsProductSecurity(defaultPeriod.value);
    }, []);

    const renderChart = () => {
        const labelsSet = new Set();
        const additionalData = [
            {
                color: '#00000',
                uniqueId: 'security'
            },
            {
                color: '#C9CEFF',
                uniqueId: 'asset'
            },
            {
                color: '#51e8e8',
                uniqueId: 'blokchain'
            },
            {
                color: '#e6bb10',
                uniqueId: 'platform'
            }

        ]
        const chartData = [
            securityData.current || {},
            assetsData.current || {},
            blockChainData.current || {},
            platformData.current || {}
        ];
        const datasets = [];

        chartData.forEach((e, i) => {
            const { data } = e;
            if (!data) {
                return;
            }
            data.forEach((dt) => {
                labelsSet.add(dt.createdAt);
            });
        })

        const labels = [...labelsSet].sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .map((dt) => {
                return format(new Date(dt), 'dd-MMM');
            });
        chartData.forEach((e, i) => {
            const { data } = e;
            if (!data) {
                return;
            }
            const dataItem = _.cloneDeep(defaultChartItem);

            labels.forEach((label) => {
                const dataPoint = data.find((e) => format(new Date(e.createdAt), 'dd-MMM') === label);
                if (dataPoint) {
                    dataItem.data.push(dataPoint.productRisk || dataPoint.risk);
                } else {
                    dataItem.data.push(null);
                }
            });
            dataItem.borderColor = additionalData[i].color;
            dataItem.uniqueId = additionalData[i].uniqueId;
            datasets.push(dataItem);
        })
        options.scales.y.suggestedMax = chartRange?.current?.max + 5;
        options.scales.y.suggestedMin = chartRange?.current?.min - 5 > 0 ? chartRange?.current?.min - 5 : chartRange?.current?.min;
        setOptions({
            ...options
        });
        setData({
            labels: [...labels],
            datasets: [...datasets]
        });
    }


    const renderColorClassName = () => {
        if (element.productRisk <= 100 && element.productRisk >= 80) {
            return {
                className: 'card__product__number card__product__green',
            };
        }

        else if (element.productRisk < 80 && element.productRisk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        }

        else if (element.productRisk < 60) {
            return {
                className: 'card__product__number card__product__red',
            };
        }
        else if (element.productRisk === null || 0) {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }






    const getStatisticsProductSecurity = (type) => {
        ApiStatisticsProductSecurity(type, element.connectionId)
            .then(({ data: response }) => {
                const { data: result } = response;
                const { max, min } = result;
                securityData.current = result;
                chartRange.current = {
                    max: Math.max(max),
                    min: Math.min(min),
                }
                renderChart();

                dispatch({
                    type: CHART_PRODUCT_SECURITY_SUCCESS,
                    payload: {
                        result
                    }
                })
            })
    };


    const getAssetStatisticInterval = (type) => {
        type = type ? type : selectedPeriod.value;
        ApiAssetstatistatics(type, element?.assetId?.id)
            .then(({ data: response }) => {
                const { data: result } = response;
                const { max, min } = result;
                assetsData.current = result;
                if (!result.data.length) {
                    return;
                }
                chartRange.current = {
                    max: Math.max((chartRange?.current?.max || 0), max),
                    min: Math.min((chartRange?.current?.min || 0), min),
                }
                renderChart();
                dispatch({
                    type: ASSET_CHART_STATISTIC_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    };


    const getBlockchainStatisticInterval = (type) => {
        type = type ? type : selectedPeriod.value;
        ApiBlockchainsstatics(type, element?.blockchainId?.id)
            .then(({ data: response }) => {
                const { data: result } = response;
                const { max, min } = result;
                blockChainData.current = result;
                chartRange.current = {
                    max: Math.max((chartRange?.current?.max || 0), max),
                    min: Math.min((chartRange?.current?.min || 0), min),
                }
                renderChart();
                dispatch({
                    type: BLOCKCHAIN_CHART_STATISTIC_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    };

    const getPlatformStatisticInterval = (type) => {
        type = type ? type : selectedPeriod.value;
        ApiPlatformstatics(type, element?.platformId?.id)
            .then(({ data: response }) => {
                const { data: result } = response;
                const { max, min } = result;
                platformData.current = result;
                chartRange.current = {
                    max: Math.max((chartRange?.current?.max || 0), max),
                    min: Math.min((chartRange?.current?.min || 0), min),
                }
                renderChart();
                dispatch({
                    type: PLATFORM_CHART_STATISTIC_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    };



    return (
        <>
            <div className='chart__container'>
                <div className="card__chart__wrapper">
                    <h2 className="card__title chart__card__title" style={{ margin: '0 0 10px 0' }}>
                        {element.assetId?.symbol} Staking
                    </h2>
                    <div className="card__chart__header">
                        {/* <p className="card__chart__title">History</p> */}
                        <div className="card__chart__dropdown">
                            <div className="card__chart__dropdown__selected" onClick={(() => {
                                setopenDropdown(!openDropdown)
                            })}>
                                {selectedPeriod.title}
                                <div className="card__chart__dropdown__selected__arrow" ></div>
                            </div>
                            {
                                openDropdown ?
                                    <div className="card__chart__dropdown__block">
                                        {
                                            dropDownData.map((element, index) => {
                                                return <div className="card__chart__dropdown__list" key={index + 1}
                                                    onClick={(() => {
                                                        setSelectedPeriod(element);
                                                        setopenDropdown(false);
                                                        if (securityData.current) {
                                                            getStatisticsProductSecurity(element?.value);
                                                        }
                                                        if (assetsData.current) {
                                                            getAssetStatisticInterval(element?.value);
                                                        }
                                                        if (blockChainData.current) {
                                                            getBlockchainStatisticInterval(element?.value);
                                                        }
                                                        if (platformData.current) {
                                                            getPlatformStatisticInterval(element?.value);
                                                        }

                                                    })}>{element?.title}</div>
                                            })
                                        }

                                    </div> : ''
                            }

                        </div>

                        <div className="check__wrapper__all">


                            <div className="check__wrapper white">
                                <input type="checkbox" id="security" checked={selectedFilters.security}
                                    onChange={((event) => {
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            security: event.target.checked
                                        });
                                        if (!event.target.checked) {
                                            securityData.current = null;
                                            renderChart();
                                            return;
                                        }

                                        getStatisticsProductSecurity(selectedPeriod.value);
                                    })} />
                                <label htmlFor="security" className="checkbox__label">
                                    <span className="checkbox__box">
                                        <img src={checked} width="12" height="12" alt=""
                                            className="check__icon" />
                                    </span>
                                    <span className="checkbox__text">Product</span>
                                </label>
                            </div>
                            <div className="check__wrapper purple">
                                <input type="checkbox" id="asset" checked={selectedFilters.asset}
                                    onChange={((event) => {
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            asset: event.target.checked
                                        });
                                        if (!event.target.checked) {
                                            assetsData.current = null;
                                            renderChart();
                                            return;
                                        }

                                        getAssetStatisticInterval();
                                    })} />
                                <label htmlFor="asset" className="checkbox__label">
                                    <span className="checkbox__box">
                                        <img src={checked} width="12" height="12" alt=""
                                            className="check__icon" />
                                    </span>
                                    <span className="checkbox__text">{element?.assetId?.symbol}</span>
                                </label>
                            </div>
                            <div className="check__wrapper blue">
                                <input type="checkbox" id="staking" checked={selectedFilters.blockchain}
                                    onChange={((event) => {
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            blockchain: event.target.checked
                                        });
                                        if (!event.target.checked) {
                                            blockChainData.current = null;
                                            renderChart();
                                            return;
                                        }
                                        getBlockchainStatisticInterval();
                                    })} />
                                <label htmlFor="staking" className="checkbox__label">
                                    <span className="checkbox__box">
                                        <img src={checked} width="12" height="12" alt=""
                                            className="check__icon" />
                                    </span>
                                    <span className="checkbox__text">{element?.blockchainId?.name}</span>
                                </label>
                            </div>
                            <div className="check__wrapper yellow">
                                <input type="checkbox" id="compound" checked={selectedFilters.platform}
                                    onChange={((event) => {
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            platform: event.target.checked
                                        });
                                        if (!event.target.checked) {
                                            platformData.current = null;
                                            renderChart();
                                            return;
                                        }
                                        getPlatformStatisticInterval();
                                    })}
                                />
                                <label htmlFor="compound" className="checkbox__label">
                                    <span className="checkbox__box">
                                        <img src={checked} width="12" height="12" alt=""
                                            className="check__icon" />
                                    </span>
                                    <span className="checkbox__text">{element?.platformId?.name}</span>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className='chart__catent__value'>
                        <p>Current Product Risk -</p>
                        <p style={{ marginLeft: '10px' }} className={renderColorClassName()?.className}>{Math.round(element?.risks?.productRisk ? element?.risks?.productRisk : element.productRisk)}</p>
                    </div>

                    <div className="card__chart">
                        <div className='line__chart'>
                            <span className="risk__text">Risk</span>
                            <Line options={options} data={data} ref={chart} />
                        </div>
                    </div>
                    {/* <div className="card__chart__close">
                        <img src={menu__open} alt="" className="card__chart__close__icon" onClick={(() => {
                            closeModal(false)
                        })} />
                    </div> */}
                </div>
            </div>
        </>
    )
}