export const ASSET_CHART_STATISTIC_SUCCESS = 'ASSET_CHART_STATISTIC_SUCCESS';


const assetStatisticState = {
    assetStatisticResult: [],
};

export function AssetChartStatisticReducer(state=assetStatisticState, action) {

    if (action.type === ASSET_CHART_STATISTIC_SUCCESS) {
        return {
            ...assetStatisticState,
            assetStatisticResult: action.payload.result,
        }
    }

    return state;
}