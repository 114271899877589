export const INVESTMENT_RISK_ALERT_SUCCESS = 'INVESTMENT_RISK_ALERT_SUCCESS';

const investmentRiskAlert ={
    investment_risk_alert_result:[],
}

export function DefiShildInvestmentRiskAlertReducer(state=investmentRiskAlert,action){
    if(action.type === INVESTMENT_RISK_ALERT_SUCCESS){
        return {
            ...investmentRiskAlert,
            investment_risk_alert_result:action.payload.result,
        }
    }

    return state;
}