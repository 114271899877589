export const UPDETE_PARTNER_GUARD_INVESTMENT_ALERT_SUCCESS = 'UPDETE_PARTNER_GUARD_INVESTMENT_ALERT_SUCCESS';


const updateInvestmentAlertState = {
    updatePartnerGuardInvestmentAlertSuccess: false,
}

export function UpdatePartnerGuardInvestmentAlertReducer(state = updateInvestmentAlertState, action) {

    if (action.type === UPDETE_PARTNER_GUARD_INVESTMENT_ALERT_SUCCESS) {
        return {
            ...updateInvestmentAlertState,
            updatePartnerGuardInvestmentAlertSuccess: true,
        }
 
    }

    return state;

}