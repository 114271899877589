import { React, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import loginbig from "../../Assets/img/login/big.png";
import loginmiddle from "../../Assets/img/login/middle.png";
import loginsmall from "../../Assets/img/login/small.png";
import loginround from "../../Assets/img/login/round.png";
import loginUser from "../../Assets/img/login/login__user__btn__icon.png";
import view from "../../Assets/img/icons/eye.svg";

import { Loading } from "../../Components";
import { RegistrationThunk } from "../../Thunks/RegistrationThunk";
import { CreatePasswordThunk } from "../../Thunks/CreatePasswordThunk";

import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountries,
  isValidPhoneNumber,
} from "react-phone-number-input";

yup.addMethod(yup.string, "phoneValidator", function () {
  return this.test({
    name: "name",
    message: "Please enter a valid phone number.",
    test: (phone = "") => {
      return isValidPhoneNumber(phone);
    },
  });
});

export function Auth() {
  const dispatch = useDispatch();
  const [stateError, setStateError] = useState();
  const [codestateError, setcodeStateError] = useState();
  const [passwordShown, setPasswordShown] = useState(false);

  const schema = yup.object().shape({
    fullName: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field."),
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field."),
    company: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces."),
    position: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces."),
    phone: yup.string().required("This is a required field.").phoneValidator(),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      company: "",
      position: "",
      phone: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      let fullName = values.fullName;
      let email = values.email;
      let phone = values.phone;
      let company = values.company;
      let position = values.position;

      dispatch(RegistrationThunk(fullName, email, phone, company, position));
    },
  });

  const verificationCodeSchema = yup.object().shape({
    code1: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field."),
    password: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field.")
      .min(
        8,
        "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."
      )
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."
      ),
  });

  const verificationCodeformik = useFormik({
    initialValues: {
      code1: "",
      password: "",
    },
    validationSchema: verificationCodeSchema,
    onSubmit: (values) => {
      dispatch(
        CreatePasswordThunk({ code: values.code1, password: values.password })
      );
    },
  });

  const [openPurchase, openPurchaseopenPurchase] = useState(false);

  const loading = useSelector((state) => {
    return state.registration.reg_loading;
  });

  const error = useSelector((state) => {
    return state.registration.reg_error;
  });

  const success = useSelector((state) => {
    return state.registration.reg_success;
  });

  const crete_password_loading = useSelector((state) => {
    return state.createPassword.create_password_loading;
  });

  const create_password_error = useSelector((state) => {
    return state.createPassword.create_password_error;
  });

  const create_password_success = useSelector((state) => {
    return state.createPassword.create_password_success;
  });

  const deleteErrorButton = (fieldKey) => {
    if (fieldKey) {
      setStateError(null);
    }
  };

  useEffect(() => {
    if (error) {
      setStateError(error);
    }
  }, [error]);

  const deleteCodeErrorButton = (fieldKey) => {
    if (fieldKey) {
      setcodeStateError(null);
    }
  };

  useEffect(() => {
    if (create_password_error) {
      setcodeStateError(create_password_error);
    }
  }, [create_password_error]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <>
      <div className="wrapper">
        <div className="login__wrapper">
          <img src={loginbig} alt="" className="login__big" />
          <img src={loginmiddle} alt="" className="login__middle" />
          <img src={loginsmall} alt="" className="login__small" />
          <img src={loginround} alt="" className="login__round" />
          <Outlet />
          <div
            className="login__user__btn"
            onClick={() => {
              openPurchaseopenPurchase(true);
            }}
          >
            <img src={loginUser} alt="" className="login__user__btn__icon" />
          </div>
          <>
            <OutsideClickHandler
              onOutsideClick={(event) => {
                //  event.stopPropagation()
                openPurchaseopenPurchase(false);
              }}
            >
              {openPurchase ? (
                <>
                  <div
                    className="purchase__form"
                    onKeyDown={(e) => {
                      if (e.code === "Tab" && e.target.id === "email") {
                        setTimeout(() => {
                          const phoneElement = document.getElementById("phone");
                          phoneElement.focus();
                          phoneElement.click();
                        }, 1);
                      }
                    }}
                    tabIndex="0"
                  >
                    {!create_password_success ? (
                      <h3 className="purchase__form__title">
                        Create an account
                      </h3>
                    ) : (
                      <h3 className="purchase__form__title">Registration</h3>
                    )}

                    {!success ? (
                      <form onSubmit={formik.handleSubmit} tabIndex="0">
                        <span
                          className="error__message requests__message"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {stateError}
                        </span>
                        <div className="form__content">
                          <div
                            className={
                              "login__input__wrapper" +
                              " " +
                              (formik.touched.fullName && formik.errors.fullName
                                ? "form__content__error"
                                : "login__input__wrapper")
                            }
                          >
                            <input
                              type="text"
                              className="login__input"
                              placeholder="Full name *"
                              id="fullName"
                              name="fullName"
                              value={formik.values.fullName}
                              onChange={formik.handleChange}
                              onFocus={() => deleteErrorButton("fullName")}
                            />
                          </div>
                          {formik.touched.fullName && formik.errors.fullName ? (
                            <span className="error__message">
                              {formik.errors.fullName}
                            </span>
                          ) : null}
                        </div>
                        <div className="form__content">
                          <div className="login__input__wrapper">
                            <input
                              type="text"
                              className="login__input"
                              placeholder="Company"
                              id="company"
                              name="company"
                              value={formik.values.company}
                              onChange={formik.handleChange}
                              onFocus={() => deleteErrorButton("company")}
                            />
                          </div>
                          {formik.touched.company && formik.errors.company ? (
                            <span className="error__message">
                              {formik.errors.company}
                            </span>
                          ) : null}
                        </div>
                        <div className="form__content">
                          <div className="login__input__wrapper">
                            <input
                              type="text"
                              className="login__input"
                              placeholder="Position"
                              id="position"
                              name="position"
                              value={formik.values.position}
                              onChange={formik.handleChange}
                              onFocus={() => deleteErrorButton("position")}
                            />
                          </div>
                          {formik.touched.position && formik.errors.position ? (
                            <span className="error__message">
                              {formik.errors.position}
                            </span>
                          ) : null}
                        </div>
                        <div className="form__content">
                          <div
                            className={
                              "login__input__wrapper" +
                              " " +
                              (formik.touched.email && formik.errors.email
                                ? "form__content__error"
                                : "login__input__wrapper")
                            }
                          >
                            <input
                              type="text"
                              className="login__input"
                              placeholder="Email *"
                              id="email"
                              name=""
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onFocus={() => deleteErrorButton("email")}
                            />
                          </div>
                          {formik.touched.email && formik.errors.email ? (
                            <span className="error__message">
                              {formik.errors.email}
                            </span>
                          ) : null}
                        </div>

                        <div className="form__content">
                          <div
                            className={
                              "login__input__wrapper phone_wrapper" +
                              " " +
                              (formik.touched.phone && formik.errors.phone
                                ? "form__content__error"
                                : "login__input__wrapper")
                            }
                          >
                            <PhoneInput
                              id="phone"
                              name="phone"
                              className="phone__input__wrapper"
                              international
                              //   defaultCountry="US"
                              countryCallingCodeEditable={false}
                              placeholder="Phone *"
                              value={formik.values.phone}
                              tabIndex="-1"
                              onChange={(e) => {
                                formik.setFieldValue("phone", e);
                                if (!formik.getFieldMeta("phone").touched) {
                                  formik.setFieldTouched("phone", true);
                                }
                              }}
                              rules={{ required: true }}
                              onFocus={() => {
                                deleteErrorButton("phone");
                              }}
                            />
                          </div>
                          {formik.touched.phone && formik.errors.phone ? (
                            <span className="error__message">
                              {formik.errors.phone}
                            </span>
                          ) : null}
                        </div>

                        <button className="login__btn">
                          Send {loading ? <Loading /> : null}
                        </button>
                      </form>
                    ) : (
                      <>
                        {!create_password_success ? (
                          <div>
                            <h2 className="purchase__form__title">
                              Please check your email
                            </h2>
                            <form
                              onSubmit={verificationCodeformik.handleSubmit}
                            >
                              {codestateError?.map((item) => (
                                <span
                                  key={item}
                                  className="error__message requests__message"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "90%",
                                  }}
                                >
                                  {codestateError}
                                </span>
                              ))}

                              <div className="form__content">
                                <div
                                  className={
                                    "login__input__wrapper" +
                                    " " +
                                    (verificationCodeformik.touched.code1 &&
                                    verificationCodeformik.errors.code1
                                      ? "form__content__error"
                                      : "login__input__wrapper")
                                  }
                                >
                                  <input
                                    type="text"
                                    className="login__input"
                                    placeholder="Code *"
                                    id="code1"
                                    name="code1"
                                    value={verificationCodeformik.values.code1}
                                    onChange={
                                      verificationCodeformik.handleChange
                                    }
                                    onFocus={() =>
                                      deleteCodeErrorButton("code1")
                                    }
                                    autoComplete="new-password"
                                  />
                                </div>
                                {verificationCodeformik.touched.code1 &&
                                verificationCodeformik.errors.code1 ? (
                                  <span className="error__message">
                                    {verificationCodeformik.errors.code1}
                                  </span>
                                ) : null}
                              </div>
                              <div className="form__content">
                                <div
                                  style={{ position: "relative" }}
                                  className={
                                    "login__input__wrapper" +
                                    " " +
                                    (verificationCodeformik.touched.password &&
                                    verificationCodeformik.errors.password
                                      ? "form__content__error"
                                      : "login__input__wrapper")
                                  }
                                >
                                  <input
                                    className="login__input"
                                    placeholder="Password *"
                                    id="password"
                                    name="password"
                                    value={
                                      verificationCodeformik.values.password
                                    }
                                    onChange={
                                      verificationCodeformik.handleChange
                                    }
                                    onFocus={() =>
                                      deleteCodeErrorButton("password")
                                    }
                                    type={passwordShown ? "text" : "password"}
                                    autoComplete="new-password"
                                  />
                                  <img
                                    className="view__icon"
                                    src={view}
                                    onClick={togglePassword}
                                  />
                                </div>
                                {verificationCodeformik.touched.password &&
                                verificationCodeformik.errors.password ? (
                                  <span className="error__message password__message">
                                    {verificationCodeformik.errors.password}
                                  </span>
                                ) : null}
                              </div>
                              <button className="login__btn">
                                Send{" "}
                                {crete_password_loading ? <Loading /> : null}
                              </button>
                            </form>
                          </div>
                        ) : (
                          <h2 className="purchase__form__title">
                            You have successfully registered.
                          </h2>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </OutsideClickHandler>
          </>
        </div>
      </div>
    </>
  );
}
