import axios from "axios";
import { api } from "./Services";
import { apiVerify } from "./Services/Base";

export const ApiLogin = (email, password) => {
  return api.post("auth/login", {
    email: email.toString().toLowerCase(),
    password,
  });
};

export const ApiTwoFAGenerate = () => {
  return api.post("2fa/generate", {
    type: 0,
  });
};

export const ApiTwoFACodeVerification = (code) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/verify`,
    {
      code: code.toString(),
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

export const ApiGetWallets = () => {
  return api.get("wallet");
};

export const ApiGetWalletNetworks = () => {
  return api.get("wallet/networks");
};

export const ApiGetProfile = () => {
  return api.get("user/profile");
};

export const ApiChangePassword = (oldPassword, newPassword) => {
  return api.post("auth/reset-password", {
    oldPassword,
    newPassword,
  });
};

export const ApiRegistration = (fullName, email, phone, company, position) => {
  return api.post("auth/registration", {
    fullName,
    email,
    phone: phone.toString(),
    company,
    position,
  });
};

export const ApiCreatePassword = (code, password) => {
  return api.post("auth/create-password", {
    code: code.toString(),
    password,
    type: "createPassword",
  });
};

export const ApiAddNotificationSetting = (params) => {
  return api.post("alerts/service", { ...params });
};

export const ApiGetAllNotificationSettings = () => {
  return api.get("alerts/services");
};

export const ApiUpdateNotificationSettings = (id, params) => {
  return api.put(`alerts/service/${id}`, { ...params });
};

export const ApiDeleteNotificationSettings = (id) => {
  return api.delete(`/alerts/service/${id}`);
};

export const ApiChangeQrCode = (code, password) => {
  return api.post("2fa/change-qr", {
    type: 0,
    code: code.toString(),
    password,
  });
};

export const ApiChangeUserInformation = (params, cancelToken) => {
  const formData = new FormData();

  for (let item in params) {
    if (params[item]) {
      formData.append(item, params[item]);
    }
  }

  return api.put("user", formData, { cancelToken: cancelToken?.token });
};

export const ApiDeleteUserAvatar = (params) => {
  return api.delete("user/avatar", params);
};

export const ApiDeFiShield = (assets, blockchains, platforms, orderRisk) => {
  const params = { assets, blockchains, platforms, orderRisk };
  for (let key in params) {
    if (!params[key] || (Array.isArray(params[key]) && !params[key].length)) {
      delete params[key];
    }
  }
  return api.get("defishield/list", { params });
};

export const ApiUnstake = (id) => {
  return api.post("user/settings/unstake", {
    id,
  });
};

export const ApiGetAssets = (blockchainId, platformId) => {
  return api.get("defishield/defaults/assets", {
    params: { blockchainId, platformId },
  });
};

export const ApiBlockchains = (assetId, platformId) => {
  return api.get(`defishield/defaults/blockchains`, {
    params: { assetId, platformId },
  });
};

export const ApiGetPlatforms = (id, name) => {
  return api.get(`defishield/defaults/platforms`, {
    params: {
      assetId: id,
      blockchainName: name,
    },
  });
};

export const ApiCreateDefishield = (assetId, blockchainId, platformId) => {
  return api.post("defishield", {
    assetId,
    blockchainId,
    platformId,
  });
};

export const ApiDeleteDefishield = (id) => {
  return api.delete(`defishield/${id}`);
};

export const ApiDefishieldInvestmentRisks = () => {
  return api.get("defishield/investment/risks");
};

export const ApiDeFiShieldUpdate = (defishildId, autoUnstacking) => {
  return api.put(`defishield/${defishildId}`, {
    autoUnstacking,
  });
};
export const ApiDeFiShieldAlertUpdate = (defishildId, alert) => {
  return api.put(`defishield/${defishildId}`, {
    alert,
  });
};

export const ApiLightDefiAlertUpdate = (lightDefiId, alert) => {
  return api.put(`lightdefi/${lightDefiId}`, {
    alert,
  });
};

export const ApiQRVerify = () => {
  return api.get("2fa/verify");
};

export const ApiAccessPassword = (password) => {
  return api.post("user/settings/access-password", {
    password,
  });
};

export const ApiWebhookUrl = (webhookUrl) => {
  return api.post(
    "user/settings",
    { webhookUrl },
    {
      headers: {
        test: "tesstt",
        "accept-token": localStorage.getItem("acceptToken"),
      },
    }
  );
};

export const ApiGetSetting = () => {
  return api.get("user/settings");
};

export const ApiEditWebhookUrl = (webhookUrl) => {
  return api.patch(
    "user/settings",
    { webhookUrl },
    {
      headers: {
        test: "tesstt",
        "accept-token": localStorage.getItem("acceptToken"),
      },
    }
  );
};

export const ApiUpdateDefishieldInvestmentRisks = (alert, type) => {
  return api.put("defishield/investment/risks", {
    alert,
    type,
  });
};

export const ApiGetInvestmentRisk = () => {
  return api.get("defishield/investment/risks/general");
};

export const ApiForgotPassword = (email) => {
  return api.post("auth/forgot-password", {
    email,
  });
};

export const ApiResetPassword = (code, password) => {
  return api.post("auth/create-password", {
    code: code.toString(),
    password,
    type: "forgotPassword",
  });
};

export const ApiGetTrash = () => {
  return api.get("trash/list");
};

export const ApiDeleteTrash = (id) => {
  return api.delete(`trash/${id}`);
};

export const ApiTrashRestore = (id) => {
  return api.post(`trash/restore/${id}`);
};

export const ApiCreateWallet = (
  network,
  wallet_type,
  wallet_service_type,
  name,
  addresses,
  params
) => {
  return api.post(
    "wallet",
    network,
    wallet_type,
    wallet_service_type,
    name,
    addresses,
    params
  );
};

export const ApiGetUserShields = (id) => {
  return api.get("user/shields", {
    params: {
      userId: id + "",
    },
  });
};

export const ApiGetLightDeFi = (assets, blockchains, platforms, orderRisk) => {
  const params = { assets, blockchains, platforms, orderRisk };
  for (let key in params) {
    if (!params[key] || (Array.isArray(params[key]) && !params[key].length)) {
      delete params[key];
    }
  }
  return api.get("lightdefi/list", { params });
};

export const ApiCreateLightDefi = (assetIds, blockchainIds, platformIds) => {
  return api.post("lightdefi", {
    assetIds,
    blockchainIds,
    platformIds,
  });
};

export const ApiDeletLightDefi = (id) => {
  return api.delete(`lightdefi/${id}`);
};

export const ApiLightDefiInvestmentRisk = () => {
  return api.get("lightdefi/investment/risks");
};

export const ApiGetLightDefiGeneral = () => {
  return api.get("lightdefi/investment/risks/general");
};

export const ApiUpdateLightDefiAlert = (alert, type) => {
  return api.put("lightdefi/investment/risks", {
    alert,
    type,
  });
};

export const ApiGetAllLightNT = (searchValue) => {
  return api.get(`lightnft/defaults/all?search=${searchValue}`);
};

export const ApiGetProductLightNT = (orderRisk) => {
  return api.get("lightnft/products", { params: { orderRisk } });
};

export const ApiCreateLightNT = (id) => {
  return api.post("lightnft/product", {
    lightNFTId: id,
  });
};

export const ApiDeleteLightNTFProduct = (id) => {
  return api.delete(`lightnft/product/${id}`);
};

export const ApiLightNTFAlertUpdate = (lightnftiId, alert) => {
  return api.put(`lightnft/${lightnftiId}`, {
    alert,
  });
};

export const ApiLighNTFInvestmentRisk = () => {
  return api.get("lightnft/investment/risks");
};

export const ApiWalletInvestmentRisk = () => {
  return api.get("wallet/investment/risks");
};

export const ApiUpdateLightNTFAlert = (alert, type) => {
  return api.put("lightnft/investment/risks", {
    alert,
    type,
  });
};

export const ApiUpdateWalletAlert = (alert, type) => {
  return api.put("wallet/investment/risks", {
    alert,
    type,
  });
};

export const ApiGetLighNTFfiGeneral = () => {
  return api.get("lightnft/investment/risks/general");
};

export const ApiGetWalletGeneral = () => {
  return api.get("wallet/investment/risks/general");
};

export const ApiStatisticsProductSecurity = (interval, connectionId) => {
  return api.get(
    `statistics/product/security?interval=${interval}&connectionId=${connectionId}`
  );
};

export const ApiAssetstatistatics = (interval, assetId) => {
  return api.get(`statistics/asset?interval=${interval}&assetId=${assetId}`);
};

export const ApiBlockchainsstatics = (interval, blockchainId) => {
  return api.get(
    `statistics/blockchain?interval=${interval}&blockchainId=${blockchainId}`
  );
};

export const ApiPlatformstatics = (interval, platformId) => {
  return api.get(
    `statistics/platform?interval=${interval}&platformId=${platformId}`
  );
};

export const ApiStatisticsLightnft = (interval, lightNFTId) => {
  return api.get(
    `statistics/lightnft?interval=${interval}&lightNFTId=${lightNFTId}`
  );
};

export const ApiStatisticspartnersGuard = (interval, partnerGuardId) => {
  return api.get(
    `statistics/partner-guard?interval=${interval}&partnerGuardId=${partnerGuardId}`
  );
};

export const ApiLightdefiFilter = (
  assetId,
  blockchainId,
  platformId,
  orderRisk
) => {
  return api.get(
    `lightdefi/list?assetId=${assetId}&blockchainId=${blockchainId}&platformId=${platformId}&orderRisk=${orderRisk}`
  );
};

export const ApiFilter = (assetId, blockchainId) => {
  return api.get(
    `defishield/defaults/get-all?assetId=${assetId}&blockchainId=${blockchainId}`
  );
};

export const ApiDeleteWalletsShield = (walletShieldId) => {
  return api.delete(`wallet/${walletShieldId}`);
};

export const ApiUpdateDefiShild = (defiShildId, params) => {
  return api.put(`defishield/${defiShildId}`, { ...params });
};

export const ApiGetWalletById = (walletId) => {
  return api.get(`wallet/${walletId}`);
};

export const ApiUpdateWallet = (
  walletId,
  network,
  wallet_type,
  wallet_service_type,
  name,
  addresses,
  params
) => {
  return api.put(
    `wallet/${walletId}`,
    network,
    wallet_type,
    wallet_service_type,
    name,
    addresses,
    params
  );
};

export const ApiGetPartnerGuardAppProduct = (searchValue) => {
  return api.get(`partnersguard/defaults/all?search=${searchValue}`);
};

export const ApiGetPartnerGuard = (orderRisk) => {
  return api.get("partnersguard/products", { params: { orderRisk } });
};

export const ApiCreatePartnerGuard = (id) => {
  return api.post(`partnersguard/product`, {
    partnersGuardId: id,
  });
};

export const ApiDeletePartnerGuardItem = (id) => {
  return api.delete(`partnersguard/product/${id}`);
};

export const ApiPartnerGuardAlertUpdate = (id, alert) => {
  return api.put(`wallet/${id}`, {
    alert,
  });
};

export const ApiWalletGuardAlertUpdate = (id, alert) => {
  return api.put(`partnersguard/${id}`, {
    alert,
  });
};

export const ApiPartnerGuardInvestmentRisk = () => {
  return api.get("partnersguard/investment/risks");
};

export const ApiUpdatePartnerGuardAlert = (alert, type) => {
  return api.put("partnersguard/investment/risks", {
    alert,
    type,
  });
};

export const ApiGetPartnerGuardGeneral = () => {
  return api.get("partnersguard/investment/risks/general");
};

const parseAssetsIds = (list) => {
  const addedAssets = {};
  if (Array.isArray(list) && list.length) {
    list.forEach((value, index) => (addedAssets[`assets[${index}]`] = value));
  }
  return addedAssets;
};

export const ApiGetListingRealVolume = (data) => {
  return api.get("listing-real-volume", {
    params: {
      ...(data && { search: data }),
    },
  });
};

export const ApiGetSelectedAssets = () => {
  return api.get("listing-assets-user-info");
};

export const AssetsSelect = (list) => {
  const assets = list.map(({ id, orderBy, selected }) => {
    return {
      id,
      // orderBy,
      selected,
    };
  });

  return api.put("listing-assets-user-info", {
    assets,
  });
};

export const ApiGetListingMarketCup = (data) => {
  return api.get("listing-market-cap", {
    params: {
      ...(data && { search: data }),
    },
  });
};

export const ApiGetListingHoldersConcentration = (data) => {
  return api.get("listing-holders-concentration", {
    params: {
      ...(data && { search: data }),
    },
  });
};
export const ApiGetListingMaturity = (data) => {
  return api.get("listing-maturity", {
    params: {
      ...(data && { search: data }),
    },
  });
};
export const ApiGetListingSecurity = (data) => {
  return api.get("listing-security", {
    params: {
      ...(data && { search: data }),
    },
  });
};
export const ApiGetListingGeneral = (data) => {
  return api.get("listing-general", {
    params: {
      ...(data && { search: data }),
    },
  });
};

export const ApiGetListingAssets = () => {
  return api.get("listing-assets");
};

export const ApiGetListingRealVolumeKeys = () => {
  return api.get("listing-real-volume/keys");
};

export const ApiPutListingRealVolumeKeys = (list) => {
  // const keys = list.map(({ id, orderBy, selected }) => ({
  //     id,
  //     selected,
  //     // orderBy
  // }))

  return api.put("listing-real-volume/keys", {
    keys: list,
  });
};

export const ApiPutListingMarketCapKeys = (list) => {
  // const keys = list.map(({ id, orderBy, selected }) => ({
  //   id,
  //   selected,
  //   // orderBy
  // }));

  return api.put("/listing-market-cap/keys", {
    keys: list,
  });
};

export const ApiGetListingMarketCapKeys = () => {
  return api.get("listing-market-cap/keys");
};

export const ApiGetListingHoldersConcentrationKeys = () => {
  return api.get("listing-holders-concentration/keys");
};

export const ApiGetListingHoldersConcentrationValue = () => {
  return api.get("listing-holders-concentration?search");
};
export const ApiPutHoldersConcentrationCapKeys = (list) => {
  const keys = list.map(({ id, orderBy, selected }) => ({
    id,
    selected,
    // orderBy
  }));

  return api.put("/listing-holders-concentration/keys", {
    keys,
  });
};
/*MATURITY api's */
export const ApiGetListingMaturityKeys = () => {
  return api.get("listing-maturity/keys");
};

export const ApiGetListingMaturityValue = () => {
  return api.get("listing-maturity?search");
};
export const ApiPutListingMaturityKeys = (list) => {
  const keys = list.map(({ id, orderBy, selected }) => ({
    id,
    selected,
    // orderBy
  }));

  return api.put("/listing-maturity/keys", {
    keys,
  });
};

/*Security api's */
export const ApiGetListingSecurityKeys = () => {
  return api.get("listing-security/keys");
};

export const ApiGetListingSecurityValue = () => {
  return api.get("listing-security?search");
};
export const ApiPutListingSecurityKeys = (list) => {
  const keys = list.map(({ id, orderBy, selected }) => ({
    id,
    selected,
    // orderBy
  }));

  return api.put("/listing-security/keys", {
    keys,
  });
};

/*General api's */
export const ApiGetListingGeneralKeys = () => {
  return api.get("listing-general/keys");
};

export const ApiGetListingGeneralValue = () => {
  return api.get("listing-general?search");
};
export const ApiPutListingGeneralKeys = (list) => {
  const keys = list.map(({ id, orderBy, selected }) => ({
    id,
    selected,
    // orderBy
  }));

  return api.put("/listing-general/keys", {
    keys,
  });
};
