export const DELETE_PARTNER_GUARD_SUCCESS ='DELETE_PARTNER_GUARD_SUCCESS';

const deletePaetnerGuardtate = {
    delete_partner_guard_success:false,
};

export function DeletePartnerGuardProductReducer(state =deletePaetnerGuardtate,action){

    if(action.type === DELETE_PARTNER_GUARD_SUCCESS){
        return {
            ...deletePaetnerGuardtate,
            delete_partner_guard_success:true,
        }
    }
    return state;
}