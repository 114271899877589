import { React, useEffect } from 'react';

import plus from '../../../../../../../Assets/img/icons/plus.png';
import check from '../../../../../../../Assets/img/icons/check__icon.png';
import close from '../../../../../../../Assets/img/icons/close.png';
import sms from '../../../../../../../Assets/img/icons/sms.png';

import * as yup from 'yup';
import { useFormik } from 'formik';

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';


export function SettingSMSInput({ value, active, onChange = () => { }, main, id, action, onDelete, placeholder, focus }) {
    yup.addMethod(yup.string, 'phoneValidator', function () {
        return this.test({
            name: 'name',
            message: 'Please enter a valid phone number.',
            test: (value = '') => {
                return isValidPhoneNumber(value);
            }
        })
    });
    const schema = yup.object().shape({
        value: yup.string().required('This is a required field.').phoneValidator()
    });
    const formik = useFormik({
        initialValues: {
            active: active,
            value: value
        },
        isInitialValid: false,
        validationSchema: schema,
        enableReinitialize: true
    });

    function callUpdateAction(values) {
        if (main || Object.keys(formik.errors).length) {
            return;
        }
        action(values ? values : formik.values);
    }

    useEffect(() => {
    }, [formik.errors])

    return (<>
        <div className={"alerts__input__wrapper " + (!main ? formik.values.active ? "active" : "disable" : '')}>
            <div className="alerts__input__icon__wrapper">
                <img width="20" height="23" src={sms} alt=""
                    className="alerts__input__icon" />
            </div>

            <PhoneInput id="value" name="value" type="text"
                className="alerts__input"
                defaultCountry="IL"
                onBlur={() => {
                    callUpdateAction()
                }}
                international
                placeholder={placeholder}
                value={formik.values.value}
                onChange={(e) => {
                    onChange({...formik.values, value: e});
                    formik.setFieldValue('value',e);
                }}
                rules={{ required: true }}
                onFocus={() => {
                    focus && focus('value')
                }}
            />
            <input type="checkbox" value={formik.values.active} checked={formik.values.active} className="alerts__check__input" name='active' id={`phone_setting_${id}`} onChange={(e) => {
                formik.handleChange(e);
                onChange({ ...formik.values, active: e.target.checked, valid: formik.errors });
                callUpdateAction({ active: e.target.checked });
            }} />
            <label htmlFor={`phone_setting_${id}`} className="alerts__input__check">
                <img src={check} width="12" height="12" alt=""
                    className="alerts__input__check__icon" />
            </label>
            {!main ? <img src={close} alt="" className="remove__input" onClick={() => onDelete()} /> : null}
            {formik.errors.value ? <span className='custom__error__message'>{formik.errors.value}</span> : null}
        </div>
        {main ?
            <div className="alerts__input__add" onClick={() => {
                if (!formik.isValid) {
                    return;
                }
                action(formik.values)?.then(() => {
                    formik.resetForm();
                });
            }}>
                <img src={plus} width="14" height="14" alt="plus" className="alerts__input__add__icon" />
            </div> : null}
    </>)
}