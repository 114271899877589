import { WALLET_SUCCESS } from '../Reducer/WalletsReducer';
import { ApiGetWallets } from '../Api';
import {ApiGetWalletNetworks} from '../Api';

export function WalletThunk() {
    return (dispatch, getState) => {
        ApiGetWallets().
            then((result) => {
                dispatch({
                    type: WALLET_SUCCESS,
                    paylod: {
                        walletResult: result.data.data,
                    }
                })
            })
            .catch((error) => {
            })
    }
};



