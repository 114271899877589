import { React } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import * as _ from 'lodash';


export function WhitelistForm({value, onChangeValue, index}) {
    const schema = yup.object().shape({
        value: yup.string(),
    });
    const formik = useFormik({
        initialValues: {
            value: value 
        },
        isInitialValid: false,
        validationSchema: schema,
        enableReinitialize: true
    });


    return (
        <>
            <div className="whitelist__ad__block__input__wrapper">
                <input type="text" className="whitelist__ad__block__input"   id="whiteList" name="whiteList" value={formik.values.value}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeValue(e.target.value, index)
                }} 
                />
            </div>
            </>
    )
}