import { React, useEffect, useState } from 'react';

import { GET_SETTINGS_SUCCESS } from '../../../../../Reducer/GetSettingsReducer';

import { useDispatch, useSelector } from 'react-redux';

import { ApiGetSetting } from '../.././../../../Api';
import { AccessPassword, WebHookForm, SettingsForm, EditWebHookForm } from './Components/index';
import { changeUrlSuccessState } from '../../../../../Reducer/EditWebhookUrlReducer';



export function DevelopersSettings() {

    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [editForm, setEditForm] = useState(false);


    const settingsResult = useSelector((state) => {
        return state.getSettings.settingsResult;
    });

    const web_hook_url_success = useSelector((state) => {
        return state.webHookUrl.web_hook_url_success;
    });


    useEffect(() => {
        ApiGetSetting()
            .then((result) => {
                dispatch({
                    type: GET_SETTINGS_SUCCESS,
                    payload: {
                        result: result.data.data || null
                    }
                });
                setShowPassword(false);
            })

            .catch((error) => {
            })
    }, []);

    useEffect(() => {
        if (web_hook_url_success) {
            ApiGetSetting()
                .then((result) => {
                    dispatch({
                        type: GET_SETTINGS_SUCCESS,
                        payload: {
                            result: result.data.data || null
                        }
                    });
                    setShowPassword(false);
                    setEditForm(false);
                })
                .catch((error) => {
                })
        }
    }, [web_hook_url_success]);

    useEffect(() => {
        if (!editForm && !showPassword) {
            dispatch(changeUrlSuccessState());
        }
    }, [editForm, showPassword]);



    return (
        <div className="login__settings" id="login__settings-3" >
            {
                !showPassword ?
                    <AccessPassword sendShowPassword={((event) => {
                        setShowPassword(event);
                        setEditForm(true)
                    })} /> : null
            }

            {
                showPassword && !settingsResult ?
                    <WebHookForm /> : null
            }
            {
                editForm && settingsResult ?
                    <EditWebHookForm showPassword={((event) => {
                        setShowPassword(event);
                        setEditForm(event);
                    })}
                    /> : null
            }

            {
                settingsResult ?
                    <SettingsForm /> : null
            }


        </div>
    )
}