import { React, useEffect, useState } from 'react';

import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import { CurrentDefiCard } from '../CurrentDefiCard';
import { WeekDefiCard } from '../WeekDefiCard';
import { useDispatch, useSelector } from 'react-redux';

import { INVESTMENTRISK_SUCCESS } from '../../Reducer/InvestmentRisksReducer';

import { ApiDefishieldInvestmentRisks, ApiUpdateDefishieldInvestmentRisks } from '../../Api';

import { LastMounthDefiCard } from '../LastMounthDefiCard';
import { EmptyCircularProgressBar } from '../EmptyCircularProgressBar';
import { DefiShildInvestmentRiskAlertThunk } from '../../Thunks/DefiShildInvestmentRiskAlertThunk';

let interval;

export function  AddDefiShild({ openModal }) {

    const dispatch = useDispatch();
    const [openProgress, setOpenProgress] = useState(false);
    const [alertvalue, setValue] = useState(0);


    const [tab, setTab] = useState(1);

    const investmentRiskResult = useSelector((state) => {
        return state.investmentRisk.investmentRiskResult;
    })

    const createDefiShildSuccess = useSelector((state) => {
        return state.creteDefiShild.create_defishild_success;
    });
    const investmentRiskAlertResult = useSelector((state) => {
        return state.defiShildInvestmentRiskAlert.investment_risk_alert_result;
    });

    const coldprops = {
        percent: Math.round(investmentRiskResult?.investmentRisk), // is require
        colorSlice: '#4DC7B5',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const riskprops = {
        percent: Math.round(investmentRiskResult?.investmentRisk), // is require
        colorSlice: '#EFB661',
        fontColor: '#EFB661',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const holdprops = {
        percent: Math.round(investmentRiskResult?.investmentRisk), // is require
        colorSlice: '#F27281',
        fontColor: '#F27281',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const whiteprops = {
        percent: 0, // is require
        colorSlice: '#f0f0f3',
        fontColor: '#f0f0f3',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }



    const renderClassName = (color) => {
        // eslint-disable-next-line default-case
        switch (color) {
            case 'green':
                return {
                    props: coldprops,
                    color: '#4DC7B5'
                };
            case 'yellow':
                return {
                    props: riskprops,
                    color: '#EFB661'
                };
            case 'red':
                return {
                    props: holdprops,
                    color: '#F27281'
                };
            case 'white':
                return {
                    props: whiteprops,
                    color: '#C4C4C4'
                };
        }
    }


    const handleChange = (value,e) => {
       // e.preventDefault()
        setValue(value)
    }

    useEffect(() => {
        if(interval){
            clearInterval(interval);
            }
            interval=setInterval(() => {
            ApiDefishieldInvestmentRisks()
                .then((result) => {
                    dispatch({
                        type: INVESTMENTRISK_SUCCESS,
                        payload: {
                            result: result.data.data
                        }
                    })
                })
                .catch((error) => {
                })

        }, 15000);

        return () =>{
            clearInterval(interval);
        }
    }, [])


    useEffect(() => {
        if (createDefiShildSuccess) {
            ApiDefishieldInvestmentRisks()
                .then((result) => {
                    dispatch({
                        type: INVESTMENTRISK_SUCCESS,
                        payload: {
                            result: result.data.data
                        }
                    })
                })
                .catch((error) => {
                })
        }
    }, [createDefiShildSuccess]);


    const alertOnChangeComplete = (event) => {
        ApiUpdateDefishieldInvestmentRisks(+alertvalue, 1)
            .then((result) => {
            })
            .catch((error) => {
            })
    };

    useEffect(() => {
        dispatch(DefiShildInvestmentRiskAlertThunk());
           ApiDefishieldInvestmentRisks()
            .then((result) => {
                dispatch({
                    type: INVESTMENTRISK_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => {
            })
    }, []);


    useEffect(() => {
        if (investmentRiskAlertResult) {
            investmentRiskAlertResult.map((element) => {
                if (element && element.type && element.type === 1) {
                    setValue(+element.alert)
                }
            });

        }
    }, [investmentRiskAlertResult])




    return (


        <aside className="aside">
            <a href="#" className="add__wallet__btn" onClick={(() => {
                openModal(true)
            })}>
                <span className="add__wallet__btn__plus">
                    <span className="add__wallet__btn__plus__bg">
                        <span></span><span></span>
                    </span>
                </span>
                Add Investment
            </a>
            <h3 className="aside__wallet__title">Portfolio Risk</h3>
            <div className="aside__chart__wrapper cold">
                {investmentRiskResult && investmentRiskResult?.investmentRiskTrustScore ?
                    <div className="card__risk__chart">
                        <CircularProgressBar {...renderClassName(investmentRiskResult?.investmentRiskTrustScore)?.props}>
                            <div className='round__shdow'></div>
                            <div className='card__risk__chart__min__wrapper_aside'>
                                <p className="card__risk__chart__number" style={{ color: renderClassName(investmentRiskResult?.investmentRiskTrustScore)?.color }}>{renderClassName(investmentRiskResult?.investmentRiskTrustScore)?.props.percent}</p>
                                <p className="card__risk__chart__number__of" style={{ color: renderClassName(investmentRiskResult?.investmentRiskTrustScore)?.color }}>of 100</p>
                            </div>
                        </CircularProgressBar>
                    </div> : <EmptyCircularProgressBar />
                }

                <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')} 
                onClick={(() => {
                    if (openProgress) {
                        alertOnChangeComplete()
                    }
                    setOpenProgress(!openProgress)
                })}>
                    <p className="card__risk__alert__title">Alert</p>
                    <p className={'card__risk__alert__number' + ' ' + (alertvalue === 0 ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{alertvalue === null || 0 ? 0 : alertvalue}</p>
                </div>
                {
                    openProgress ? 
                    <div className={'card__risk__range__hr active current__card' + ' ' + (alertvalue === 0 ? 'horizontal_slid_nit_value' : 'card__risk__range__hr active current__card')}>
                        <Slider
                            min={0}
                            max={100}
                            value={alertvalue === null || 0 ? 0 : alertvalue}
                            orientation='horizontal'
                            onChange={handleChange}
                            tooltip={false}
                             onChangeComplete={alertOnChangeComplete}
                        />
                    </div> : null
                }
            </div>
            <ul className="aside__tabs">
                <li className="aside__tabs__list" onClick={(() => {
                    setTab(1)
                })}>
                    <a href="#" className={'aside__tabs__link' + ' ' + (tab === 1 ? 'active' : 'aside__tabs__link')}>24 hours</a>
                </li>
                <li className="aside__tabs__list" onClick={(() => {
                    setTab(2)
                })}>
                    <a href="#" className={'aside__tabs__link' + ' ' + (tab === 2 ? 'active' : 'aside__tabs__link')}>7 days</a>
                </li>
                <li className="aside__tabs__list" onClick={(() => {
                    setTab(3)
                })}>
                    <a href="#" className={'aside__tabs__link' + ' ' + (tab === 3 ? 'active' : 'aside__tabs__link')} onClick={(() => { })}>1 month</a>

                </li>
            </ul>
            {
                tab === 1 ?
                    <CurrentDefiCard title={'defiShild'} element={investmentRiskResult} /> : null
            }
            {
                tab === 2 ?
                    <WeekDefiCard title={'defiShild'} element={investmentRiskResult} /> : null
            }
            {
                tab === 3 ?
                    <LastMounthDefiCard title={'defiShild'} element={investmentRiskResult} /> : null
            }
        </aside >
    )
}