import {ASSETS_SUCCESS} from '../Reducer/AssetsReducer';

import { ApiGetAssets } from '../Api';

export function AssetsThunks(blockchainId, platformId) {
    return (dispatch, getState) => {
        ApiGetAssets(blockchainId, platformId)
            .then((result) => {
                dispatch({
                    type: ASSETS_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => {
            })
    }
}