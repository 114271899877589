import {LIGHT_NTF_INVESTMENT_RISK} from '../Reducer/LightNTFInvestmentRiskReducer';
import {ApiLighNTFInvestmentRisk} from '../Api';

export function LightNTFInvestmentRiskThunk() {
    return (dispatch,getState)=>{
        ApiLighNTFInvestmentRisk()
        .then((result)=>{
            dispatch({
                type:LIGHT_NTF_INVESTMENT_RISK,
                payload:{
                    result:result.data.data
                }
            })
        })
    }
}