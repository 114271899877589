import { React, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import site from "../../../Assets/img/site/logo__login.svg";

import {
  TwoFAGenerateThunk,
  TwoFACodeVerificationThunk,
} from "../../../Thunks";
import { Loading } from "../../../Components";
import { VERIFICATIONT_IS_AUTH } from "../../../Reducer/TwoFACodeVerificationReducer";

import { ApiQRVerify } from "../../../Api";
import useInactivityTimeout from "../../../hooks";

export function QRVerify() {
  const [stateError, setStateError] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showqrImage, setshowqrImage] = useState(true);

  const [inputValue, setInputValue] = useState("");
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  let timer = null;

  const qrImage = useSelector((state) => {
    return state.twoFAGenerate.qrImage;
  });

  const loading = useSelector((state) => {
    return state.codeVerification.verification_loading;
  });

  const error = useSelector((state) => {
    return state.codeVerification.verification_error;
  });

  const verification_is_auth = useSelector((state) => {
    return state.codeVerification.verification_success;
  });

  const set_verification = useSelector((state) => {
    return state.login.set_verification;
  });

  useEffect(() => {
    if (set_verification === 0) {
      dispatch(TwoFAGenerateThunk());
    }
  }, [set_verification]);

  useEffect(() => {
    if (verification_is_auth) {
      dispatch({
        type: VERIFICATIONT_IS_AUTH,
      });

      navigate("/assetsinsight");
    }
  }, [verification_is_auth]);

  useEffect(() => {
    const element = document.getElementById("code");
    element.focus();
  }, []);

  const deleteErrorButton = (fieldKey) => {
    if (fieldKey) {
      setStateError(null);
    }
  };

  useEffect(() => {
    if (error) {
      setStateError(error);
    }
  }, [error]);

  useEffect(() => {
    ApiQRVerify()
      .then((result) => {
        setshowqrImage(result.data.data.qrScanned);
      })
      .catch((error) => {
        navigate("/auth/login");
      });
  }, []);

  const schema = yup.object().shape({
    code: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field."),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(TwoFACodeVerificationThunk(values));
    },
  });
  // useInactivityTimeout(() => {
  //   navigate("/auth/login");
  // }, 900000);
  const startTimer = () => {
    // Clear existing timer
    if (timer) clearTimeout(timer);

    // Set a new timer
    timer = setTimeout(() => {
      navigate("/auth/login");
    }, 15 * 60 * 1000); // 15 minutes
  };

  // Reset the timer on input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    startTimer(); // Reset timer on input change
  };

  // Start timer when component mounts
  useEffect(() => {
    startTimer();

    // Clean up timer on component unmount
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <form className="login__form" onSubmit={formik.handleSubmit}>
        <img src={site} alt="" className="login__logo" />
        <>
          {error ? (
            <span className="error__message requests__message">
              {stateError}
            </span>
          ) : null}
        </>
        <div style={{ textAlign: "center" }}>
          {!showqrImage ? (
            <img
              src={qrImage}
              className={
                "qr__logo" + " " + (qrImage ? "qr__logo" : "qr__logo__opacity")
              }
            />
          ) : null}
          <div className="login__container"></div>
          <div className="form__content">
            <div
              className={
                "login__input__wrapper" +
                " " +
                (formik.touched.code && formik.errors.code
                  ? "form__content__error"
                  : "login__input__wrapper")
              }
            >
              <input
                type="text"
                className="login__input no-cursor"
                placeholder="Please enter 2FA code"
                name="code"
                id="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                onFocus={() => deleteErrorButton("code")}
              />
            </div>
            {formik.touched.code && formik.errors.code ? (
              <span className="error__message">{formik.errors.code}</span>
            ) : null}
          </div>

          <button className="login__btn">
            Login {loading ? <Loading /> : null}
          </button>
        </div>
      </form>
    </>
  );
}
