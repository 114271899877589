import {INVESTMENT_RISK_ALERT_SUCCESS} from '../Reducer/DefiShildInvestmentRiskAlertReducer';

import {ApiGetInvestmentRisk} from '../Api';


export function DefiShildInvestmentRiskAlertThunk(){
    return (dispach,getState)=>{
        ApiGetInvestmentRisk()
        .then((result)=>{
            dispach({
                type:INVESTMENT_RISK_ALERT_SUCCESS,
                payload:{
                    result:result.data.data,
                }
            })
        })

    }
}