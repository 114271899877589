export const ZOOM_MAIN = 'zoom_main';
export const ZOOM_MAIN_CLOSE = 'zoom_main_close';

const zoomState ={
    zoom: +localStorage.getItem('zoom'),
}

export function ZoomMainReducer(state=zoomState,action){
    if(action.type === ZOOM_MAIN){
        localStorage.setItem('zoom', 1);
        return {
          
            ...state,
            zoom:1,
        }
    }
    else if(action.type === ZOOM_MAIN_CLOSE){
        localStorage.setItem('zoom', 0)
        return {
            ...state,
            zoom:0,
        }
    }

    return state;

}