import {UPDETE_LIGHT_DEFI_INVESTMENT_ALERT_SUCCESS} from '../Reducer/UpdateLightDefiInvestmentAlertReducer';
import {ApiUpdateLightDefiAlert} from '../Api';

export function UpdateLightDefiInvestmentAlertThunk(alert,type){
    return (dispatch,getState)=>{
        ApiUpdateLightDefiAlert(alert, type)
        .then((result)=>{
            return dispatch({
                type:UPDETE_LIGHT_DEFI_INVESTMENT_ALERT_SUCCESS
            })
        })
        .catch((error)=>{
        })
    
    }
}