import { React, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Main,
  WalletsShield,
  DeFiShield,
  Settings,
  LightDeFi,
  LightNFT,
  Trash,
  LightcoreActivate,
  PartnerGuard,
  Listing,
} from "../Pages/index";

import { OpenPageThunk } from "../Thunks/OpenPageThunk";

import { useSelector, useDispatch } from "react-redux";

export function MainRoutes() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => {
    return state.profile.profileResult;
  });
  // const { deFiShield, lightDeFi, lightNFT } = useSelector((state) => {
  //     return state.openPage;
  // });

  const walletShield = useSelector((state) => {
    return state.openPage.walletShield;
  });

  const lightDeFi = useSelector((state) => state.openPage.lightDeFi);

  const lightNFT = useSelector((state) => {
    return state.openPage.lightNFT;
  });

  const deFiShield = useSelector((state) => {
    return state.openPage.deFiShield;
  });

  const partnersGuard = useSelector((state) => {
    return state.openPage.partnersGuard;
  });

  const listingGuard = useSelector((state) => {
    return state.openPage.listeningGuard;
  });

  useEffect(() => {
    if (userData && userData.id) {
      const id = userData.id;
      dispatch(OpenPageThunk(id));
    }
  }, [userData]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Navigate to="/assetsinsight" />} />
          <Route
            path="/wallet-shield"
            element={
              walletShield ? (
                <WalletsShield />
              ) : (
                <Navigate to="/lightcore-activate" state={"Custody Shield"} />
              )
            }
          />
          <Route
            path="/defi-shield"
            element={
              deFiShield ? (
                <DeFiShield />
              ) : (
                <Navigate to="/lightcore-activate" state={"DeFi Shield"} />
              )
            }
          />
          <Route
            path="/light-defi"
            element={
              lightDeFi ? (
                <LightDeFi />
              ) : (
                <Navigate to="/lightcore-activate" state={"Light DeFi"} />
              )
            }
          />
          <Route
            path="/light-nft"
            element={
              lightNFT ? (
                <LightNFT />
              ) : (
                <Navigate to="/lightcore-activate" state={"Light NFT"} />
              )
            }
          />
          <Route
            path="/counterparty-risk"
            element={
              partnersGuard ? (
                <PartnerGuard />
              ) : (
                <Navigate
                  to="/lightcore-activate"
                  state={"Counterparty Risk"}
                />
              )
            }
          />
          <Route path="/assetsinsight" element={<Listing />} />
          <Route path="/recycle" element={<Trash />} />
          <Route path="/lightcore-activate" element={<LightcoreActivate />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="/assetsinsight" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
