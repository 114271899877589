import { React, useState } from 'react';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

export function NTFCurrentCard({ element }) {

    const [openProgress, setOpenProgress] = useState(false);
    const [value, setValue] = useState(0);
    

    const handleChange = (value) => {
        setValue(value)
    }

    return (
        <>
            {(() => {
                switch (element.value) {
                    case 'cold':
                        return <div className="aside__card cold">
                            <div className="row">
                                <div className="aside__card__left">
                                    <h3 className="aside__card__title">NFT Portfolio Value</h3>
                                    <p className="aside__card__numbers">80/<span>100</span></p>
                                </div>
                                
                                <div  className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')} onClick={(() => {
                                    setOpenProgress(!openProgress)
                                })}>
                                    <p className="card__risk__alert__title">Alert</p>
                                    <p className={'card__risk__alert__number' + ' ' + (value === 0 ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{value}</p>
                                </div>
                                {
                                    openProgress ? <div  className={'card__risk__range__hr active current__card' + ' ' + (value === 0 ? 'horizontal_slid_nit_value' : 'card__risk__range__hr active current__card')}>
                                        <Slider
                                            min={0}
                                            max={100}
                                            value={value}
                                            orientation='horizontal'
                                            onChange={handleChange}
                                            tooltip={false}
                                        />
                                    </div> : null
                                }
                            </div>
                            <div className="aside__card__progress">
                                <div className="aside__card__progress__bg" style={{ width: '93%' }}></div>
                            </div>
                        </div>
                    // case 'hot':
                    //     return <div className="aside__card hot">
                    //         <div className="row">
                    //             <div className="aside__card__left">
                    //                 <h3 className="aside__card__title ntf__security__title">DeFi Portfolio Security</h3>
                    //                 <p className="aside__card__numbers">65/<span>100</span></p>
                    //             </div>
                               
                    //             <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')} onClick={(() => {
                    //                 setOpenProgress(!openProgress)
                    //             })}>
                    //                 <p className="card__risk__alert__title">Alert</p>
                    //                 <p  className={'card__risk__alert__number' + ' ' + (value === 0 ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{value}</p>
                    //             </div>
                    //             {
                    //                 openProgress ? <div className={'card__risk__range__hr active current__card' + ' ' + (value === 0 ? 'horizontal_slid_nit_value' : 'card__risk__range__hr active current__card')}>
                    //                     <Slider
                    //                         min={0}
                    //                         max={100}
                    //                         value={value}
                    //                         orientation='horizontal'
                    //                         onChange={handleChange}
                    //                         tooltip={false}
                    //                     />
                    //                 </div> : null
                    //             }
                    //         </div>
                    //         <div className="aside__card__progress">
                    //             <div className="aside__card__progress__bg" style={{ width: '65%' }}></div>
                    //         </div>
                    //     </div>
                }
            })()}
        </>
    )
}