import { useEffect, useRef, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as EditIcon } from "../../Assets/img/icons/edit.svg";
import FilterAssets from "../../Assets/img/icons/filter_volums.svg";
import { ListingAssetsChange } from "../../Modal/ListingAssetsChange";
import {
  getListingMarketCapKeys,
  getListingRealVolumeKeys,
  getListingHoldersConcentrationKeyData,
  getListingMaturityKeyData,
  getListingSecurityKeyData,
  getListingGeneralKeyData,
} from "../../Reducer/ListeningReducer";
import { Tooltip } from "antd";

import {
  findLongestString,
  formatCurrency,
  numberWithComma,
  removeSpaces,
  stringToNumber,
} from "../../Utils/helper";
import _ from "lodash";
import {
  amlOptions,
  communityMediaOptions,
  foundersTeamOptions,
  lawsuitsOptions,
  options,
  reputationOptions,
  securityOptions,
} from "../../Utils/staticTexts";
import {
  AMLTooltip,
  communityMediaTooltip,
  foundersTeamTooltip,
  googleSearchTrendTooltip,
  lawsuitsTooltip,
  projectAdoptionRateTooltip,
  projectMaturityTooltip,
  projectSecurityTooltip,
  projectVolatilityTooltip,
  reputationTooltip,
  smartContractPlatformSecurityAudit,
} from "../../Utils/tooltipTexts";

export const ListingTable = ({ setIsModalOpen, setModalType, tableData }) => {
  const tableWrapperRef = useRef(null);
  const [searchParams] = useSearchParams();

  const realVolumeKeys = useSelector(getListingRealVolumeKeys);
  const marketCapKeys = useSelector(getListingMarketCapKeys);
  const listingHoldersConcentrationKeyData = useSelector(
    getListingHoldersConcentrationKeyData
  );
  const listingMaturityKeyData = useSelector(getListingMaturityKeyData);
  const listingSecurityKeyData = useSelector(getListingSecurityKeyData);
  const listingGeneralKeyData = useSelector(getListingGeneralKeyData);
  const [isAboveModalOpen, setIsAboveModalOpen] = useState(false);
  const [aboveField, setAboveField] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [style, setStyle] = useState({});
  const [arrow, setArrow] = useState("Show");
  const [editableAssetsItem, setEditableAssetsItem] = useState({});

  const [visible, setVisible] = useState(false);
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const openAssetModal = () => {
    setModalType("assets");
    setIsModalOpen(true);
  };

  function assetAboveValue(realVolumeKeysItem, item) {
    let currentVolume;
    if ("listingRealVolume" in item) {
      currentVolume = item?.listingRealVolume;
    } else if ("listingMarketCap" in item) {
      currentVolume = item?.listingMarketCap;
    }
    if (realVolumeKeysItem?.key === "volumeAbove") {
      if (
        Math.round(
          currentVolume["volumePast24h"] ? currentVolume["volumePast24h"] : 0
        ) > Math.round(realVolumeKeysItem?.above)
      ) {
        return <span className="listing_table_yes">Yes</span>;
      } else {
        return <span className="listing_table_no">No</span>;
      }
    } else if (realVolumeKeysItem?.key === "sufficientVolumeIn") {
      if (
        Math.round(
          currentVolume["reputableExchangesTotalVolume"]
            ? currentVolume["reputableExchangesTotalVolume"]
            : 0
        ) > Math.round(realVolumeKeysItem?.above)
      ) {
        return <span className="listing_table_yes">Yes</span>;
      } else {
        return <span className="listing_table_no">No</span>;
      }
    } else if (realVolumeKeysItem?.key === "currentVsHighAbove") {
      if (
        Math.round(
          currentVolume["currentVsHigh"] ? currentVolume["currentVsHigh"] : 0
        ) > Math.round(realVolumeKeysItem?.above)
      ) {
        return <span className="listing_table_yes">Yes</span>;
      } else {
        return <span className="listing_table_no">No</span>;
      }
    } else if (realVolumeKeysItem?.key === "circMarketCapAbove") {
      if (
        Math.round(
          currentVolume["circMarketCapMonthAgo"]
            ? currentVolume["circMarketCapMonthAgo"]
            : 0
        ) > Math.round(realVolumeKeysItem?.above)
      ) {
        return <span className="listing_table_yes">Yes</span>;
      } else {
        return <span className="listing_table_no">No</span>;
      }
    } else {
      if (realVolumeKeysItem?.key === "currentVsHigh") {
        return `${
          currentVolume[realVolumeKeysItem?.key]
            ? Number(currentVolume[realVolumeKeysItem?.key]).toFixed(2)
            : 0
        }%`;
      } else if (
        realVolumeKeysItem?.key === "currentPrice" ||
        realVolumeKeysItem?.key === "allTimeHighPrice"
      ) {
        return currentVolume[realVolumeKeysItem?.key] &&
          currentVolume[realVolumeKeysItem?.key] != "0"
          ? `${
              currentVolume[realVolumeKeysItem?.key]
                ? formatCurrency(currentVolume[realVolumeKeysItem?.key])
                : 0
            }`
          : null;
      } else if (currentVolume[realVolumeKeysItem?.key] === null) {
        return realVolumeKeysItem?.key == "allTimePeakVolume"
          ? " "
          : `Not Traded`;
      } else {
        return `$${
          currentVolume[realVolumeKeysItem?.key]
            ? numberWithComma(
                Math.round(currentVolume[realVolumeKeysItem?.key])
              )
            : 0
        }`;
      }
    }
  }

  function assetEditClick(item) {
    setEditableAssetsItem(item);
    setAboveField(item.key);
    setIsAboveModalOpen(true);
  }

  const getEntered = (assetKey, above) => {
    if (assetKey === "volumeAbove") {
      if (above) {
        return `$${numberWithComma(above)}`;
      } else {
        return "$0";
      }
    } else if (assetKey === "sufficientVolumeIn") {
      if (above) {
        return `$${numberWithComma(above)}`;
      } else {
        return "$0";
      }
    } else if (assetKey === "currentVsHighAbove") {
      if (above) {
        return `${numberWithComma(above)}%`;
      } else {
        return "0%";
      }
    } else if (assetKey === "circMarketCapAbove") {
      if (above) {
        return `$${numberWithComma(above)}`;
      } else {
        return "$0";
      }
    }
  };

  function createAssetsTableRow(item) {
    const mainOptions =
      item.key == "foundersOrTeam"
        ? foundersTeamOptions
        : item.key == "reputation"
        ? reputationOptions
        : item.key == "lawsuits"
        ? lawsuitsOptions
        : item.key == "aml"
        ? amlOptions
        : communityMediaOptions;

    return searchParams.get("tab") === "3" ? (
      <tr className="listing_table_row_wrapper hover">
        <td
          className="listing_table_datas headcol"
          style={{
            minWidth: `${
              findLongestString(listingHoldersConcentrationKeyData) * 15
            }px`,
            paddingRight: "64px",
          }}
        >
          <span> {item?.name} </span>
        </td>
        {tableData?.map((i) => {
          return (
            <td className="listing_table_datas">
              <span
                className={
                  item?.key === "currentVsHigh" ? "listing_table_yes" : ""
                }
              >
                {i.listingHoldersConcentration[item?.key] !== "0"
                  ? /^-|\d+(\.\d+)?$/.test(
                      i.listingHoldersConcentration[item?.key]
                    )
                    ? Number(i.listingHoldersConcentration[item?.key])
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : i.listingHoldersConcentration[item?.key]
                  : null}
              </span>
            </td>
          );
        })}
      </tr>
    ) : searchParams.get("tab") === "2" ? (
      <tr className="listing_table_row_wrapper hover">
        <td
          className="listing_table_datas headcol"
          style={{
            minWidth: `${findLongestString(marketCapKeys) * 14}px`,
            paddingRight: "64px",
          }}
        >
          <span>
            {item?.name}{" "}
            {item?.key === "circMarketCapAbove" && (
              <>
                <span className="listing_asset_edit_value">
                  {getEntered(item?.key, item?.above)}
                </span>
                <div
                  className="listing_asset_edit"
                  onClick={() => assetEditClick(item)}
                >
                  <EditIcon className="listing_asset_edit_icon" />
                </div>
              </>
            )}
          </span>
        </td>
        {tableData?.map((i) => {
          return (
            <td className="listing_table_datas">
              <span
                className={
                  item?.key === "currentVsHigh" ? "listing_table_yes" : ""
                }
              >
                {i && assetAboveValue(item, i)}
              </span>
            </td>
          );
        })}
      </tr>
    ) : searchParams.get("tab") === "5" ? (
      <>
        {item?.key === "projectSecurity" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingSecurityKeyData) * 12}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item?.name}</span>

              <Tooltip
                placement="rightTop"
                title={projectSecurityTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : item.key === "smartContractPlatformSecurityAudit" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingSecurityKeyData) * 12}px`,
                // paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item.name}</span>
              <Tooltip
                placement="rightTop"
                onOpenChange={(visible) => setVisible(visible)}
                title={smartContractPlatformSecurityAudit}
                arrow={mergedArrow}
                color={"#364048"}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : (
          ""
        )}
        <tr className="listing_table_row_wrapper hover">
          <td
            className="listing_table_datas headcol "
            style={{
              minWidth: `${findLongestString(listingSecurityKeyData) * 12}px`,
              paddingRight: "64px",
            }}
          >
            <span>{/* {item?.name}  */}</span>
          </td>
          {tableData?.map((i) => {
            const securityValue =
              i?.listingSecurity && i.listingSecurity[`${item?.key}Level`];
            const option = securityOptions[securityValue];
            return (
              <td
                className="listing_table_datas"
                style={{ width: 240, verticalAlign: "top" }}
              >
                <span
                  className={
                    item?.key === "currentVsHigh"
                      ? "listing_table_yes"
                      : item?.key === "projectMaturityParameter"
                      ? "listing_bolded_row"
                      : ""
                  }
                >
                  <p style={option?.style} className="listing_bolded_row">
                    {i.listingSecurity[`${item?.key}Level`]}
                  </p>
                  <p className="generalText">{i.listingSecurity[item?.key]}</p>
                </span>
              </td>
            );
          })}
        </tr>
      </>
    ) : searchParams.get("tab") === "6" ? (
      <>
        {item.key === "foundersOrTeam" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingGeneralKeyData) * 15}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item.name}</span>
              <Tooltip
                placement="rightTop"
                title={foundersTeamTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : item.key === "reputation" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingGeneralKeyData) * 15}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item.name}</span>
              <Tooltip
                placement="rightTop"
                title={reputationTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : item.key === "lawsuits" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingGeneralKeyData) * 15}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item.name}</span>
              <Tooltip
                placement="rightTop"
                title={lawsuitsTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : item.key === "aml" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingGeneralKeyData) * 15}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item.name}</span>
              <Tooltip
                placement="rightTop"
                title={AMLTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : item.key === "communityOrMedia" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingGeneralKeyData) * 15}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>{item.name}</span>
              <Tooltip
                placement="rightTop"
                title={communityMediaTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : (
          ""
        )}
        <tr className="listing_table_row_wrapper hover">
          <td
            className="listing_table_datas headcol "
            style={{
              minWidth: `${findLongestString(listingGeneralKeyData) * 15}px`,
              paddingRight: "64px",
            }}
          >
            <span>
              {" "}
              {(item?.key === "telegram" ||
                item?.key === "reddit" ||
                item?.key === "twitter" ||
                item?.key === "githubCommits" ||
                item?.key === "googleSearchTrend") &&
                item?.name}{" "}
            </span>
            {item.key === "googleSearchTrend" ? (
              <Tooltip
                placement="rightTop"
                title={googleSearchTrendTooltip}
                arrow={mergedArrow}
                color={"#364048"}
                onOpenChange={(visible) => setVisible(visible)}
              >
                <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
              </Tooltip>
            ) : (
              ""
            )}
          </td>
          {tableData?.map((i) => {
            const generalValue =
              i?.listingGeneral && i?.listingGeneral[`${item?.key}Level`];
            const option = mainOptions[removeSpaces(generalValue)];
            return (
              <td
                className="listing_table_datas"
                style={{ width: 240, verticalAlign: "top" }}
              >
                <span
                  className={
                    item.key === "currentVsHigh"
                      ? "listing_table_yes"
                      : item.key === "projectMaturityParameter"
                      ? "listing_bolded_row"
                      : ""
                  }
                >
                  <p style={option?.style} className="listing_bolded_row">
                    {i?.listingGeneral[`${item?.key}Level`]}
                  </p>
                  <p className="generalText">
                    {" "}
                    {i?.listingGeneral[item?.key] &&
                    /^\d+(\.\d+)?$/.test(i?.listingGeneral[item?.key])
                      ? i?.listingGeneral[item?.key] != "0"
                        ? Number(i?.listingGeneral[item?.key])
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : null
                      : i?.listingGeneral[item?.key]}
                    {/* {i.listingGeneral[item?.key]} */}
                  </p>
                </span>
              </td>
            );
          })}
        </tr>
      </>
    ) : searchParams.get("tab") === "4" ? (
      <>
        {item?.key === "sevenDayAverage" ? (
          <tr className="listing_table_row_wrapper highlighted_row">
            <td
              className="headcol"
              style={{
                minWidth: `${findLongestString(listingMaturityKeyData) * 12}px`,
                paddingRight: "64px",
                backgroundColor: "rgb(233 233 236)",
              }}
            >
              <span>Transactions Volume in USD </span>
            </td>
            {tableData?.map(() => {
              return <td className="listing_table_datas"></td>;
            })}
          </tr>
        ) : (
          ""
        )}
        <tr className="listing_table_row_wrapper hover">
          <td
            className="listing_table_datas headcol"
            style={{
              minWidth: `${findLongestString(listingMaturityKeyData) * 12}px`,
              paddingRight: "64px",
            }}
          >
            <span>
              {item?.name}{" "}
              {item?.key == "projectMaturityParameter" ? (
                <Tooltip
                  placement="rightTop"
                  title={projectMaturityTooltip}
                  arrow={mergedArrow}
                  color={"#364048"}
                  onOpenChange={(visible) => setVisible(visible)}
                >
                  <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
                </Tooltip>
              ) : item?.key == "newAdoptionRate" ? (
                <Tooltip
                  placement="rightTop"
                  title={projectAdoptionRateTooltip}
                  arrow={mergedArrow}
                  color={"#364048"}
                  onOpenChange={(visible) => setVisible(visible)}
                >
                  <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
                </Tooltip>
              ) : item?.key == "volatility" ? (
                <Tooltip
                  placement="rightTop"
                  title={projectVolatilityTooltip}
                  arrow={mergedArrow}
                  color={"#364048"}
                  onOpenChange={(visible) => setVisible(visible)}
                >
                  <i className="icon-Vector" style={{ paddingLeft: 10 }}></i>
                </Tooltip>
              ) : null}{" "}
            </span>
          </td>
          {tableData?.map((i) => {
            const maturityValue =
              i.listingMaturity && i.listingMaturity[item?.key]
                ? stringToNumber(i.listingMaturity[item?.key])
                : "";
            const isProjectMaturityParameter =
              item?.key === "projectMaturityParameter";
            const option = options[maturityValue];

            return (
              <td className="listing_table_datas">
                <span
                  className={
                    item?.key === "currentVsHigh"
                      ? "listing_table_yes"
                      : item?.key === "projectMaturityParameter"
                      ? "listing_bolded_row"
                      : ""
                  }
                  style={
                    isProjectMaturityParameter && option ? option.style : {}
                  }
                >
                  {maturityValue &&
                  (item?.key === "sevenDayAverage" ||
                    item?.key === "ninetyDayAverage")
                    ? i?.listingMaturity[item?.key] &&
                      i?.listingMaturity[item?.key] !== "0"
                      ? `$${Number(i.listingMaturity[item?.key])
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : null
                    : item.key === "averageNumberOfTransactionsPer7Days"
                    ? i?.listingMaturity[item?.key] &&
                      i?.listingMaturity[item?.key] !== "0"
                      ? `${Number(i.listingMaturity[item?.key])
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : null
                    : item?.key === "newAdoptionRate" && maturityValue
                    ? i?.listingMaturity[item?.key] &&
                      i?.listingMaturity[item?.key] !== "0"
                      ? `${(Number(i?.listingMaturity[item?.key]) * 100)
                          .toFixed(2)
                          .toString()}%`
                      : null
                    : item.key === "volatility"
                    ? i?.listingMaturity[item?.key] &&
                      i?.listingMaturity[item?.key] !== "0"
                      ? `${Number(i.listingMaturity[item?.key])
                          .toFixed(2)
                          .toString()}% `
                      : ""
                    : maturityValue}

                  {}
                </span>
              </td>
            );
          })}
        </tr>
      </>
    ) : (
      <tr className="listing_table_row_wrapper hover">
        <td
          className="listing_table_datas headcol"
          style={{
            minWidth: `${findLongestString(realVolumeKeys) * 12}px`,
            paddingRight: "44px",
          }}
        >
          <span>
            {" "}
            {item?.name}
            {(item?.key === "volumeAbove" ||
              item?.key === "sufficientVolumeIn" ||
              item?.key === "currentVsHighAbove") && (
              <>
                <span className="listing_asset_edit_value">
                  {getEntered(item?.key, item?.above)}
                </span>
                <div
                  className="listing_asset_edit"
                  onClick={() => assetEditClick(item)}
                >
                  <EditIcon className="listing_asset_edit_icon" />
                </div>
              </>
            )}
          </span>{" "}
        </td>

        {tableData?.map((i) => {
          return (
            <td className="listing_table_datas">
              <span
                className={
                  item?.key === "currentVsHigh" ? "listing_table_yes" : ""
                }
              >
                {i && assetAboveValue(item, i)}
              </span>
            </td>
          );
        })}
      </tr>
    );
  }

  const onMouseDown = (e) => {
    if (!visible) {
      setIsMouseDown(true);
      setStartX(e.pageX - tableWrapperRef.current.offsetLeft);
      setStartY(e.pageY - tableWrapperRef.current.offsetTop);
      setScrollLeft(tableWrapperRef.current.scrollLeft);
      setScrollTop(tableWrapperRef.current.scrollTop);
    }
  };

  const onMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    if (!visible) {
      const x = e.pageX - tableWrapperRef.current.offsetLeft;
      const y = e.pageY - tableWrapperRef.current.offsetTop;
      const walkX = (x - startX) * 2;
      const walkY = (y - startY) * 2;
      tableWrapperRef.current.scrollLeft = scrollLeft - walkX;
      tableWrapperRef.current.scrollTop = scrollTop - walkY;
    }
  };

  return (
    <>
      <div
        ref={tableWrapperRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={() => setIsMouseDown(false)}
        onMouseUp={() => setIsMouseDown(false)}
        className="listing_table_wrapper"
        style={{ overflowX: "auto" }}
      >
        <table className="headrow listing_table">
          <tr className="listing_table_row_wrapper">
            <th
              className="listing_table_titles headcol"
              style={{
                paddingLeft: 64,
                minWidth:
                  searchParams.get("tab") === "1"
                    ? `${findLongestString(realVolumeKeys) * 12}px`
                    : searchParams.get("tab") === "2"
                    ? `${findLongestString(marketCapKeys) * 14}px`
                    : searchParams.get("tab") === "3"
                    ? `${
                        findLongestString(listingHoldersConcentrationKeyData) *
                        15
                      }px`
                    : searchParams.get("tab") === "4"
                    ? `${findLongestString(listingMaturityKeyData) * 12}px`
                    : searchParams.get("tab") === "5"
                    ? `${findLongestString(listingSecurityKeyData) * 12}px`
                    : `${findLongestString(listingGeneralKeyData) * 15}px`,
              }}
            >
              <div
                className="assetRowWrapper"
                // style={{ minWidth: "490px", paddingLeft: 40 }}
              >
                ASSET
                <div
                  onClick={openAssetModal}
                  className="listing_filter_volums_wrapper"
                >
                  <img
                    src={FilterAssets}
                    alt="filter volums"
                    className="listing_filter_volums"
                  />
                </div>
              </div>
            </th>
            {searchParams.get("tab") === "1" &&
              tableData?.map(({ assetName, listingAssetsUserInfo }) => {
                if (listingAssetsUserInfo?.selected) {
                  return <th className="listing_table_titles ">{assetName}</th>;
                }
              })}
            {searchParams.get("tab") === "2" &&
              tableData?.map(({ assetName, listingAssetsUserInfo }) => {
                if (listingAssetsUserInfo?.selected) {
                  return <th className="listing_table_titles">{assetName}</th>;
                }
              })}
            {searchParams.get("tab") === "3" &&
              tableData?.map(({ assetName, listingAssetsUserInfo }) => {
                if (listingAssetsUserInfo?.selected) {
                  return <th className="listing_table_titles">{assetName}</th>;
                }
              })}
            {searchParams.get("tab") === "4" &&
              tableData?.map(({ assetName, listingAssetsUserInfo }) => {
                if (listingAssetsUserInfo?.selected) {
                  return <th className="listing_table_titles">{assetName}</th>;
                }
              })}
            {searchParams.get("tab") === "5" &&
              tableData &&
              tableData?.map(({ assetName, listingAssetsUserInfo }) => {
                if (listingAssetsUserInfo?.selected) {
                  return (
                    <th
                      className="listing_table_titles"
                      style={{ width: 240, verticalAlign: "top" }}
                    >
                      {assetName}
                    </th>
                  );
                }
              })}

            {searchParams.get("tab") === "6" &&
              tableData &&
              tableData?.map(({ assetName, listingAssetsUserInfo }) => {
                if (listingAssetsUserInfo?.selected) {
                  return (
                    <th
                      className="listing_table_titles"
                      style={{ width: 240, verticalAlign: "top" }}
                    >
                      {assetName}
                    </th>
                  );
                }
              })}
          </tr>
        </table>
        <table className="listing_table">
          {searchParams.get("tab") === "1" && (
            <>
              {realVolumeKeys?.map((item) => {
                if (item?.selected) {
                  return createAssetsTableRow(item);
                }
              })}
            </>
          )}
          {searchParams.get("tab") === "2" && (
            <>
              {marketCapKeys?.map((item) => {
                if (item?.selected) {
                  return createAssetsTableRow(item);
                }
              })}
            </>
          )}
          {searchParams.get("tab") === "3" && (
            <>
              {listingHoldersConcentrationKeyData?.map((item) => {
                if (item?.selected) {
                  return createAssetsTableRow(item);
                }
              })}
            </>
          )}
          {searchParams.get("tab") === "4" && (
            <>
              {listingMaturityKeyData?.map((item) => {
                if (item?.selected) {
                  return createAssetsTableRow(item);
                }
              })}
            </>
          )}
          {searchParams.get("tab") === "5" && (
            <>
              {listingSecurityKeyData?.map((item) => {
                if (item.selected) {
                  return createAssetsTableRow(item);
                }
              })}
            </>
          )}
          {searchParams.get("tab") === "6" && (
            <>
              {listingGeneralKeyData?.map((item) => {
                if (item.selected) {
                  return createAssetsTableRow(item);
                }
              })}
            </>
          )}
        </table>
      </div>
      {isAboveModalOpen && (
        <ListingAssetsChange
          editableAssetsItem={editableAssetsItem}
          isAboveModalOpen={isAboveModalOpen}
          setIsAboveModalOpen={setIsAboveModalOpen}
          aboveField={aboveField}
        />
      )}
    </>
  );
};
