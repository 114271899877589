import { React, useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";

import site from "../../../Assets/img/site/logo__login.svg";

import { ForgotPasswordThunk } from "../../../Thunks/ForgotPaswordThunk";

import { Loading } from "../../../Components/Loading";

import { RESTART_FORGOT_PASSWORD } from "../../../Reducer/ForgotPasswordReducer";

export function ForgotPassword() {
  const [stateError, setStateError] = useState();
  const [checkEmail, setCheckEmail] = useState(false);

  const forgot_password_loading = useSelector((state) => {
    return state.forgotPassword.forgot_password_loading;
  });

  const forgot_password_error = useSelector((state) => {
    return state.forgotPassword.forgot_password_error;
  });

  const forgot_passwsord_success = useSelector((state) => {
    return state.forgotPassword.forgot_password_success;
  });

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(/^[^-\s]/, "This field cannot contain only spaces.")
      .required("This is a required field.")
      .email("Please enter a valid email address."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(ForgotPasswordThunk(values));
    },
  });

  const deleteErrorButton = (fieldKey) => {
    if (fieldKey) {
      setStateError(null);
    }
  };

  useEffect(() => {
    if (forgot_password_error) {
      setStateError(forgot_password_error);
    }
  }, [forgot_password_error]);

  useEffect(() => {
    if (forgot_passwsord_success) {
      setCheckEmail(true);
    }
  }, [forgot_passwsord_success]);

  useEffect(() => {
    return () => {
      dispatch({
        type: RESTART_FORGOT_PASSWORD,
      });
    };
  }, []);

  return (
    <>
      {!checkEmail ? (
        <form className="login__form" onSubmit={formik.handleSubmit}>
          <img src={site} alt="" className="login__logo" />
          <>
            {forgot_password_error ? (
              <span className="error__message requests__message">
                {stateError}
              </span>
            ) : null}
            <div className="login__container"></div>
            <div className="form__content">
              <div
                className={
                  "login__input__wrapper" +
                  " " +
                  (formik.touched.email && formik.errors.email
                    ? "form__content__error"
                    : "login__input__wrapper")
                }
              >
                <input
                  type="text"
                  className="login__input"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onFocus={() => deleteErrorButton("email")}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <span className="error__message">{formik.errors.email}</span>
              ) : null}
            </div>
          </>
          <button className="login__btn">
            Reset Password{forgot_password_loading ? <Loading /> : null}
          </button>
        </form>
      ) : (
        <div className="login__form">
          <img src={site} alt="" className="login__logo" />
          <h1 className="purchase__form__title">Check your email</h1>
          <p className="purchase__form__title email__title">
            An email has been sent to{" "}
            <b style={{ color: "#214175" }}>{formik.values.email}</b> with
            instructions to reset your password.
          </p>
        </div>
      )}
    </>
  );
}
