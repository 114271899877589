export const PLATFORMS_SUCCESS = 'platforms_success';
export const SET_PLATFORM_DATA = 'set_platform_data';

const PlatformsState = {
    plarformsresult: [],
}
export function PlatformsReducer(state = PlatformsState, action) {

    if (action.type === PLATFORMS_SUCCESS) {
        const data = action.payload.result.map(item => {
            return {
                label: `${item.name}`,
                value: item.id,
                default: item.defaultValue,
                platformvalue:item.value,
            }
        })

        return {
            ...state,
            plarformsresult: data,
        }
    }
  else  if (action.type === SET_PLATFORM_DATA) {
        return {
            ...state,
            plarformsresult: action.payload.result,
        }
    }

    return state;
}