import {LIGHT_NTF_INVESTMENT_RISK_ALERT} from '../Reducer/LightnthInvestmentRiskAlertReducer';
import {ApiGetLighNTFfiGeneral} from '../Api';


export function LighNTFInvestmentRiskAlertThunk(){
    return (dispatch,getState) =>{
        ApiGetLighNTFfiGeneral()
        .then((result)=>{
            dispatch({
                type:LIGHT_NTF_INVESTMENT_RISK_ALERT,
                payload:{
                    result:result.data.data  
                }
            })
        })
    }
}