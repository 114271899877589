export const PLATFORM_CHART_STATISTIC_SUCCESS = 'PLATFORM_CHART_STATISTIC_SUCCESS';


const platformStatisticState = {
    platformStatisticResult: [],
};

export function PlatformChartStatisticReducer(state=platformStatisticState, action) {

    if (action.type === PLATFORM_CHART_STATISTIC_SUCCESS) {
        return {
            ...platformStatisticState,
            platformStatisticResult: action.payload.result,
        }
    }

    return state;
}