export const CREATE_PASSWORD_LOADING = 'create_password_loading';
export const CREATE_PASSWORD_ERROR = 'create_password_error';
export const CREATE_PASSWORD_SUCCESS = 'create_password_success';

const cretePasswordState = {
    create_password_loading: false,
    create_password_error: null,
    create_password_success: false,
}

export function CreatePasswordReducer(state = cretePasswordState, action) {
    if (action.type === CREATE_PASSWORD_LOADING) {
        return {
            ...cretePasswordState,
            create_password_loading: true,
        }

    }

    else if (action.type === CREATE_PASSWORD_ERROR) {
        return {
            ...cretePasswordState,
            create_password_loading: false,
            create_password_error: action.paylod.error,
        }
    }

    else if (action.type === CREATE_PASSWORD_SUCCESS) {
        {
            return {
                ...cretePasswordState,
                create_password_loading: false,
                create_password_error: null,
                create_password_success: true,
            }
        }
    }
    return state;
}