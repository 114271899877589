import { React, useState } from 'react';

import remove from '../../../../../Assets/img/icons/remove.png';

import OutsideClickHandler from 'react-outside-click-handler';

import { DeleteCardModule } from '../../../../../Modal/DeleteCard';

import { WalletAddressesComponent } from '../../../../../Components/WalletAddresses';

export function WalletsShieldTrash({ element, restoreItem, deleteItem }) {

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    return (<>
        <div className="card cold trash">
            <div className="card__header">
                <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="card__settings" >
                        <i className="icon-setting"

                        ></i>
                    </div>
                    <h2 className="card__title">
                        {
                            element?.name ?
                                <span className='heder_title'> {element?.name}</span> :
                                <span className="card__title__type" style={{ opacity: '0' }}>none</span>
                        }


                        {
                            element?.network ?
                                <span className="card__title__type">(
                                    {element?.network[0].toUpperCase() + element?.network.slice(1, element?.network.length).toLowerCase()})</span> :
                                <span className="card__title__type" style={{ opacity: '0' }}>none</span>
                        }
                    </h2>
                    <div className='close__card__content' onClick={(() => {
                        setOpenDeleteModal(true)
                    })}>
                        <span className="card__close" >
                            <span></span><span></span>
                        </span>
                    </div>

                </div>
            </div>
            <div className="card__price">

                <>    $ {element?.totalPrice ? new Intl.NumberFormat('en-US').format(Math.round(element?.totalPrice)) : 0}</>


            </div>
              <div className="card__wallet">
                    {
                        element?.addressesBalance?.map((element, index) => {
                            return <WalletAddressesComponent element={element} key={index + 1} />
                        })
                    }


                </div>
            <div className="card__risk">
                <h3 className="card__risk__title">Wallet Risk</h3>
                <div className="card__risk__alert">
                    <p className="card__risk__alert__title">Alert</p>
                    <p className="card__risk__alert__number">0</p>
                </div>
                <div className="card__risk__chart">
                    <div className="card__risk__chart__min">
                        <p className="card__risk__chart__number">100</p>
                        <p className="card__risk__chart__number__of">of 100</p>
                    </div>
                </div>
                <div className="card__risk__info">
                    <i className="icon-info-icon"></i>
                </div>
            </div>
            <div className="trash__wallet"
                onClick={(() => {
                    restoreItem(element?.trashId)
                })}
            >
                <img width="44" height="48" src={remove} alt="" className="trash__wallet__icon" />
            </div>
            <div className="trash__layer"></div>
        </div>
        <OutsideClickHandler onOutsideClick={(event) => {
            setOpenDeleteModal(false)
        }}>
            {
                openDeleteModal ?
                    <DeleteCardModule title="You are going to delete this product"
                        text="This product will be deleted and you will not be able to restore it."
                        element={element} deleteItem={(() => {
                            deleteItem(element.trashId)
                            setOpenDeleteModal(false)
                        })} cancel={(() => {
                            setOpenDeleteModal(false)
                        })} /> : ''
            }
        </OutsideClickHandler>
    </>
    )
}