import {DEFI_SHILES_SUCCESS,DEFISHILD_ERROR} from '../Reducer/DeFiShieldReducer';

import {ApiDeFiShield} from '../Api';



export function DeFiShieldThunk(assetId,blockchainId,platformId,orderRisk){
    return (dispatch,getState) =>{
        ApiDeFiShield(assetId,blockchainId,platformId,orderRisk)
        .then((result)=>{
            dispatch({
                type:DEFI_SHILES_SUCCESS,
                payload:{
                    result:result?.data?.data,
                }
            })
        })
        .catch((error)=>{
          dispatch({
            type:DEFISHILD_ERROR
          })
        })
    }
}