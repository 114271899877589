export const CHANGE_PASSWORD_LOADING = 'change_password_loading';
export const CHANGE_PASSWORD_ERROR = 'change_password_error';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const RESTART_CHANGE_PASSWORD = 'restart_change_password';

const changePasswordState = {
    password_loading: false,
    password_error: null,
    password_success: false,
}

export function ChangePasswordReducer(state=changePasswordState, action) {
    if (action.type === CHANGE_PASSWORD_LOADING) {
        return {
            ...changePasswordState,
            password_loading: true,
        }
    }

    else if (action.type === CHANGE_PASSWORD_ERROR) {
        return {
            ...changePasswordState,
            password_loading: false,
            password_error: action.payload.error,
        }
    }
    else if (action.type === CHANGE_PASSWORD_SUCCESS) {
        return {
            ...changePasswordState,
            password_loading: false,
            password_error: null,
            password_success: true,
        }
    }

    else if(action.type === RESTART_CHANGE_PASSWORD){
        return {
            ...changePasswordState,
            password_loading: false,
            password_error: null,
            password_success: false,

        }
    }

    return state;
}