import { React, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Header } from "../../../Components/index";

import to_top from "../../../Assets/img/icons/to__top.svg";
import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";

import { TrashThunk } from "../../../Thunks/TrashThunk";

import { TrashCard } from "./Component/index";

import { ApiTrashRestore, ApiDeleteTrash } from "../../../Api";

import { DeleteCardModule } from "../../../Modal/DeleteCard";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";
import trash from "../../../Assets/img/icons/trash.svg";
import { LogoLoading } from "../../../Components/index";

import { TRUSH_FETCHED } from "../../../Reducer/TrashReducer";

export function Trash() {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showRestorError, setShowRestorError] = useState(false);
  const [restoreError, setRestoreError] = useState(null);

  const [loading, setLoading] = useState(false);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const trashResult = useSelector((state) => {
    return state.trush.trashResult;
  });

  const isTrushFetched = useSelector((store) => store.trush.isTrushFetched);

  useEffect(() => {
    dispatch(TrashThunk());
  }, []);

  useEffect(() => {
    document.getElementById("scrollContent").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  useEffect(() => {
    if (trashResult?.length === 0 && !modalIsOpen) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [trashResult.length, modalIsOpen]);

  useEffect(() => {
    dispatch({
      type: TRUSH_FETCHED,
    });
  }, []);

  return (
    <div className="empty__trash_container">
      <Header title={"Recycle"} />
      {!trashResult.length && isTrushFetched ? (
        <main
          className={
            "main empty__trash" +
            " " +
            (openMenu ? "open" : "main empty__trash")
          }
        >
          <h3 className="empty__trash__title">Empty Recycle</h3>
        </main>
      ) : null}
      <main className={"main" + " " + (openMenu ? "open" : "main")}>
        <div className="content" id="scrollContent">
          {trashResult.length ? (
            <button
              className={
                "card__product__unstacked trash__clean" +
                " " +
                (openMenu
                  ? "open__trush"
                  : "card__product__unstacked trash__clean")
              }
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              {/* <span className="trash__icon__wrapper">
                                    <img width="25" height="25" src={trash} className="trash__icon" alt="" />
                                    <span className="trash__text">Trash</span>
                                </span> */}
              <span className="trash__text__all">Clean All</span>
            </button>
          ) : null}
          <div className="row">
            {trashResult.map((element) => {
              return (
                <TrashCard
                  key={element.id}
                  element={element}
                  resToreTrashItem={(event) => {
                    ApiTrashRestore(event)
                      .then((result) => {
                        dispatch(TrashThunk());
                      })
                      .catch((error) => {
                        setRestoreError(error?.response?.data?.error?.message);
                        setTimeout(() => {
                          setShowRestorError(true);
                        });
                        setTimeout(() => {
                          setShowRestorError(false);
                        }, 3000);
                      });
                  }}
                  deleteTrashItem={(event) => {
                    ApiDeleteTrash(event)
                      .then((result) => {
                        dispatch(TrashThunk());
                      })
                      .catch((error) => {
                        alert(error?.response?.data?.error?.message);
                      });
                  }}
                />
              );
            })}
          </div>

          {loading ? <LogoLoading /> : null}
          <div className="recycle__layout">
            <div className="zoom__page__btn trash__zoom recucle__zoom_btn">
              <img
                src={zoom_pluse}
                width="24"
                height="24"
                alt=""
                className="zoom__page__btn__plus"
                onClick={zoomClose}
              />
              <img
                src={zoom_minus}
                width="24"
                height="24"
                alt=""
                className="zoom__page__btn__minus"
                onClick={zoom}
              />
            </div>
          </div>

          {showTopBtn ? (
            <a
              className="to__top recycle_top"
              onClick={() => {
                document.getElementById("scrollContent").scrollTo(0, 0);
              }}
            >
              <img src={to_top} alt="top" className="to__top__icon" />
            </a>
          ) : null}
        </div>

        {showRestorError ? (
          <div className="trash__error__modal">
            <p className="trash__error__modal__title">{restoreError}</p>
          </div>
        ) : null}

        {openDeleteModal ? (
          <div>
            <DeleteCardModule
              title="You are going to delete all products"
              text="All products will be deleted and you will not be able to restore them."
              deleteItem={() => {
                ApiDeleteTrash(0)
                  .then((result) => {
                    dispatch(TrashThunk());
                    setOpenDeleteModal(false);
                  })
                  .catch((error) => {
                    setOpenDeleteModal(false);
                    alert(error?.response?.data?.error?.message);
                  });
              }}
              cancel={() => {
                setOpenDeleteModal(false);
              }}
            />
          </div>
        ) : null}
      </main>
    </div>
  );
}
