export const GET_LIGHT_DEFI = 'GET_LIGHT_DEFI';
export const LIGHT_DEFI_FETCHED ='LIGHT_DEFI_FETCHED';

const lightDefiState = {
    lightDefiResult: [],
    islightDefiFetched: false,
}

export function GetLightDefiReducer(state = lightDefiState, action) {
    if (action.type === GET_LIGHT_DEFI) {
        return {
            ...lightDefiState,
            lightDefiResult: action.payload.result,
            islightDefiFetched: action.payload.result.length ? false : true,
        }
    }

    else if(action.type ===  LIGHT_DEFI_FETCHED){
        return {
            ...state,
            lightDefiResult: [],
            islightDefiFetched:false,  
        }
    }

    return state;
}