import { React,useState,useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import copy from '../../../../../../../Assets/img/icons/copy.svg';



export function SettingsForm() {

    const webhookUrlRef = useRef(null);
    const clientIdRef = useRef(null);
    const client_secretRef = useRef(null);

    const settingsResult = useSelector((state) => {
        return state.getSettings.settingsResult;
    });




    const schema = yup.object().shape({
         enableReinitialize: true,
        webhookUrl: yup.string(),
        clientId: yup.string(),
        client_secret: yup.string(),

    })

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            webhookUrl: settingsResult.webhookUrl || '',
            clientId: settingsResult.clientId || '',
            client_secret: settingsResult.clientSecret || ''
        },
        validationSchema: schema,
        onSubmit: values => {
        }
    });

    return <>
    
        <form className="password__change__block" onSubmit={formik.handleSubmit}>
            <h4 className="password__change__block__title">Webhook URL</h4>
            <label htmlFor="webhookUrl" className="password__change__label password__change__input">
                <span className="password__change__label__text">Webhook Url</span>
                <input id="webhookUrl" name="webhookUrl" placeholder="Webhook Url" type="text" className="password__input"
                    value={formik.values.webhookUrl}
                    onChange={formik.handleChange}
                    ref={webhookUrlRef}
                />
                <div className="dropdown__copy__icon__wrapper" onClick={(()=>{
                   webhookUrlRef.current.select();
                   document.execCommand('copy');  
                })}>
                    <img src={copy} alt="" className="dropdown__copy__icon" />
                </div>
            </label>
            <label htmlFor="clientId" className="password__change__label password__change__input">
                <span className="password__change__label__text">Client Id</span>
                <input id="clientId" name="clientId" placeholder="Client Id" type="text" className="password__input"
                    value={formik.values.clientId}
                    onChange={formik.handleChange}
                    ref={clientIdRef}
                />
                 <div className="dropdown__copy__icon__wrapper"  onClick={(()=>{
                   clientIdRef.current.select();
                   document.execCommand('copy');  
                })}>
                    <img src={copy} alt="" className="dropdown__copy__icon" />
                </div>
            </label>
            <label htmlFor="client_secret" className="password__change__label password__change__input">
                <span className="password__change__label__text">Client Secret</span>
                <input id="client_secret" name="client_secret" placeholder="Client Secret" type="text" className="password__input"
                    value={formik.values.client_secret}
                    onChange={formik.handleChange}
                    ref={client_secretRef}
                />
                 <div className="dropdown__copy__icon__wrapper"  onClick={(()=>{
                   client_secretRef.current.select();
                   document.execCommand('copy');  
                })}>
                    <img src={copy} alt="" className="dropdown__copy__icon" />
                </div>
            </label>
            
        </form>
    </>
}