import {UPDETE_PARTNER_GUARD_INVESTMENT_ALERT_SUCCESS} from '../Reducer/UpdatePartnerGuardInvestmentAlertReducer';
import {ApiUpdatePartnerGuardAlert} from '../Api';

export function UpdatePartnerGuardInvestmentAlertThunk(alert,type){
    return (dispatch,getState)=>{
        ApiUpdatePartnerGuardAlert(alert, type)
        .then((result)=>{
            return dispatch({
                type:UPDETE_PARTNER_GUARD_INVESTMENT_ALERT_SUCCESS
            })
        })
        .catch((error)=>{
        })
    
    }
}