import { React, useEffect, useState } from 'react';

import plus from '../../../../../../../Assets/img/icons/plus.png';
import telegram from '../../../../../../../Assets/img/icons/telegram.png';
import slack from '../../../../../../../Assets/img/icons/slack.png';
import whatsap from '../../../../../../../Assets/img/icons/whatsap.png';
import check from '../../../../../../../Assets/img/icons/check__icon.png';
import close from '../../../../../../../Assets/img/icons/close.png';

import { NOTIFICATION_SETTINGS_TYPES } from '../../../../../../../Constants/NotificationSettingTypes';

import * as yup from 'yup';
import * as _ from 'lodash';
import { useFormik } from 'formik';

export function SettingSocialInput({ value, active, type, onChange = () => { }, id = Math.random(), main, action, onDelete, placeholder,focus }) {
    const socials = [
        {
            type: NOTIFICATION_SETTINGS_TYPES.TELEGRAM,
            icon: telegram
        },
        {
            type: NOTIFICATION_SETTINGS_TYPES.SLACK,
            icon: slack
        },
        {
            type: NOTIFICATION_SETTINGS_TYPES.WHATSAPP,
            icon: whatsap
        },
    ];

    const [selectedSocial, setSelectedSocial] = useState();

    const schema = yup.object().shape({
        value:yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.')
    });
    const formik = useFormik({
        initialValues: {
            active,
            value
        },
        isInitialValid: false,
        validationSchema: schema,
    });

    function callUpdateAction(values) {
        if (main || Object.keys(formik.errors).length) {
            return;
        }
        action(values ? values : formik.values);
    }

    useEffect(() => {
    }, [formik.errors])

    useEffect(() => {
        setSelectedSocial(socials.find((e) => e.type === type));
    }, []);


    return (
        <>
            <div 
            className={"alerts__input__wrapper " + (main && !selectedSocial ? "links" : "") + (selectedSocial && main ? "one" : "") + (!main ? formik.values.active ? "active" : "disable" : '')}>
                <div className="alerts__input__icon__wrapper">
                    {
                        socials.filter((e) => {
                            if (main) {
                                if (selectedSocial) {
                                    return e.type === selectedSocial.type;
                                }
                                return true;
                            }
                            return e.type === type;
                        }).map((e) => {
                            return <img width="32" height="32" src={e.icon} alt=""
                                className="alerts__input__icon" onClick={() => {
                                    if (!main) {
                                        return;
                                    }
                                    setSelectedSocial(selectedSocial ? null : e);
                                }} />
                        })
                    }
                </div>
                <input placeholder={placeholder} name="value" value={formik.values.value} type="text"
                    className="alerts__input" onChange={(e) => {
                        formik.handleChange(e);
                        onChange({ ...formik.values });
                    }} onBlur={() => {
                        callUpdateAction();
                    }} 
                    onFocus={() => {
                        focus && focus('value') 
                    }}
                    
                    />
                <input type="checkbox" name="active" className="alerts__check__input" id={`social_setting_${id}`} value={formik.values.active}
                    checked={formik.values.active} onChange={(e) => {
                        formik.handleChange(e);
                        onChange({ ...formik.values,valid:formik.errors });
                        callUpdateAction({ active: e.target.checked });
                    }} />

                <label htmlFor={`social_setting_${id}`} className="alerts__input__check">
                    <img src={check} width="12" height="12" alt=""
                        className="alerts__input__check__icon" />
                </label>
                {!main ? <img src={close} alt="" className="remove__input" onClick={() => onDelete()} /> : null}
                {formik.errors.value ? <span className='custom__error__message'>{formik.errors.value}</span> : null}
            </div>

            {main ?
                <div className="alerts__input__add"
                    onClick={() => {
                        if (!formik.isValid) {
                            return;
                        }
                        action({ ...formik.values, type: selectedSocial.type }).then(() => {
                            setSelectedSocial(null);
                            formik.resetForm();
                        });
                    }}
                >
                    <img src={plus} width="14" height="14" alt="plus" className="alerts__input__add__icon" />
                </div> : null}
        </>
    )
}