export const TWO_FA_GENERATE_SUCCESS = 'two_fa_generate_success';

const twoFAGenerateState = {
    qrImage: null,
}

export function TwoFAGenerateReducer(state = twoFAGenerateState, action) {
    if (action.type === TWO_FA_GENERATE_SUCCESS) {
        return {
            ...state,
            qrImage: action.payload.qrImage,
        }
    }
    return state;
}
