import { React } from 'react';

export function WalletAddressesComponent({ element }) {
    return (
        <>
            <div className="card__wallet__row">
                <div className="card__wallet__rates cart__walet__text__title"><span>{+element?.balance ? new Intl.NumberFormat('en-US').format((+element?.balance).toFixed(2)) : 0}  </span>
                <span>{element?.account}</span>
                </div>
                <div className="card__wallet__usd cart__walet__text__title">$ {element?.usdPrice ? new Intl.NumberFormat('en-US').format(Math.round(element?.usdPrice)) : 0}</div>
            </div>
        </>
    )
}



