import { CREATE_DEFISHILD_ERROR, CREATE_DEFISHILD_LOADING, CREATE_DEFISHILD_SUCCESS } from '../Reducer/CreateDefishieldReducer';
import { ApiCreateDefishield } from '../Api';


export function CreateDefishieldThunk(assetId, blockchainId, platformId) {
    return (dispatch, getState) => {
        dispatch({
            type: CREATE_DEFISHILD_LOADING
        })
        ApiCreateDefishield(assetId, blockchainId, platformId)
            .then((result) => {
                dispatch({
                    type: CREATE_DEFISHILD_SUCCESS,
                })
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_DEFISHILD_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
    }
}