import { React, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Header,
  LightDefiCard,
  DeFiLightAside,
  AllLightDeFiCard,
} from "../../../Components";

import { ZOOM_MAIN, ZOOM_MAIN_CLOSE } from "../../../Reducer/ZoomMainReducer";
import { GetLightDefiThunk } from "../../../Thunks/GetLightDefiThunk";

import { AddLightDefiModal } from "../../../Modal/index";

import top from "../../../Assets/img/icons/to__top.svg";

import zoom_pluse from "../../../Assets/img/icons/zoom_plus.png";
import zoom_minus from "../../../Assets/img/icons/zoom_minus.png";

import grid from "../../../Assets/img/icons/grid.svg";
import list from "../../../Assets/img/icons/list.svg";
import no_active from "../../../Assets/img/icons/a.svg";
import active_list from "../../../Assets/img/icons/active_list.svg";
import filter from "../../../Assets/img/icons/filter.svg";

import { FilterComponent } from "../../../Components/Filter";
import { NoFilterResultModal } from "../../../Modal/NoFilterResult";

import { LogoLoading } from "../../../Components/index";

import { LIGHT_DEFI_FETCHED } from "../../../Reducer/GetLightDefiReducer";

let interval;

export function LightDeFi() {
  let searchData = {};

  const dispatch = useDispatch();
  const [tab, showTab] = useState(1);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showFilterMessage, setShowFilterMessage] = useState(false);

  const [loading, setLoading] = useState(false);

  const openMenu = useSelector((state) => {
    return state.menu.open;
  });

  const lightDefiResult = useSelector((state) => {
    return state.GetLightDefi.lightDefiResult;
  });

  const isLightDefiFetched = useSelector(
    (store) => store.GetLightDefi.islightDefiFetched
  );

  const createLightDefiSuccess = useSelector((state) => {
    return state.creteLightDefiReduicer.create_lightdefi_success;
  });

  useEffect(() => {
    if (isLightDefiFetched) {
      if (lightDefiResult?.length === 0 || modalIsOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [isLightDefiFetched, lightDefiResult.length, modalIsOpen]);

  useEffect(() => {
    if (lightDefiResult?.length === 0 && !modalIsOpen) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [lightDefiResult.length, modalIsOpen]);

  useEffect(() => {
    dispatch({
      type: LIGHT_DEFI_FETCHED,
    });
  }, []);

  const zoom = () => {
    dispatch({
      type: ZOOM_MAIN,
    });
  };

  const zoomClose = () => {
    dispatch({
      type: ZOOM_MAIN_CLOSE,
    });
  };

  useEffect(() => {
    document.getElementById("scrollContent").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    searchData = {};
    const search = searchParams.get("q");
    searchData = JSON.parse(search || "{}");
    if (interval) {
      clearInterval(interval);
    }
    loadLighDefiList();
    interval = setInterval(() => {
      loadLighDefiList(false);
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [searchParams.toString()]);

  useEffect(() => {
    navigate("/light-defi");
  }, []);

  useEffect(() => {
    if (createLightDefiSuccess) {
      setSearchParams(JSON.parse("{}"));
      loadLighDefiList();
    }
  }, [createLightDefiSuccess]);

  useEffect(() => {
    searchData = {};
    const search = searchParams.get("q");
    searchData = JSON.parse(search || "{}");

    if (search && lightDefiResult.length < 1) {
      setShowFilterMessage(true);
    } else {
      setShowFilterMessage(false);
    }
  }, [searchParams, lightDefiResult]);

  const loadLighDefiList = () => {
    const params = { ...searchData };
    dispatch(
      GetLightDefiThunk(
        params?.assets || "",
        params.blockchains || "",
        params.platforms || "",
        params.orderRisk
      )
    );
  };

  return (
    <>
      <Header title={"Light DeFi"} />
      <main className={"main" + " " + (openMenu ? "open" : "main")}>
        <div className="content" id="scrollContent">
          <div className="row">
            {modalIsOpen ? (
              <AddLightDefiModal
                modalIsOpen={modalIsOpen}
                title={"LightDefi"}
                modalClose={() => {
                  setIsOpen(false);
                }}
              />
            ) : (
              ""
            )}
            {tab === 1 ? (
              <>
                {lightDefiResult?.length ? (
                  <>
                    {lightDefiResult?.map((element) => {
                      return (
                        <LightDefiCard
                          key={element?.id}
                          element={element}
                          reloadListCallback={() => {
                            loadLighDefiList();
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="content_list">
                <div className="row">
                  {lightDefiResult?.map((element) => {
                    return (
                      <AllLightDeFiCard key={element?.id} element={element} />
                    );
                  })}
                </div>
              </div>
            )}

            <div className="layout__block">
              <img
                src={filter}
                alt=""
                className="filter__icon"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              />
              <div
                className="layout__grid"
                onClick={() => {
                  showTab(1);
                }}
              >
                {tab === 1 ? (
                  <img src={grid} alt="" className="layout__grid__icon" />
                ) : (
                  <img src={no_active} alt="" className="layout__grid__icon" />
                )}
              </div>

              <FilterComponent
                activeclassName={showFilter ? "open" : "close"}
                urlpath={"/light-defi"}
              />

              <div
                className="layout__list"
                onClick={() => {
                  showTab(2);
                }}
              >
                {tab === 2 ? (
                  <img
                    src={active_list}
                    alt=""
                    className="layout__list__icon"
                  />
                ) : (
                  <img src={list} alt="" className="layout__list__icon" />
                )}
              </div>
              <div className="zoom__page__btn">
                <img
                  src={zoom_pluse}
                  width="24"
                  height="24"
                  alt=""
                  className="zoom__page__btn__plus"
                  onClick={zoomClose}
                />
                <img
                  src={zoom_minus}
                  width="24"
                  height="24"
                  alt=""
                  className="zoom__page__btn__minus"
                  onClick={zoom}
                />
              </div>
            </div>
          </div>

          {loading ? <LogoLoading /> : null}

          {showFilterMessage ? (
            <NoFilterResultModal title={"Light DeFi"} />
          ) : null}

          {showTopBtn ? (
            <a
              className="to__top"
              onClick={() => {
                document.getElementById("scrollContent").scrollTo(0, 0);
              }}
            >
              <img src={top} alt="top" className="to__top__icon" />
            </a>
          ) : (
            ""
          )}
        </div>
        <DeFiLightAside
          openModal={(event) => {
            setIsOpen(event);
          }}
        />
      </main>
    </>
  );
}
