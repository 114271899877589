import { React, useState } from 'react';



import { DefiShildTrashComponent, LightNFTTrash, WalletsShieldTrash, LightDeFiTrashComponent,PartnerGuardTrash } from '../index';


export function TrashCard({ element, resToreTrashItem, deleteTrashItem }) {
    return (
        <>
            <div className="row">
                {
                    element && element.defiShield ?
                        <DefiShildTrashComponent element={element} restoreItem={((event) => {
                            resToreTrashItem(event)
                        })} deleteItem={((event) => {
                            deleteTrashItem(event)
                        })} /> : null

                }

                {
                    element && element.lightDeFi ?
                        <LightDeFiTrashComponent element={element} restoreItem={((event) => {
                            resToreTrashItem(event)
                        })} deleteItem={((event) => {
                            deleteTrashItem(event)
                        })} /> : null
                }
                {element && element.lightNFTProduct ?
                    <LightNFTTrash element={element}
                        restoreItem={((event) => {
                            resToreTrashItem(event)
                        })} deleteItem={((event) => {
                            deleteTrashItem(event)
                        })} /> : null

                }


                {
                    element && element.wallet_service_type || element.wallet_type?
                        <WalletsShieldTrash element={element} 
                        restoreItem={((event) => {
                            resToreTrashItem(event)
                        })} deleteItem={((event) => {
                            deleteTrashItem(event)
                        })} /> : null
                }
                {
                    element && element?.partnersGuardProduct ?
                    <PartnerGuardTrash element={element}
                    restoreItem={((event) => {
                        resToreTrashItem(event)
                    })} deleteItem={((event) => {
                        deleteTrashItem(event)
                    })} /> : null

                }

            </div>




        </>
    )
}