export const WEBHOOK_URL_SUCCESS = 'web_hook_url_success';
export const WEBHOOK_URL_ERROR = 'web_hook_url_error';
export const WEBHOOK_URL_LOADING = 'web_hook_url_loading';

export const webHookState ={
    web_hook_url_error:null,
    web_hook_url_loading:false,
    web_hook_url_success:false,
}


export function WebHookUrlReducer(state=webHookState,action){
    if(action.type === WEBHOOK_URL_LOADING){
        return {
         ...webHookState,
         web_hook_url_loading:true,
         }
     }
     else if(action.type === WEBHOOK_URL_ERROR){
         return {
             ...webHookState,
             web_hook_url_loading:false,
             web_hook_url_error:action.payload.error,
         }
     }
     else if (action.type === WEBHOOK_URL_SUCCESS){
         return {
             ...webHookState,
             web_hook_url_loading:false,
             web_hook_url_error:null,
             web_hook_url_success:true
         }   
     }
return state;
}