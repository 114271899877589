import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { ChangePasswordThunk, ChangeQRCodeThunk, TwoFAGenerateThunk } from "../../../../../Thunks";

import * as yup from 'yup';
import { useEffect, useState } from "react";
import { Loading } from "../../../../../Components";

import { RESTART_CHANGE_PASSWORD } from '../../../../../Reducer/ChangePassword';
import { RESET_QR_CODE } from '../../../../../Reducer/ChangeQRCodeReducer';

import view from '../../../../../Assets/img/icons/eye.svg';

export function LoginSettings() {

    const dispatch = useDispatch();
    const [stateError, setStateError] = useState();
    const [qrstateError, setQrStateError] = useState();
    const [changed, setChanged] = useState(false);
    const [changeCode, setChangeCode] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const [newpasswordShown, newsetPasswordShown] = useState(false);
    const [confirmpasswordShown, confirmsetPasswordShown] = useState(false);
    const [twoFapasswordShown, twoFasetPasswordShown] = useState(false);



    const schema = yup.object().shape({
        oldPassword: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.').min(8, 'The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol.').matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."),
        newPassword: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.').min(8, 'The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol.').matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."),
        passwordConfirmation: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match.').required('This is a required field.'),
    });

    const passwordFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newPassword: '',
            passwordConfirmation: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            dispatch(ChangePasswordThunk(values));
        }

    });

    const schemaqr = yup.object().shape({
        qr_password: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.').min(8, 'The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol.').matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, "The password must be at least 8 characters long with at least 1 uppercase, 1 lowercase, 1 number and 1 symbol."),
        qr_code: yup.string().matches(/^[^-\s]/, 'This field cannot contain only spaces.').required('This is a required field.'),
    });

    const qrFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            qr_password: '',
            qr_code: '',
        },
        validationSchema: schemaqr,
        onSubmit: values => {
            dispatch(ChangeQRCodeThunk({password:values.qr_password, code:values.qr_code }));
        }
    });




    const changePasswordLoading = useSelector((state) => {
        return state.changePassword.password_loading;
    });

    const changePasswordError = useSelector((state) => {
        return state.changePassword.password_error;
    });

    const changePasswordSuccess = useSelector((state) => {
        return state.changePassword.password_success;
    });


    const change_qr_code_loading = useSelector((state) => {
        return state.changeQrCode.change_qr_code_loading
    });

    const change_qr_code_error = useSelector((state) => {
        return state.changeQrCode.change_qr_code_error;
    });

    const change_qr_code_success = useSelector((state) => {
        return state.changeQrCode.change_qr_code_success;
    });


    const qrImage = useSelector((state) => {
        return state.changeQrCode.change_qrCode_image;
    });

    useEffect(() => {
        if (changePasswordSuccess) {
            setChanged(true)
            setTimeout(() => {
                setChanged(false)
            }, 5000);
            passwordFormik.setValues({
                oldPassword: '',
                newPassword: '',
                passwordConfirmation: ''
            });
            passwordFormik.setTouched({}, false)
        }
    }, [changePasswordSuccess]);

    useEffect(() => {
        return () => {
            dispatch({
                type: RESTART_CHANGE_PASSWORD
            });

            dispatch({
                type: RESET_QR_CODE
            })

        }
    }, []);


    useEffect(() => {
        if (change_qr_code_success) {
            setChangeCode(true)
            setTimeout(() => {
                setChangeCode(false)
            }, 5000)
            qrFormik.setValues({
                qr_password: '',
                qr_code: ''
            });
            qrFormik.setTouched({}, false)

        }
    }, [change_qr_code_success]);

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    const deleteQrErrorButton = (fieldKey) => {
        if (fieldKey) {
            setQrStateError(null)

        }
    };

    useEffect(() => {
        if (changePasswordError) {
            setStateError(changePasswordError);
        }
    }, [changePasswordError]);


    useEffect(() => {
        if (change_qr_code_error) {
            setQrStateError(change_qr_code_error);
        }
    }, [change_qr_code_error]);

    return <>
        <div id="login__settings-1" className="login__settings">
            <form className="password__change__block" onSubmit={passwordFormik.handleSubmit}>
                <span className='error__message requests__message'>{stateError}</span>
                {changed ?
                    <p className="password__change__block__title" style={{ color: '#4DC7B5' }}>Your password was changed successfully.</p>
                    : null
                }

                <h4 className="password__change__block__title">Password change</h4>
                <div className='form__content'>
                    <>
                        <div className='notificaten__password__content'>
                            <input placeholder="Current Password" className={'password__change__input' + ' ' + (passwordFormik.touched.oldPassword && passwordFormik.errors.oldPassword ? 'form__content__error' : 'password__change__input')} id="oldPassword" name="oldPassword"
                                value={passwordFormik.values.oldPassword}
                                onChange={passwordFormik.handleChange}
                                onFocus={() => deleteErrorButton('oldPassword')}
                                type={passwordShown ? "text" : "password"}
                                autoComplete="new-password"
                            />
                            <img className='notificaten__password__icon' src={view} onClick={(() => {
                                setPasswordShown(!passwordShown)
                            })} />

                        </div>
                        {passwordFormik.touched.oldPassword && passwordFormik.errors.oldPassword ? <span className='error__message change_password__message'>{passwordFormik.errors.oldPassword}</span> : null}
                    </>


                </div>

                <div className='form__content'>

                    <div className='notificaten__password__content'>
                        <input placeholder="New Password" className={'password__change__input' + ' ' + (passwordFormik.touched.newPassword && passwordFormik.errors.newPassword ? 'form__content__error' : 'password__change__input')} id="newPassword" name="newPassword"
                            value={passwordFormik.values.newPassword}
                            onChange={passwordFormik.handleChange}
                            onFocus={() => deleteErrorButton('newPassword')}
                            type={newpasswordShown ? "text" : "password"}
                            autoComplete="new-password"
                        />
                        <img className='notificaten__password__icon' src={view} onClick={(() => {
                            newsetPasswordShown(!newpasswordShown)
                        })} />
                    </div>
                    {passwordFormik.touched.newPassword && passwordFormik.errors.newPassword ? <span className='error__message change_password__message'>{passwordFormik.errors.newPassword}</span> : null}
                </div>
                <>
                    <div className='form__content'>

                        <div className='notificaten__password__content'>
                            <input placeholder="Confirm Password" className={'password__change__input' + ' ' + (passwordFormik.touched.passwordConfirmation && passwordFormik.errors.passwordConfirmation ? 'form__content__error' : 'password__change__input')} id="passwordConfirmation" name="passwordConfirmation"
                                value={passwordFormik.values.passwordConfirmation}
                                onChange={passwordFormik.handleChange}
                                onFocus={() => deleteErrorButton('passwordConfirmation')}
                                type={confirmpasswordShown ? "text" : "password"}
                                autoComplete="new-password"

                            />
                            <img className='notificaten__password__icon' src={view} onClick={(() => {
                                confirmsetPasswordShown(!confirmpasswordShown)
                            })} />
                        </div>


                    </div>
                    {passwordFormik.touched.passwordConfirmation && passwordFormik.errors.passwordConfirmation ? <span className='error__message'>{passwordFormik.errors.passwordConfirmation}</span> : null}
                </>


                <>
                    <button className="password__change__btn">Apply {changePasswordLoading ? <Loading /> : null}</button>
                </>

            </form>
            <form className="password__change__block" onSubmit={qrFormik.handleSubmit}>
                <h4 className="password__change__block__title" style={{'marginTop':'20px'}}>2FA change</h4>
                <span className='error__message requests__message'>{qrstateError}</span>
                {changeCode ?
                    <p className="password__change__block__title" style={{ color: '#4DC7B5' }}>Your QR code was successfully generated. You can scan it.</p> : null
                }
                <>
                    <div className='form__content'>
                        <div className='notificaten__password__content'>
                            <input placeholder="Password" className={'password__change__input' + ' ' + (qrFormik.touched.qr_password && qrFormik.errors.qr_password ? 'form__content__error' : 'password__change__input')} id="qr_password" name="qr_password"
                                value={qrFormik.values.qr_password}
                                onChange={qrFormik.handleChange}
                                onFocus={() => deleteQrErrorButton('qr_password')}
                                type={twoFapasswordShown ? "text" : "password"}
                                autoComplete="new-password"
                            />
                            <img className='notificaten__password__icon' src={view} onClick={(() => {
                                twoFasetPasswordShown(!twoFapasswordShown)
                            })} />
                        </div>

                    </div>
                    {qrFormik.touched.qr_password && qrFormik.errors.qr_password ? <span style={{width:'43%'}} className='error__message change_password__message'>{qrFormik.errors.qr_password}</span> : null}
                </>

                <div className='form__content'>

                    <input placeholder="Current 2FA" type="text" className={'password__change__input' + ' ' + (qrFormik.touched.qr_code && qrFormik.errors.qr_code ? 'form__content__error' : 'password__change__input')} id="qr_code" name="qr_code"
                        value={qrFormik.values.qr_code}
                        onChange={qrFormik.handleChange}
                        onFocus={() => deleteQrErrorButton('qr_code')}
                    />
                    {qrFormik.touched.qr_code && qrFormik.errors.qr_code ? <span className='error__message'>{qrFormik.errors.qr_code}</span> : null}
                </div>

                <button className="password__change__btn">Generate New 2FA QR {change_qr_code_loading ? <Loading /> : null}</button>
                {qrImage ?
                    <div className="qr__wrapper">

                        <img src={qrImage} alt="" className="qr__img" />
                    </div> : null}

            </form>
        </div>
    </>
}