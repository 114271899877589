import { CREATE_PASSWORD_LOADING, CREATE_PASSWORD_ERROR, CREATE_PASSWORD_SUCCESS } from '../Reducer/CreatePasswordReducer';

import { ApiCreatePassword } from '../Api';

export function CreatePasswordThunk({ code, password }) {
    return (dispatch, getState) => {
        dispatch({
            type: CREATE_PASSWORD_LOADING,
        })
        ApiCreatePassword(code, password)
            .then((result) => {
                dispatch({
                    type: CREATE_PASSWORD_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_PASSWORD_ERROR,
                    paylod: {
                        error: error?.response?.data?.error?.message,
                    }

                })
            })
    }
}
