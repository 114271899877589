export const EDIT_WALLET_SHILD_SUCCESS = 'EDIT_WALLET_SHILD_SUCCESS';
export const EDIT_WALLET_SHILD_LOADING = 'EDIT_WALLET_SHILD_LOADING';
export const EDIT_WALLET_SHILD_ERROR = 'EDIT_WALLET_SHILD_ERROR';
export const EDIT_WALLET_SHILD_SUCCESS_CLOSE_MODAL = 'EDIT_WALLET_SHILD_SUCCESS_CLOSE_MODAL';

const editWalletShildState ={
edit_wallet_shild_success:false,
edit_wallet_shild_error:null,
edit_wallet_shild_loading:false,
};


export function EditWalletShildReducer(state=editWalletShildState,action){

    if(action.type === EDIT_WALLET_SHILD_SUCCESS){

        return {
            ...editWalletShildState,
            edit_wallet_shild_success:true,
            edit_wallet_shild_error:null,
            edit_wallet_shild_loading:false,
        }
    }

    else if(action.type === EDIT_WALLET_SHILD_LOADING ){

        return {
            ...editWalletShildState,
            edit_wallet_shild_success:false,
            edit_wallet_shild_error:null,
            edit_wallet_shild_loading:true,
        }
    }

    else if(action.type === EDIT_WALLET_SHILD_ERROR){
        return {
            ...editWalletShildState,
            edit_wallet_shild_success:false,
            edit_wallet_shild_error:action.payload.error,
            edit_wallet_shild_loading:false,
        }
    }
    
    else if(action.type === EDIT_WALLET_SHILD_SUCCESS_CLOSE_MODAL ){

        return {
            ...editWalletShildState,
            edit_wallet_shild_success:false,
            edit_wallet_shild_error:null,
            edit_wallet_shild_loading:false,
        }
    }

    return state;

}