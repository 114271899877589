export const BLOCKCHAINS_SUCCESS = 'blockchains_success';
export const SET_BLOCKCHATIN_DATA = 'set_blockchain_data';

const blockchainsState = {
    blockchainsResult: [],
}

export function BlockchainsReducer(state = blockchainsState, action) {
    if (action.type === BLOCKCHAINS_SUCCESS) {
        const data = action.payload.result.map(item => {
            return {
                label: `${item.name}`,
                value: item.id,
                default: item.defaultValue,
                blockChainValue:item.value
            }
        })
        return {
            ...state,
            blockchainsResult: data,
        }
    }
    if (action.type === SET_BLOCKCHATIN_DATA) {
        return {
            ...state,
            blockchainsResult: action.payload.result,
        }
    }
    return state;
}