export const CHANGE_QR_CODE_LOADING = 'change_qr_code_loading';
export const CHANGE_QR_CODE_ERROR = 'change_qr_code_error';
export const CHANGE_QR_CODE_SUCCESS = 'change_qr_code_success';
export const RESET_QR_CODE = 'reset_qr_code';

const changeQrCodeState = {
    change_qr_code_loading: false,
    change_qr_code_error: null,
    change_qr_code_success: false,
    change_qrCode_image:null,
};

export function ChangeQRCodeReducer(state = changeQrCodeState, action) {
    if (action.type === CHANGE_QR_CODE_LOADING) {
        return {
            ...changeQrCodeState,
            change_qr_code_loading: true,

        }
    }

    else if (action.type === CHANGE_QR_CODE_ERROR) {
        return {
            ...changeQrCodeState,
            change_qr_code_loading: false,
            change_qr_code_error: action.paylod.error,

        }
    }

    else if (action.type === CHANGE_QR_CODE_SUCCESS) {
        return {
            ...changeQrCodeState,
            change_qr_code_loading: false,
            change_qr_code_error: null,
            change_qr_code_success: true,
            change_qrCode_image:action.paylod.qrImage,

        }
    }

    else if (action.type ===RESET_QR_CODE ){
        return {
            ...changeQrCodeState,
            change_qr_code_loading: false,
            change_qr_code_error: null,
            change_qr_code_success: false,
            change_qrCode_image:null,

        }
    }
    return state;
}