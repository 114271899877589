export const ASSETS_SUCCESS = 'assets_success';
export const SET_ASSET_DATA = 'set_asset_data';

const assetsState = {
    assetsResult: [],
}

export function AssetsReducer(state = assetsState, action) {

    if (action.type === ASSETS_SUCCESS) {
        const data = action.payload.result.map(item => {
            return {
                label: `${item.symbol}`,
                value: item.id,
                default: item.defaultValue,
                assetsValue: item.value
            }
        })
        return {
            ...state,
            assetsResult: data,
        }
    }
    else if (action.type === SET_ASSET_DATA) {
        return {
            ...state,
            assetsResult: action.payload.result,
        }
    }
    return state;


}