import React, { useEffect, useState } from 'react';

import arrow from '../../Assets/img/icons/arrow_down.png';

import OutsideClickHandler from 'react-outside-click-handler';

import { useSelector } from 'react-redux';

import * as _ from 'lodash';


export function DropDown({ options, value, onChange, placeholder, className = "", children, allassets, allblockchains, allPlatform, title }) {

    const [selectedOption, setSelectedOption] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const createDefiShildSuccess = useSelector((state) => {
        return state.creteDefiShild.create_defishild_success;
    });


    const createLightDefiSuccess = useSelector((state) => {
        return state.creteLightDefiReduicer.create_lightdefi_success;
    });

    // useEffect(() => {
    //     if (!value) {
    //         return;
    //     }
    //     const selectedValue = options.find((e) => e.value === value) || null;
    //     if (!_.isEqual(selectedValue, selectedOption)) {
    //         onClickItem(selectedOption);
    //     }
    // }, [value, options]);

    useEffect(() => {
        if (_.isEmpty(selectedOption)) {
            return;
        }
        if (selectedOption.label.includes('All ') && !_.isEqual(selectedOption.value, options.map((e) => e.value))) {
            onClickItem({ label: selectedOption.label, value: options.map((e) => e.value) });
        }
    }, [options])

    function onClickItem(item) {
        setSelectedOption(item);
        onChange(item);
    }

    function toggleDropDown() {
        setIsOpen(!isOpen);

    }


    useEffect(() => {
        if (createDefiShildSuccess) {

            setSelectedOption({});
        }
    }, [createDefiShildSuccess]);

    useEffect(() => {
        if (createLightDefiSuccess) {
            setSelectedOption({});
        }
    }, [createLightDefiSuccess]);


    return (
        <OutsideClickHandler onOutsideClick={(event) => {
          //  event.stopPropagation()
            setIsOpen(false)
        }}>
            <div className="card__product__row">
                <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px', alignItems: 'center' }} onClick={() => toggleDropDown()}>
                    {selectedOption?.label ?? placeholder}
                    <img src={arrow}
                        className="card__product__arrow" style={{ width: '10px', height: '6px', marginRight: '10px' }} alt="arrow" />
                </div>
                <div className={"product__row__dropdown " + (isOpen ? "open" : "") + className} >

                    {
                        allassets && title === 'LightDefi' ?
                            <div className="product__row__dropdown__item" onClick={() => {
                                onClickItem({ value: options.map((item) => item.value), label: 'All Assets' });
                                toggleDropDown();
                            }}>All Assets</div> : null
                    }

                    {
                        allblockchains && title === 'LightDefi' ?
                            <div className="product__row__dropdown__item" onClick={() => {
                                onClickItem({ value: options.map((item) => item.value), label: 'All Blockchains' });
                                toggleDropDown();
                            }}>All Blockchains</div> : null
                    }

                    {
                        allPlatform && title === 'LightDefi' ?
                            <div className="product__row__dropdown__item" onClick={() => {
                                onClickItem({ value: options.map((item) => item.value), label: 'All Platforms' });
                                toggleDropDown();
                            }}>All Platforms</div> : null
                    }

                    {
                        options.map((e, index) => {
                            return <div className="product__row__dropdown__item" key={index} onClick={() => {
                                onClickItem(e);
                                toggleDropDown();
                            }}>{e?.label}</div>
                        })
                    }
                </div>
                {children}
            </div>
        </OutsideClickHandler>
    )
}
