import {WALLET_INVESTMENT_RISK_ALERT} from '../Reducer/WalletInvestmentRiskAlertReducer';
import {ApiGetWalletGeneral} from '../Api';


export function WalletInvestmentRiskAlertThunk(){
    return (dispatch,getState) =>{
        ApiGetWalletGeneral()
        .then((result)=>{
            dispatch({
                type:WALLET_INVESTMENT_RISK_ALERT,
                payload:{
                    result:result.data.data  
                }
            })
        })
    }
}