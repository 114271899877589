import React from 'react';

import l_big from '../../Assets/img/login/l_big.png';
import l_mid from '../../Assets/img/login/l_mid.png';
import l_small from '../../Assets/img/login/l_small.png';
import l_round from '../../Assets/img/login/l_round.png';
import l_logo from '../../Assets/img/login/l_logo.png';


export function NoFilterResultModal({title}) {
    return (
        <>
        <div className='empty__filtet_content'>
            <div className='active__page__main__container'>
                <div className='active__page__main'>
                    <h4 className="light_core_activate_text" style={{ fontSize: '50px', fontWeight: '600' }}>{title}</h4>
                    <h3 className="light_core_activate_text"> There is no such product in your Portfolio.
                    </h3>
                    <h2 className="light_core_activate_title">Please add this product.</h2>
                    <div className="loading__layer active_loading__layer">
                        <div className="loading__wrapper">
                            <img src={l_big} alt="" className="l_big" />
                            <img src={l_mid} alt="" className="l_mid" />
                            <img src={l_small} alt="" className="l_small" />
                            <img src={l_round} alt="" className="l_round" />
                            <img src={l_logo} alt="" className="l_logo" />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}